import React, { useState } from 'react'
import axios from 'axios';
import SessionStatus from '../../../service/SessionStatus';
import { useToken } from '../../../service/context/TokenProvider';

function UserAvatarPanel() {
    const [showModal, setShowModal] = React.useState(false);
    const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(null);
    const [avatars, setAvatars] = useState(null);
    const [session, setSession] = useState(null);

    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const handleAvatarClick = (index) => {
        setSelectedAvatarIndex(index === selectedAvatarIndex ? null : index);
    };

    const fillAvatars = () => {
        axios.get('/avatar/getAllWithImage')
            .then((response) => {
                setAvatars(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const setNewAvatar = () => {
        axios.put('/avatar/change/' + subId + '/' + avatars[selectedAvatarIndex].avatarName, {
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                localStorage.setItem("signedUserAvatarName", avatars[selectedAvatarIndex].avatarName);
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.data.message === "Access Denied") {
                    setSession("Session has expired, please log in again.");
                    return
                }
            })
    }

    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    fillAvatars()
                    setShowModal(true)
                }}
                type="button">
                <button className="relative text-center align-middle ">
                    <p className=' lg:text-base md:text-base sm:text-sm text-s  text-gray-400 hover:text-gray-500 duration-200'>Change Avatar</p>
                    <input
                        type="file"
                        id="avatar-upload"
                        className="hidden"
                    />
                </button>
            </a>
            {showModal ? (
                <>
                    <div className=" justify-center  text-white items-center font-bodyFont mt-40  px-4 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        {session && (
                            <SessionStatus session={session}></SessionStatus>
                        )}
                        <div className="relative font-bodyFont w-auto  mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" bg-slate-900 border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                <div className=" relative block items-center align-middle ">
                                    <div className=" overflow-y-auto max-h-96 grid grid-cols-3 lg:grid-cols-4 gap-10 m-4">
                                        {avatars ?
                                        avatars.map((avatar, index) => (
                                            <div key={index} className="flex justify-center items-center mt-1">
                                                <img
                                                    className={`lg:h-28 lg:w-28 sm:h-20 sm:w-20 h-20 w-20 ${selectedAvatarIndex === index ? 'border-4 border-indigo-400' : ''}  rounded-full cursor-pointer duration-100  ring-1 ring-zinc-500`}
                                                    alt={`Avatar ${avatar.avatarName}`}
                                                    onClick={() => handleAvatarClick(index)}
                                                    src={`data:image/jpeg;base64,${avatar.avatarImage}`}
                                                />
                                            </div>
                                        ))
                                        : 
                                        Array.from({ length: 8 }, (_, i) => (
                                            <div key={i} className="flex justify-center items-center mt-1">
                                                <div class="w-24 h-24 bg-gray-400 rounded-full animate-pulse"></div>
                                            </div>
                                        ))}
                                        {/* Diğer avatar blokları */}
                                    </div>

                                    <form className="">
                                        <div className="flex  justify-end  my-2 w-full rounded-b">

                                            <button
                                                className="border-white border-2 duration-300  transform inline-block rounded-md lg:text-lg sm:text-base bg-slate-950 px-4 py-2 m-2 hover:text-red-500 hover:border-red-500 text-white "
                                                type="button"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="border-white border-2 duration-300  transform inline-block rounded-md lg:text-lg sm:text-base bg-slate-950 px-4 py-2  m-2 hover:text-green-500 hover:border-green-500 text-white  "
                                                type="button"
                                                onClick={() => setNewAvatar()}
                                            >
                                                Accept
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default UserAvatarPanel