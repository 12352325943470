import React, { useEffect, useState } from 'react'
import SessionStatus from '../../service/SessionStatus';
import design2 from '../../assets/design2.png'
import { FiHome } from "react-icons/fi";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useToken } from '../../service/context/TokenProvider';
import { getAvatarImageFromLocalStorage } from '../../service/Avatar';

function EndGame(props) {
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const { showEndGame, gameId, trueAnswersCount, wrongAnswersCount, sectionLength } = props
    const [showModal, setShowModal] = React.useState(false);
    const [session, setSession] = useState(null);
    const [leaderboard, setLeaderboard] = useState(null)
    const [userRank, setUserRank] = useState(null)

    useEffect(() => {
        if (showEndGame) {
            setShowModal(true)
        }
    }, [showEndGame])

    useEffect(() => {
        if (showModal) {
            fillLeaderboard()
            getUserRank()
        }
    }, [showModal])

    const fillLeaderboard = () => {
        axios.get('/played/game/' + gameId)
            .then((response) => {
                setLeaderboard(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getUserRank = () => {
        axios.get('/rank/userLeaderboard/' + subId + '/' + gameId, {
            headers: {
                Authorization: accessToken,
            }
        })
            .then((response) => {
                setUserRank(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className=" justify-center px-2 flex text-white items-center font-bodyFont align-middle  overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none bg-opacity-0 backdrop-filter backdrop-blur-sm z-10 ">
                        {session && (
                            <SessionStatus session={session}></SessionStatus>
                        )}
                        <div

                            className="relative font-bodyFont w-auto  mx-auto max-w-5xl rounded-lg">

                            <div
                                style={{
                                    backgroundAttachment: 'scroll',
                                    backgroundPosition: 'bottom right  ',
                                    backgroundImage: ` url(${design2})`,
                                    backgroundSize: '30%',
                                    backgroundRepeat: 'no-repeat',
                                    // Ensure the container covers the entire viewport height
                                }} className=" bg-slate-900  rounded-lg p-5 shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                <div className='flex text-white  rounded-sm ring-1 ring-indigo-400'>
                                    <table
                                        className="">
                                        <thead className="border-b font-medium border-gray-500 text-white bg-gray-800 ">
                                            <tr className=''>
                                                <th scope="col" className="lg:px-9 lg:py-4   sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Rank</th>
                                                <th scope="col" className="lg:px-9 lg:py-4   sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Nick</th>
                                                <th scope="col" className="lg:px-9 lg:py-4   sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                leaderboard == null ?
                                                    null
                                                    :
                                                    leaderboard.length == 0 ?
                                                        <tr className="border-neutral-500 lg:text-lg sm:text-xs text-xs">
                                                            <td className="whitespace-nowrap lg:px-10 md:px-14 sm:px-10 px-8 py-2 lg:text-lg sm:text-xs"></td>
                                                            <td className="whitespace-nowrap lg:px-10 md:px-14 sm:px-10 px-8 py-2">
                                                                <div className='flex items-center '>
                                                                    <div className='ms-2 lg:mx-2 ' >
                                                                        Leaderboard not found!
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        leaderboard.map((item, index) => (
                                                            <tr className="border-neutral-500 lg:text-xl sm:text-xs text-xs">
                                                                <div className='flex justify-center'>
                                                                    <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10  px-8 py-2 lg:text-xl sm:text-xs">{index + 1}</td>
                                                                </div>
                                                                <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10  px-8 py-2">
                                                                    <div className='flex items-center'>
                                                                        <img src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(item.userAvatarName)}`} className="lg:h-8 lg:w-8 sm:w-6 sm:h-6 h-6 w-6 rounded-full" />
                                                                        <Link className='hover:text-indigo-300 duration-200' to={"/userProfile/" + item.userId}>
                                                                            <div className='ms-2 truncate '>
                                                                                {item.userName}
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10  px-8 py-2">{item.score}</td>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                                <div className='text-white lg:text-2xl sm:text-lg align-middle  '>
                                    <p className="mt-2">
                                        Rank: <span className='text-indigo-500'>{userRank}</span>
                                    </p>
                                    <p className="mt-1">
                                        Rate: <span className='text-indigo-500'>{(trueAnswersCount / sectionLength) * 100}%</span>
                                    </p>
                                    <p className="mt-1">
                                        Score: <span className='text-indigo-500'>{showEndGame.score}</span>
                                    </p>

                                </div>

                                <form className="">
                                    <div className="flex  justify-center  my-2 w-full rounded-b">
                                        <Link to={"/"}>
                                            <button
                                                className="border-white duration-300 p-1 hover:bg-indigo-400 transform inline-block rounded-md lg:text-lg sm:text-base  m-2  text-white  "
                                                type="button"
                                            >
                                                <FiHome className='text-4xl text-white '></FiHome>
                                            </button>
                                        </Link>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default EndGame