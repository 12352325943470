import React, { useEffect, useState } from 'react'
import { FiTrash2, FiArrowDown } from "react-icons/fi";
import { useToken } from '../../../service/context/TokenProvider';
import axios from 'axios';
import design3 from '../../../assets/design3.png'

function MangaReaded(props) {
    const { userId } = props;
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [userAnimeList, setUserAnimeList] = useState([])
    const [userAnimeListLength, setUserAnimeListLength] = useState(0)
    const [newAnimeName, setNewAnimeName] = useState('')
    const [page, setPage] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)

    // const fillUserAnimeListLength = () => {
    //     axios.get('/animeList/count', {
    //         params: {
    //             userId: userId,
    //             animeListTypeId: 3,
    //         }
    //     })
    //         .then((response) => {
    //             setUserAnimeListLength(response.data)
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    const fillUserAnimeList = (update) => {
        axios.get('/animeList/get', {
            params: {
                userId: userId,
                animeListTypeId: 3,
                page: update ? 0 : page,
                size: 6
            }
        })
            .then((response) => {
                setIsLoaded(true)
                if (update) {
                    setUserAnimeList(response.data)
                } else {
                    setUserAnimeList(prevList => prevList.concat(response.data))
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const addAnimeToList = () => {
        axios.post('/animeList/create', {
            userId: subId,
            animeListTypeId: 3,
            animeName: newAnimeName,
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                fillUserAnimeList(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteAnimeFromList = (listId) => {
        axios.delete('/animeList/' + listId, {
            params: {
                userId: subId,
                token: accessToken
            },
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                fillUserAnimeList(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getMoreAnimeList = () => {
        setPage(prevPage => prevPage + 1)
    }

    useEffect(() => {
        fillUserAnimeList()
    }, [page])

    // useEffect(() => {
    //     fillUserAnimeListLength()
    // }, [])

    const sectionStyle = {
        backgroundAttachment: 'scroll',
        backgroundPosition: 'bottom 75% right',
        backgroundImage: windowWidth > 767 ? `url(${design3})` : 'none',
        backgroundSize: '25%',
        backgroundRepeat: 'no-repeat',
        // Ensure the container covers the entire viewport height
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const onInputChange = (e) => {
        setNewAnimeName(e.target.value)
    };

    return (
        <article
            style={sectionStyle}
            className="rounded-xl  shadow-sm shadow-gray-500 bg-purple-50    p-4">
            {
                subId === userId ?
                    <>
                        <div className="flex justify-between mb-2">
                            <h3 className="lg:text-xl sm:text-sm text-sm font-medium text-white">Add Manga</h3>
                        </div>
                        <div className="flex items-end ">
                            <input
                                onChange={onInputChange}
                                type="text"
                                id="animeName"
                                required
                                maxLength={50}
                                className="w-full p-2 mt-1 border rounded-md text-white  bg-gray-800 focus:outline-none focus:ring focus:border-indigo-400"
                                placeholder=""
                            />
                            <button
                                onClick={addAnimeToList}
                                className="ml-2 p-2  bg-green-500 hover:bg-green-600   rounded-md cursor-pointer text-white duration-200 focus:outline-none focus:ring focus:border-green-700"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-7 w-7"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 12h14M12 5l7 7-7 7"
                                    />
                                </svg>
                            </button>
                        </div>
                    </>
                    :
                    null
            }

            {
                !isLoaded ?
                    <div class='flex justify-center items-center text-white border-indigo-400 rounded-lg'>
                        <div class="border-t-transparent mt-4 border-solid animate-spin rounded-full border-indigo-400 border-8 h-5 w-5">
                        </div>
                    </div>
                    :
                    <ul className="mt-4 gap-1  grid lg:grid-cols-2 sm:grid-cols-1">
                        {
                            userAnimeList.map((item, index) => (
                                <li key={index}>
                                    <div className="flex justify-between h-full rounded-lg border border-gray-700 p-2 hover:border-indigo-400 relative">
                                        <p className="mt-1 lg:text-sm text-xs font-medium text-gray-200">
                                            {item.animeName}
                                        </p>
                                        {
                                            subId === userId ?
                                                <button className=" text-gray-500 hover:text-red-500">
                                                    <FiTrash2 onClick={() => deleteAnimeFromList(item.id)}></FiTrash2>
                                                </button>
                                                :
                                                null
                                        }
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
            }
            <div className='flex justify-center items-center mt-2'>
                <FiArrowDown
                    onClick={getMoreAnimeList}
                    className=' lg:h-5 lg:w-5 h-4 w-4 text-gray-200 hover:text-gray-500 cursor-pointer'
                ></FiArrowDown>
            </div>
        </article>
    )
}

export default MangaReaded