import axios from 'axios';
import React, { useState } from 'react'
import {  FaCircleUser, FaTrash } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import { formatDate } from '../../service/FormatDate';

function AdminViewGame(props) {
    const [showModal, setShowModal] = React.useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const { id, children } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedSection, setIsLoadedSection] = useState(false);


    const [error, setError] = useState(false);
    const [game, setGame] = useState()
    const [section, setSection] = useState()


    const getGameById = async () => {
        await axios.get('/game/withImage/' + id)
            .then((response) => {
                setGame(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    const getSectionByGameId = async () => {
        await axios.get('/section/getAllForAdmin/game/' + id, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                setSection(response.data)
                setIsLoadedSection(true);
            })
            .catch((error) => {
                setIsLoadedSection(true);
                setError(error)
            })
    }
    const deleteGame = async () => {
        try {
            const response = await axios.delete(`/game/${id}`, {
                headers: {
                    Authorization: accessToken
                }
            });
            window.location.reload();
        } catch (error) {
            console.error("Oyun silinirken bir hata oluştu:", error);
        }
    };

    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                    getGameById()
                    getSectionByGameId()
                }}
                type="button">
                {children}
            </a>
            {showModal ? (
                <>
                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative font-bodyFont w-auto  mx-auto max-w-3xl">
                            {/*content*/}
                            <div className=" bg-zinc-800 border-gray-600  rounded-lg shadow-lg relative flex  flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                {
                                    isLoaded ?
                                        <div className="relative block overflow-y-auto h-screen">
                                            {/* body */}
                                            <div className="relative flex">
                                                <div className="font-bodyFont">
                                                    <div className="flex">
                                                        <div className="mt-4 ml-4 rounded-lg relative">
                                                            <img
                                                                alt="Art"
                                                                src={`data:image/jpeg;base64,${game.gamePreviewImage}`}
                                                                className=" rounded-lg object-cover sm:h-40 lg:h-96 lg:w-96 sm:w-40"
                                                            />
                                                        </div>
                                                        <div className="mt-4 ml-4 text-white">
                                                            <h2 className="text-2xl font-semibold">{game.gameName}</h2>
                                                            <p className="text-xl ">Oyun Oluşturan:<Link to={"/userProfile/" + game.gameCreatorUserId}> <span className='text-gray-400'>{game.gameCreatorUserName}</span></Link></p>
                                                            <p className="text-xl">Tür: <span className='text-gray-400'>{game.gameType.gameTypeName}</span> </p>
                                                            <p className="text-xl ">Oyunma Sayısı: <span className='text-gray-400'>{game.playerCount}</span></p>
                                                            <p className="text-xl ">Oluşturma Tarihi: <span className='text-gray-400'>{formatDate(game.createDate)}</span></p>
                                                            <p className="text-xl ">Güncelleme Tarihi: <span className=' text-gray-400'>{formatDate(game.updateDate)}</span></p>
                                                            <div className='flex mt-20 justify-evenly'>
                                                                <Link to={"/userProfile/" + game.gameCreatorUserId}>
                                                                    <FaCircleUser className='mt-5  hover:-translate-y-1 duration-200  cursor-pointer text-3xl mr-5 text-green-500 hover:text-green-600'></FaCircleUser>
                                                                </Link>
                                                                <FaTrash onClick={deleteGame} className='mt-5  hover:-translate-y-1 duration-200 cursor-pointer text-3xl mr-5 text-red-500 hover:text-red-600' />
                                                            </div>
                                                        </div>

                                                        <button
                                                            className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                                            onClick={() => setShowModal(false)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="w-6 h-6"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M6 18L18 6M6 6l12 12"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        {
                                                            isLoadedSection ?
                                                                <div className="mx-2 rounded-lg relative">
                                                                    <div className='flex justify-between my-2 mx-2'>
                                                                        <p className='text-lg'>Game Id: {section.gameId} </p>
                                                                        <p className='text-lg'>Kare Sayısı: {section.squareAmount} </p>
                                                                        <p className='text-lg'>Bölüm Sayısı: {section.sections.length} </p>
                                                                    </div>
                                                                    <p className='text-xl my-2 mx-2'>Bölümler ve Doğru Cevaplar</p>
                                                                    <hr></hr>

                                                                    <div className="flex flex-wrap text-white">
                                                                        {section.sections.map((key, index) => (
                                                                            <div className="w-full lg:w-1/2 p-4">
                                                                                <div className="max-w-md mx-auto  rounded-lg flex">
                                                                                    <div className="w-1/2 p-4">
                                                                                        <img
                                                                                            alt="Art"
                                                                                            src={`data:image/jpeg;base64,${key.sectionImage}`}
                                                                                            className="h-30 w-40 rounded-lg object-cover transition-transform transform scale-100 hover:scale-125 cursor-pointer"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="flex items-center w-1/2">
                                                                                        <p className="text-base overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                                                            {key.sectionCorrectAnswer}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                                :
                                                                <div className="fixed inset-0 flex items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                                                                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-24"></div>
                                                                </div>
                                                        }
                                                    </div>

                                                    <form className="">
                                                        <div className="flex justify-end my-2 w-full rounded-b">
                                                            {/* Diğer düğmeler buraya gelebilir */}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                        :
                                        <div className="fixed inset-0 flex items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                                            <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-24"></div>
                                        </div>


                                }
                            </div>

                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default AdminViewGame