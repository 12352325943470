import React, { useEffect, useState } from 'react'
import LoadingTodayGame from '../card/loading/LoadingTodayGame'
import TodayGameCard from '../card/TodayGameCard'
import TopListTable from '../card/TopListTable'
import { Helmet } from 'react-helmet';

import design22 from '../../../assets/design25.png'


function TopList() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const sectionStyle = {
        backgroundAttachment: 'scroll',
        backgroundPosition: ' bottom  left 3%   ',
        backgroundImage: windowWidth > 767 ? `url(${design22})` : 'none',
        backgroundSize: '10%',
        backgroundRepeat: 'no-repeat',
        // Ensure the container covers the entire viewport height
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

   

    return (
        <div
            style={sectionStyle}>
            <Helmet>
                <title>Anime Guess - Today's Anime Guess Game and Top Player List</title>
                <meta name="description" content="Anime Guess - Enjoy the top anime guessing game experience and compete to be on the top player list! You can find the anime guess game of the day and the leaderboard of the best anime guessers." />
            </Helmet>
            <div className="mx-auto  max-w-6xl lg:px-16 md:px-10 px-5   font-bodyFont">
                <h1 className="lg:text-4xl sm:text-xl font-bold  text-white my-10">
                    Today's Game
                </h1>
                <TodayGameCard></TodayGameCard>
                <span class="flex items-center  sm:px-20 lg:px-16 lg:text-4xl sm:text-xl font-bold justify-center  text-white my-10 ">
                    <span class="h-px flex-1 bg-white"></span>
                    <span class="shrink-0 px-6">TOP PLAYER LIST</span>
                    <span class="h-px flex-1 bg-white"></span>
                </span>

                <TopListTable></TopListTable>
            </div>
        </div>
    )
}

export default TopList