// reducers.js
import { SET_WEBSOCKET_CONNECTION } from './WebSocketActions';

const initialState = {
  webSocketConnection: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEBSOCKET_CONNECTION:
      return {
        ...state,
        webSocketConnection: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
