import React from 'react'
import { Link } from 'react-router-dom'

function GameCard(props) {
    const { data } = props
    return (
        <Link to={"/gameView/" + data.id}>
            <div class="flex font-bodyFont flex-col items-center justify-center w-full mx-auto hover:cursor-pointer duration-200 hover:scale-105 rounded-lg ring-1 ring-indigo-400">
                <img
                    alt='Anime Guess - Guess The Anime image'
                    src={`data:image/jpeg;base64,${data.gamePreviewImage}`} className="w-full h-64 bg-cover bg-center bg-gray-300  shadow-md  rounded-t-lg">
                </img>
                <div className='w-full rounded-lg shadow-lg bg-slate-900  rounded-b-lg'>
                    <div className="py-4 text-center ">
                        <h3  className="block lg:text-lg sm:text-sm text-sm text-white" tabIndex="0" role="link">{data.gameName}</h3>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default GameCard