import React from 'react'

function LoadingGameCard() {
    return (
        <div className="block shadow-lg hover:scale-105 duration-200 cursor-pointer  rounded-lg">
            <div className='w-full h-64 animate-pulse rounded-t-md bg-slate-900'></div>
            <div className=' bg-slate-800 rounded-b-md'>
                <div className="py-4 flex items-center justify-center">
                    <div className="h-4 w-1/3 mb-2 bg-slate-900 rounded animate-pulse justify-center"></div>
                </div>
                
            </div>
        </div>)
}

export default LoadingGameCard