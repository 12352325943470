import React from 'react'
import { Link } from 'react-router-dom'
import CategoryCard from './CategoryCard'

function CategoryCardPanel(props) {
    const { gameType } = props
    return (
        <div className=" grid lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-4 mt-8">
            {
                gameType.map((key, index) => (
                    <Link to={"/categoryPage/" + key.id}>
                        <CategoryCard key={index} id={key.id} gameTypeName={key.gameTypeName} gameCount={key.gameCount}></CategoryCard>
                    </Link>
                ))
            }
        </div>
    )
}

export default CategoryCardPanel