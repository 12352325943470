import React from 'react'

function LoadingCategoryCard() {
    return (
        <div class="flex font-bodyFont flex-col items-center justify-center w-full max-w-sm mx-auto  duration-200">
            <div className="w-full h-64 animate-pulse rounded-b-md bg-slate-900"></div>
            <div class=" -mt-10 overflow-hidden rounded-lg shadow-lg lg:w-48 md:w-36 sm:w-28 w-28 bg-slate-900 ring-1 ring-slate-800 text-center z-10">
                <div class="flex items-center justify-center py-1">
                    <div className="h-6 w-1/3 mb-1 bg-slate-800 rounded animate-pulse justify-center"></div>
                </div>
                <div class="flex items-center justify-center px-2 py-1 bg-slate-800 ">
                    <div className="h-4 w-1/3 mb-1 bg-slate-900 rounded animate-pulse justify-center"></div>
                </div>
            </div>
        </div >
    )
}

export default LoadingCategoryCard