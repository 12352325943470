import React from 'react'
import { Link } from 'react-router-dom'

function CategoryTrendCard(props) {
    const { trendGame } = props
    return (
        <Link to={"/gameView/" + trendGame.id}>
            <div class="flex font-bodyFont flex-col items-center justify-center w-full mx-auto hover:cursor-pointer duration-200 hover:scale-105 rounded-lg ring-1 ring-indigo-400">
                <img src={`data:image/jpeg;base64,${trendGame.gamePreviewImage}`} className="w-full lg:h-64 md:h-60 sm:h-40 h-40  bg-cover bg-center bg-gray-300  shadow-md  rounded-t-lg">
                </img>
                <div className='w-full rounded-lg shadow-lg bg-slate-900  rounded-b-lg'>
                    <div className="py-4 text-center ">
                        <h3 className="block lg:text-lg sm:text-sm text-sm text-white" tabIndex="0" role="link">{trendGame.gameName}</h3>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CategoryTrendCard