import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { useDispatch, useSelector } from 'react-redux';
import { setWebSocketConnection } from '../../store/WebSocketActions';
import { FiInfo } from 'react-icons/fi';
import { FiRotateCcw } from "react-icons/fi";

function VsGameView() {
    // Timer configrations
    const [showNotification, setShowNotification] = useState(false);
    const clipboardRef = useRef(null);
    const [gameData, setGameData] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);


    const [seconds, setSeconds] = useState(100);
    const percentage = ((seconds / 180) * 100) / 1.1;

    const circleStyle = {
        clipPath: `circle(${percentage}% at 50% 50%)`,
    };
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [seconds]);

    // Websocket connection configrations

    const { gameId } = useParams();
    const { tokenData } = useToken();
    const navigate = useNavigate();
    const { subId, accessToken } = tokenData;

    const [gameLink, setGameLink] = useState("")
    const [userId, setUserId] = useState(null)

    const dispatch = useDispatch();

    let socket, stompClient;


    useEffect(() => {
        socket = new SockJS(process.env.REACT_APP_API_URL + '/websocket');
        stompClient = Stomp.over(socket);
        stompClient.connect({}, (frame) => {
            console.log('WebSocket bağlantısı kuruldu. Bilgiler: ', frame);
            dispatch(setWebSocketConnection({ socket: socket, stompClient: stompClient }));

            const urlParams = new URLSearchParams(window.location.search);
            const gameToken = urlParams.get('token');

            if (subId) {
                setUserId(subId)
            } else {
                let guestUserId = localStorage.getItem('guestId')
                if (!guestUserId) {
                    guestUserId = Math.floor(Math.random() * 90000000) + 10000000
                    localStorage.setItem('guestId', guestUserId)
                }
                setUserId(guestUserId)
            }

            seperateOperation(gameToken)
        });
        return () => {
            const url = window.location.pathname;
            if (!url.includes('gameVs')) {
                stompClient.disconnect(() => {
                    socket.close();
                    console.log('WebSocket bağlantısı kapatıldı.');
                    dispatch(setWebSocketConnection({ socket: null, stompClient: null }));
                });
            }
        };
    }, [dispatch]);

    useEffect(() => {
        getGameData()
    }, [])

    const handleClipboardClick = () => {
        const textToCopy = gameLink;

        if (textToCopy) {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 2000);
                }
            } catch (err) {
                console.error('Kopyalama işlemi sırasında bir hata oluştu:', err);
            }

            document.body.removeChild(textArea);
        }
    };

    const seperateOperation = (gameToken) => {
        if (gameToken) {
            joinGame(gameToken)
        } else {
            createGame()
        }
    }

    const createGame = () => {
        axios.post('/multiplayer/createMultiplayerGame', {
            gameId: gameId,
            userId: userId,
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                console.log(response)
                setGameLink("https://animeguess.app/gameVsView/" + gameId + "?token=" + response.data.gameToken)
                subscribeToGameReady(response.data.gameToken)
            }, (error) => {
                console.log(error)
            });

    }

    const subscribeToGameReady = (gameToken) => {
        console.log("stomp client", stompClient)
        stompClient.subscribe('/topic/gameReady', (message) => {
            console.log(message);
            console.log(message.body);
            console.log(message.body == gameToken)
            if (message.body == gameToken) {
                console.log("game is ready to start")
                stompClient.unsubscribe('/topic/gameReady');
                navigate('/gameVs/' + gameId + "?token=" + gameToken, { state: { "playerNumber": 1 } });
            }
        });
    }

    const joinGame = (gameToken) => {
        axios.post('/multiplayer/joinMultiplayerGame', {
            gameId: gameId,
            userId: userId,
            token: accessToken,
            gameToken: gameToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                console.log(response)
                navigate('/gameVs/' + gameId + "?token=" + response.data.gameToken, { state: { "playerNumber": 2 } });
            }, (error) => {
                console.log(error)
            });
    }

    const getGameData = async () => {
        await axios.get('/game/withImage/' + gameId + (subId ? '?userId=' + subId : (localStorage.getItem('guestId') ? '?guestId=' + localStorage.getItem('guestId') : '')))
            .then((response) => {
                setGameData(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }
    const onClickRefreshButton = () => {
        window.location.reload();
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">An unexpected error occurred...</div>
                <div className="text-2xl font-bold text-red-500">Please try again later.</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div class='flex justify-center items-center text-white border-yellow-500 rounded-lg'>
                <div class="border-t-transparent mt-20 border-solid animate-spin rounded-full border-yellow-500 border-8 h-28 w-28">
                </div>
            </div>
        )
    } else
        return (

            <section className="font-bodyFont overflow-hidden ">

                {showNotification && (
                    <div role="alert" className=" absolute  m-2 z-50">
                        <div className="rounded-xl border  bg-green-400 p-4">
                            <div className="flex items-start gap-4">
                                <span >
                                    <FiInfo className="text-white w-10 h-10" />
                                </span>
                                <div className="flex-1 text-white">
                                    <strong className="block font-medium">Notification!</strong>
                                    <p className="mt-1 text-sm">Link copied successfully.</p>
                                </div>

                                <button
                                    className="text-gray-500 transition hover:text-gray-600"
                                    onClick={() => setShowNotification(false)}
                                >
                                    <span className="sr-only">Dismiss popup</span>

                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className='container lg:px-10 px-5 max-w-6xl  mx-auto mt-10'>
                    <div className='flex justify-center'>
                        <img
                            alt='Anime Guess - Guess The Anime image'
                            class="lg:w-1/2 w-full h-full object-contain object-center rounded-lg ring-1 ring-yellow-500 " src={`data:image/jpeg;base64,${gameData.gamePreviewImage}`} />
                    </div>
                    <div className='flex justify-center'>
                        <h1 class="text-white mt-1 lg:text-3xl md:text-xl sm:text-xl text-xl font-bold">{gameData.gameName}</h1>
                    </div>
                    <div className='flex justify-center gap-4 mt-1'>
                        <div class="flex text-white lg:text-lg text-sm">
                            Section Count:<span class="ml-2 text-yellow-500"> {gameData.sectionAmount}</span>
                        </div>
                        <div class="flex text-white lg:text-lg text-sm">
                            Difficulty Level:<span class={gameData.squareAmount === 1 ? "ml-2 text-green-500" :
                                gameData.squareAmount === 4 ? "ml-2 text-yellow-500" :
                                    gameData.squareAmount === 9 ? "ml-2 text-orange-500" : "ml-2 text-red-500"}>
                                {gameData.squareAmount === 1 ? "Easy" : gameData.squareAmount === 4 ? "Medium" : "Hard"}</span>
                        </div>
                    </div>
                    <div class="">

                        <div class="mx-auto flex items-center justify-center  mt-5 ">

                            <div class="flex items-center border-b-2 border-yellow-500 py-2">
                                <input
                                    class="appearance-none bg-transparent border-none w-full lg:text-2xl text-md text-gray-300 mr-3 py-1 px-2 leading-tight focus:outline-none"
                                    type="text"
                                    value={gameLink}
                                />
                                <button
                                    class="flex-shrink-0 lg:text-2xl text-md duration-200 bg-yellow-600 hover:bg-yellow-500 border-yellow-600 hover:border-yellow-500 border-4 text-white py-1 px-2 rounded"
                                    type="button"
                                    ref={clipboardRef}
                                    onClick={handleClipboardClick}
                                >
                                    Copy Link
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-center mt-2">
                        <div className="relative lg:w-32 lg:h-32 h-20 w-20">
                            <div className="absolute top-0 left-0 w-full h-full rounded-full bg-slate-600 border border-slate-800"></div>
                            <div
                                className="absolute top-0 left-0 w-full h-full rounded-full bg-yellow-500"
                                style={circleStyle}
                            ></div>
                            <div
                                className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-white"
                            >
                                <div className="lg:text-4xl md:text-xl text-lg">{seconds}</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center mt-5'>
                        {seconds == 0 ?
                            <FiRotateCcw onClick={onClickRefreshButton} className='lg:text-4xl text-3xl cursor-pointer text-yellow-500'></FiRotateCcw>
                            : null}
                    </div>
                    <div className="flex justify-center mt-2">
                        <button
                            className={' cursor-auto animate-pulse flex italic items-center align-middle rounded-lg lg:text-2xl md:text-xl sm:text-lg text-lg  lg:px-3 lg:py-2 px-2 py-2 font-medium text-white '}
                        >
                            Opponent Waiting
                        </button>
                    </div>
                </div>
            </section>
        )
}

export default VsGameView