import axios from 'axios';
import React, { useState } from 'react'
import { useToken } from '../../service/context/TokenProvider';

export default function AddRoleUser() {
    const [showModal, setShowModal] = React.useState(false);
    const [isSend, setIsSend] = useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData

    const [roleOperations, setRoleOperations] = useState({
        roleId: "",
        userId: ""
    })

    const onRoletInputChange = (e) => {
        setRoleOperations({ ...roleOperations, [e.target.name]: e.target.value });
        setIsSend(false);
    };


    const addRoleToUser = async (e) => {e.preventDefault();
        const roleId = document.getElementById("roleId").value;
        const userId = document.getElementById("userId").value;

        if (roleId.trim() === "" && userId.trim() === "") {
            alert("Lütfen gerekli alanları doldurun.");
            return;
        }

        const roleOperations = {
            roleId: roleId,
            userId: userId
        };

        await axios.post("/role/addRoleToUser", roleOperations, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(res => {
                setShowModal(false)
                alert("Kullanıcıya rol başarıyla eklendi")
            })
            .catch(err => {
                console.log(err)
                alert("Kullanıcıya rol ekleme başarısız.")
            })
            setRoleOperations({
                roleId: "",
                userId: ""
            })
            
    };


    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                }}
                type="button">
                <button
                    className="bg-green-600 hover:bg-green-700 duration-200 text-white font-medium py-2 px-4 rounded-lg mt-5"
                >
                    Kullanıcıya Rol Ekle
                </button>
            </a>

            {showModal ? (
                <>
                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-2xl">

                            {/*content*/}
                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}

                                <div className="relative block ml-4">
                                    {/*body*/}
                                    <div className="relative">
                                        <div className="font-bodyFont">
                                            <button
                                                onClick={() => setShowModal(false)}
                                                className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="p-4">
                                            <h2 className="text-2xl font-semibold"></h2>
                                            <form
                                                onSubmit={addRoleToUser}
                                            >
                                                <div className="mt-4">
                                                    <label className="text-xl">Kullanıcı Id:</label>
                                                    <input
                                                        onChange={(e) => onRoletInputChange(e)}
                                                        type="text"
                                                        id="userId"
                                                        name="userId"
                                                        value={roleOperations.userId}

                                                        required
                                                        className="block w-full px-4 mt-2 text-xl bg-zinc-900 py-2 border rounded focus:outline-none focus:border-red-500"
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <label className="text-xl">Role Id:</label>
                                                    <input
                                                        onChange={(e) => onRoletInputChange(e)}
                                                        type="text"
                                                        id="roleId"
                                                        name="roleId"
                                                        value={roleOperations.roleId}

                                                        required
                                                        className="block w-full px-4 mt-2 text-xl bg-zinc-900 py-2 border rounded focus:outline-none focus:border-red-500"
                                                    />
                                                </div>

                                                <div className="flex justify-end mt-6">
                                                    <button
                                                        type='submit'
                                                        className="px-4 py-2 bg-zinc-900  text-white rounded hover:bg-zinc-700 duration-200 focus:outline-none"
                                                    >
                                                        Kaydet
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

}