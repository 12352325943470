
import React, { useEffect, useState } from 'react'
import CategoryGames from "./CategoryGames";
import LoadingCategoryGame from './loading/LoadingCategoryGame';
import { FaCircleArrowDown } from 'react-icons/fa6'
import axios from 'axios';

function CategoryAllGamesPanel(props) {
    const { categoryId } = props

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [games, setGames] = useState([])
    const [page, setPage] = useState(0)
    const [lastPage, setLastPage] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const getGames = () => {
        setIsLoading(true)
        axios.get('/game/getAllGameTypeGamePageable/' + categoryId + '?page=' + page + '&size=3')
            .then(response => {
                setGames(prevGames => prevGames.concat(response.data.content))
                setLastPage(response.data.last)
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const nextPage = () => {
        setPage(prevPage => prevPage + 1)
    }

    useEffect(() => {
        if (page > 0) {
            getGames()
        }
    }, [page])

    useEffect(() => {
        getGames()
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setSlidesPerView(2);
            } else {
                setSlidesPerView(3);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize(); // Component ilk render olduğunda da çalıştırılsın
        return () => {
            window.removeEventListener('resize', handleResize); // Component unmount olduğunda event listener'ı temizle
        };
    }, []);
    return (
        <div
            className="mx-auto mt-20"
        >
            <div className="max-w-7xl items-end justify-between sm:flex sm:pe-6 lg:pe-8 text-white">
                {categoryId == 4 ? (
                    <h2 className="lg:text-3xl sm:text-xl font-bold  text-white">
                        All Games
                    </h2>
                ) : (<h2 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    All Games
                </h2>)
                }
            </div>

            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-10">
                {
                    games.length == 0 ?
                        <>
                            <LoadingCategoryGame></LoadingCategoryGame>
                            <LoadingCategoryGame></LoadingCategoryGame>
                            <LoadingCategoryGame></LoadingCategoryGame>
                        </>
                        :
                        games.map((game) => (
                            <CategoryGames game={game}></CategoryGames>
                        ))
                }

            </div>

            {isLoading ?
                <div className='flex justify-center mt-10'>
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-indigo-400 border-8 h-12 w-12"></div>
                </div>
                :
                !lastPage ?
                    <div className='flex justify-center items-center mt-10'>
                        <FaCircleArrowDown
                            onClick={nextPage}
                            className='animate-bounce lg:h-14 lg:w-14 h-10 w-10 text-indigo-400 cursor-pointer'
                        ></FaCircleArrowDown>
                    </div>
                    :
                    null
            }

        </div>
    )
}

export default CategoryAllGamesPanel