import React, { useEffect, useState, useRef } from 'react'
import { FiClipboard, FiInfo } from "react-icons/fi";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getAvatarImageFromLocalStorage } from '../../service/Avatar';
import { formatDate } from '../../service/FormatDate';
import { useToken } from '../../service/context/TokenProvider';
import SessionStatus from '../../service/SessionStatus';
import GuestView from './GuestView';
import design8 from '../../assets/design8.png'
import { GiCrossedSwords } from "react-icons/gi";
import { Helmet } from 'react-helmet';

function ViewGame() {
    const navigate = useNavigate();
    const { gameId } = useParams();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [gameData, setGameData] = useState([]);
    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [leaderboard, setLeaderboard] = useState([])
    const [session, setSession] = useState(false)
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;
    const clipboardRef = useRef(null);
    const [showNotification, setShowNotification] = useState(false);

    const getGameData = async () => {
        await axios.get('/game/withImage/' + gameId + (subId ? '?userId=' + subId : (localStorage.getItem('guestId') ? '?guestId=' + localStorage.getItem('guestId') : '')))
            .then((response) => {
                setGameData(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    const fillLeaderboard = () => {
        axios.get('/played/game/' + gameId)
            .then((response) => {
                setLeaderboard(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const startGame = (e) => {
        e.preventDefault();
        axios.post('/played/check', {
            userId: subId,
            gameId: gameId
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(response => {

                if (localStorage.getItem('guestId')) {
                    axios.post('/guestplayed/check?gameId=' + gameId + '&guestId=' + localStorage.getItem('guestId'), {}, {
                        headers: {
                            Authorization: accessToken
                        }
                    })
                        .then((guestPlayedResponse) => {
                            startGameSession(response.data.gamePlayed, guestPlayedResponse.data)
                        })
                        .catch((error) => {
                            console.error(error)
                        })
                } else {
                    startGameSession(response.data.gamePlayed, false)
                }

            })
            .catch(error => {
                if (error.response.data.message === "Access Denied") {
                    setIsLoaded(true);
                    setSession("Session has expired, please log in again.");
                    return
                }
                console.error(error)
                setError("Please try again later or contact support.")
            })
    }

    const startGameSession = (userGamePlayed, guestGamePlayed) => {
        axios.post('/section/startgame', {
            gameId: gameId,
            userId: subId,
            transferPlay: guestGamePlayed == true ? true : false,
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(startResponse => {
                if (userGamePlayed == true || guestGamePlayed == true) {
                    navigate('/game/' + gameId, { state: { "isGamePlayedState": true, "navigated": true } });
                } else {
                    navigate('/game/' + gameId, { state: { "isGamePlayedState": false, "navigated": true } });
                }
            })
    }

    const handleClipboardClick = () => {
        const textToCopy = window.location.href;

        if (textToCopy) {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 2000);
                }
            } catch (err) {
                console.error('Kopyalama işlemi sırasında bir hata oluştu:', err);
            }

            document.body.removeChild(textArea);
        }
    };
    const sectionStyle = {
        backgroundAttachment: 'scroll',
        backgroundPosition: ' bottom  right ',
        backgroundImage: windowWidth > 767 ? `url(${design8})` : 'none',
        backgroundSize: '15%',
        backgroundRepeat: 'no-repeat',
        // Ensure the container covers the entire viewport height
    };


    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
        
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getGameData()
        fillLeaderboard()
    }, [])



    if (session) {
        return (
            <SessionStatus session={session}></SessionStatus>
        )
    } else if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">An unexpected error occurred...</div>
                <div className="text-2xl font-bold text-red-500">Please try again later.</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div class='flex justify-center items-center text-white border-indigo-400 rounded-lg'>
                <div class="border-t-transparent mt-20 border-solid animate-spin rounded-full border-indigo-400 border-8 h-28 w-28">
                </div>
            </div>
        )
    } else
        return (
            <section
                style={sectionStyle} class="text-gray-600 font-bodyFont overflow-hidden  h-screen" >
                <Helmet>
                    <title>Anime Guess - Game View</title>
                    <meta name="description" content="Anime Guess - Enjoy the gaming experience, and compete to be on the top player list! Anime guess from the pictures! The best anime characters and anime hentai games are here!" />
                </Helmet>
                {showNotification && (
                    <div role="alert" class=" absolute  m-2">
                        <div className="rounded-xl border  bg-green-400 p-4">
                            <div className="flex items-start gap-4">
                                <span >
                                    <FiInfo className="text-white w-10 h-10" />
                                </span>
                                <div className="flex-1 text-white">
                                    <strong className="block font-medium">Notification!</strong>
                                    <p className="mt-1 text-sm">Link copied successfully.</p>
                                </div>

                                <button
                                    className="text-gray-500 transition hover:text-gray-600"
                                    onClick={() => setShowNotification(false)}
                                >
                                    <span className="sr-only">Dismiss popup</span>

                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div class="container  lg:px-10 px-5 max-w-6xl  mt-10 mx-auto">
                    <div class=" mx-auto flex flex-wrap">
                        <img
                            alt='Anime Guess - Guess The Anime image'
                            class="lg:w-1/2 w-full h-full object-contain object-center rounded-lg ring-1 ring-indigo-400 " src={`data:image/jpeg;base64,${gameData.gamePreviewImage}`} />
                        <div class="lg:w-1/2 w-full lg:pl-10">
                            <h1 class="text-white lg:text-3xl md:text-xl sm:text-xl text-xl title-font font-medium mb-1">{gameData.gameName}</h1>
                            <h3 class="text-gray-600 lg:text-base md:text-lg sm:text-sm text-sm title-font font-medium mb-1">{formatDate(gameData.createDate)}</h3>
                            <div class="flex text-white  lg:text-lg text-sm">
                                Type:<Link target='_blank' to={"/categoryPage/" + gameData.gameType.id}><span class="ml-2 hover:text-indigo-300 duration-200">{gameData.gameType.gameTypeName}</span></Link>
                            </div>
                            <div class="flex text-white lg:text-lg text-sm">
                                Section Count:<span class="ml-2"> {gameData.sectionAmount}</span>
                            </div>
                            <div class="flex text-white lg:text-lg text-sm">
                                Difficulty Level:<span class={gameData.squareAmount === 1 ? "ml-2 text-green-500" :
                                    gameData.squareAmount === 4 ? "ml-2 text-yellow-500" :
                                        gameData.squareAmount === 9 ? "ml-2 text-orange-500" : "ml-2 text-red-500"}>
                                    {gameData.squareAmount === 1 ? "Easy" : gameData.squareAmount === 4 ? "Medium" : "Hard"}</span>
                            </div>
                            <div className="flex mt-6 items-center text-white pb-5 border-b font-medium border-gray-500 mb-5 relative">
                                <span
                                    ref={clipboardRef}
                                    onClick={handleClipboardClick}
                                >
                                    <FiClipboard className="text-green-500 w-6 h-6 hover:text-green-400 hover:-translate-y-1 cursor-pointer duration-200"
                                    />
                                </span>

                                <Link to="/oursite">
                                    <FiInfo className='ml-4 text-blue-500 w-6 h-6 hover:text-blue-400 hover:-translate-y-1 cursor-pointer duration-200' />
                                </Link>

                                {/* <Link to={"/gameVsView/" + gameId} >
                                    <GiCrossedSwords
                                        className={`ml-4 absolute bottom-4 text-yellow-500 hover:text-yellow-400 hover:scale-125 animate-pulse cursor-pointer game-icon-container inline-block transition-transform duration-300  transform ${isScaled ? ' animate-none' : ''}`}
                                        style={{
                                            transition: 'width 5s, height 5s',
                                            width: isScaled ? '7rem' : '2rem',
                                            height: isScaled ? '7rem' : '2rem'
                                        }}
                                    />
                                </Link> */}
                            </div>

                            <div class="flex">
                                {subId === null
                                    ?
                                    <GuestView gameId={gameId} isGamePlayed={gameData.isGamePlayed}/>
                                    :
                                    <button
                                        class="group relative w-full inline-block overflow-hidden border border-indigo-400 px-8 py-4 focus:outline-none focus:ring rounded-lg"
                                        onClick={startGame}
                                    >
                                        <span
                                            class="absolute  inset-y-0 left-0 w-[2px] rounded-md duration-200 bg-indigo-400 transition-all group-hover:w-full group-active:bg-indigo-400"
                                        ></span>
                                        <span
                                            class="relative text-lg font-medium text-white transition-colors group-hover:text-white"
                                        >
                                            {gameData.isGamePlayed ? 'Play Again' : 'Play'}
                                        </span>
                                    </button>
                                }

                            </div>
                            <Link to={"/gameVsView/" + gameId}>
                            <button
                                class="group relative w-full inline-block overflow-hidden border border-yellow-600 px-8 py-4 mt-2 focus:outline-none focus:ring rounded-lg"

                            >
                                <span
                                    class="absolute  inset-y-0 left-0 w-[2px] rounded-md duration-200 bg-yellow-600 transition-all group-hover:w-full group-active:bg-yellow-400"
                                ></span>
                                <span
                                    class="relative text-lg font-medium text-white transition-colors group-hover:text-white"
                                >
                                    <div className='flex justify-center'>
                                        <GiCrossedSwords
                                            className={` text-white w-7 h-7  hover:scale-150 inline-block transition-transform duration-300 mr-1`}
                                        />
                                        Play Multiplayer!
                                    </div>
                                </span>
                            </button>
                        </Link>
                        <p className=' cursor-default lg:text-3xl text-xl mt-1 italic text-white justify-center text-center animate-pulse'>Multiplayer available now!</p>
                    </div>
                    <div className='lg:w-1/2 h-full lg:-mt-8 text-white my-5 ring-1 ring-indigo-400 shadow-md bg-gray-900 shadow-black rounded-sm'>
                        <table className="">
                            <thead className="w-full border-b font-medium border-gray-500 text-white bg-gray-800 ">
                                <tr className=''>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">Rank</th>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">Nick</th>
                                    <th scope="col" className="w-1/3 py-2 lg:text-xl sm:text-xs text-xs">Score</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {leaderboard.length !== 0 ? (
                                    leaderboard.map((item, index) => (
                                        <tr className="border-neutral-500 lg:text-xl sm:text-xs text-xs">
                                            <div className='flex justify-center'>
                                                <td className="whitespace-nowrap px-8 py-2 lg:text-xl sm:text-xs">{index + 1}</td>
                                            </div>
                                            <td className="whitespace-nowrap px-8 py-2">
                                                <div className='flex items-center'>
                                                    <img src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(item.userAvatarName)}`} className="lg:h-8 lg:w-8 sm:w-6 sm:h-6 h-6 w-6 rounded-full" />
                                                    <Link className='hover:text-indigo-300 duration-200' to={"/userProfile/" + item.userId}>
                                                        <div className='ms-2 truncate'>
                                                            {item.userName}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <div className='flex justify-center'>
                                                <td className="whitespace-nowrap px-8 py-2">{item.score}</td>
                                            </div>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="border-neutral-500 lg:text-xl sm:text-xs text-xs">
                                        <div className='flex justify-center'>
                                            <td className="whitespace-nowrap px-8 py-2 lg:text-xl sm:text-xs"></td>
                                        </div>
                                        <td className="whitespace-nowrap px-8 py-2">
                                            <div className='flex items-center'>
                                                <div className='ms-2 truncate'>
                                                    Leaderboard not found.
                                                </div>
                                            </div>
                                        </td>
                                        <div className='flex justify-center'>
                                            <td className="whitespace-nowrap px-8 py-2"></td>
                                        </div>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                </div>

            </div>
            </section >
        )
}

export default ViewGame
