import { Disclosure, Menu } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useToken } from '../../service/context/TokenProvider';
import { getAvatarImageFromLocalStorage } from '../../service/Avatar';
import logo from '../../assets/logo.png'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
    const { tokenData, resetTokenData } = useToken();
    const { subId } = tokenData;

    var navigate = useNavigate();

    const navigation = [
        { name: "Home", to: '/', current: false },
        { name: "Top Players", to: '/topListPlayer', current: false },
    ]


    const onLogoutClicked = () => {
        localStorage.removeItem("signedRefreshToken");
        localStorage.removeItem("signedUserAvatarName");
        localStorage.removeItem("signedUserId");
        navigate("/");
        resetTokenData();
        //window.location.reload();
    };


    return (

        <Disclosure as="nav" className="bg-midnight sticky top-0 z-50 w-full ring-1 ring-gray-500 font-bodyFont">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-6xl px-4 py-3">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {/* Hamburger icon */}
                                    {open ? (
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    ) : (
                                        <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex items-center justify-center">
                                <div className="">
                                    <img
                                        onClick={() => navigate('/')}
                                        src={logo}
                                        className="lg:h-14 md:h-10 sm:h-8 h-8 lg:ml-0 md:ml-0 ml-16 flex cursor-pointer"
                                        alt="Your Company"
                                    />
                                </div>
                                <div className="hidden sm:flex m-auto text-sm lg:text-xl lg:flex md:flex items-center justify-center">
                                    <div className="flex ml-10 gap-6">
                                        {navigation.map((item, index) => (
                                            <Link
                                                key={index}
                                                to={item.to}
                                                onClick={() => {
                                                    window.scroll({
                                                        top: 0,
                                                        left: 0,
                                                        behavior: 'smooth',
                                                    });
                                                }}
                                            >
                                                <button
                                                    key={item.name}
                                                    className={classNames('text-gray-300 hover:text-indigo-400 duration-200 rounded-md  font-medium')}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </button>
                                            </Link>
                                        ))}
                                        <Link
                                            to="https://gameguesser.xyz"
                                            target="_blank"
                                            onClick={() => {
                                                window.scroll({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth',
                                                });
                                            }}
                                        >
                                            <button
                                                className={classNames('text-gray-300 hover:text-indigo-400 duration-200 rounded-md font-medium')}
                                            >
                                                Game Guesser
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {/* User Avatar */}
                                <div className="flex-shrink-0">
                                    {/* Your avatar component or image */}
                                </div>
                            </div>



                            <div className=" flex items-center sm:static ">
                                {/* <Translate /> */}

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-5">

                                    {subId === null ?
                                        (
                                            <Link to="/login">
                                                <button className="flex lg:text-md  items-center px-4 py-2 font-medium tracking-wide text-white transition-colors duration-200 transform   hover:border-white  bg-indigo-500  rounded-lg hover:bg-indigo-400 focus:outline-none focus:ring ">
                                                    <span className="mx-1"> Login</span>
                                                </button>
                                            </Link>

                                        )
                                        : (
                                            <Menu.Button
                                                className="relative flex rounded-full bg-slate-950 text-sm focus:outline-none ">
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="lg:h-16 lg:w-16 sm:h-16 sm:w-16 h-14 w-14 rounded-full ring-2 ring-indigo-400"
                                                    src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(localStorage.getItem("signedUserAvatarName"))}`}
                                                />
                                            </Menu.Button>
                                        )}

                                    {/* Dropdown menu */}
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-slate-900 py-1 shadow-md shadow-slate-800  ring-1 ring-zinc-600">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to={"/userProfile/" + localStorage.getItem("signedUserId")}>
                                                    <button
                                                        href="#"
                                                        className={classNames(active ? 'bg-slate-700  ' : '', 'flex items-start  w-full px-4 py-2 text-sm text-white ')}
                                                    >
                                                        Your Profile
                                                    </button>
                                                </Link>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={onLogoutClicked}

                                                    className={classNames(active ? 'bg-slate-700' : '', 'flex items-start  w-full px-4 py-2 text-sm text-white')}
                                                >
                                                    Sign Out
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>

                            </div>
                        </div>

                    </div>

                    {/* Mobile menu */}
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item, index) => (

                                <Link
                                    key={index}
                                    to={item.to}
                                    onClick={() => {
                                        window.scroll({
                                            top: 0,
                                            left: 0,
                                            behavior: 'smooth',
                                        });
                                    }}
                                >
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        className={classNames(
                                            item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>

                                </Link>
                            ))}
                            <Link
                                to={"https://gameguesser.xyz"}
                                target="_blank"
                                onClick={() => {
                                    window.scroll({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth',
                                    });
                                }
                                }
                            >
                                <button
                                    className={classNames('text-gray-300 hover:text-indigo-500 duration-200 rounded-md px-3 py-2 font-medium ')}
                                >
                                    Game Guesser
                                </button>
                            </Link>
                        </div>
                    </Disclosure.Panel>
                </>
            )
            }
        </Disclosure >

    )

}