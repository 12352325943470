import axios from 'axios';
import React, {  useState } from 'react'
import {  FaCircleUser, FaTrash, FaMessage } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import { formatDate } from '../../service/FormatDate';

export default function AdminViewUser(props) {
    const [showModal, setShowModal] = React.useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [isLoaded, setIsLoaded] = useState(false);
    const { id, children, userMail } = props;
    const [error, setError] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [user, setUser] = useState()


    const getUserById = async () => {
        await axios.get('/user/getOne/' + id, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                setUser(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }
    const bannedUser = async () => {
        try {
            const response = await axios.post("/bannedAccount/add", {
                userMail: user.userMail
            }, {
                headers: {
                    Authorization: accessToken
                }
            });
            setIsSend(true);
            setShowModal(false);
        } catch (error) {
            console.error("Hata oluştu: ", error);
        }
    };

    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                    getUserById()
                }}
                type="button">
                {children}
            </a>

            {showModal ? (
                <>
                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-2xl">
                            {/*content*/}
                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                {isLoaded ? (
                                    <div className="relative block ml-4">
                                        {/*body*/}
                                        <div className="relative">
                                            <div className="font-bodyFont">
                                                <button
                                                    className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                                <div className='p-4'>
                                                    <p className='text-xl'>id: <span className='text-blue-400'> {user.id}</span>  </p>
                                                    <p className='text-xl'>Nick:<span className='text-blue-400'> {user.userName}</span>  </p>
                                                    <p className='text-xl'>E-Mail: <span className='text-blue-400'> {user.userMail}</span>  </p>
                                                    <p className='text-xl'>Avatar id: <span className='text-blue-400'> {user.avatar.id}</span>  </p>
                                                    <p className='text-xl'>CreateDate: <span className='text-blue-400'> {formatDate(user.createDate)} </span> </p>
                                                    <p className='text-xl'>UpdateDate: <span className='text-blue-400'> {formatDate(user.updateDate)} </span> </p>
                                                    <p className='text-xl'>Verified: <span className='text-blue-400'> {user.isVerified ? 'true' : 'false'}</span> </p>
                                                </div>
                                                <div className='flex my-5 justify-evenly'>
                                                    <Link to={"/userProfile/" + id}>
                                                        <FaCircleUser className='mt-5  hover:-translate-y-1 duration-200  cursor-pointer text-3xl mr-5 text-green-500 hover:text-green-600'></FaCircleUser>
                                                    </Link>
                                                    <a href={`mailto:${user.userMail}`} className='mt-5 hover:-translate-y-1 duration-200 cursor-pointer text-3xl mr-5 text-blue-500 hover:text-blue-600'>
                                                        <FaMessage />
                                                    </a>
                                                    <FaTrash onClick={() => bannedUser()} className='mt-5  hover:-translate-y-1 duration-200 cursor-pointer text-3xl mr-5 text-red-500 hover:text-red-600' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="fixed inset-0 flex items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                                        <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-24"></div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

}