import React from 'react'
import { Link } from 'react-router-dom'

function CategoryGames(props) {
    const { game } = props

    return (
        <Link to={"/gameView/" + game.id}>
            <div href="#" className="block cursor-pointer rounded-t-lg hover:cursor-pointer duration-200 hover:-translate-y-3 rounded-lg ring-1 ring-indigo-400">
                <img
                    src={`data:image/jpeg;base64,${game.gamePreviewImage}`}
                    className=" w-full object-fill h-32 sm:h-32 lg:h-40  rounded-t-lg"
                />
                <div className='w-full rounded-lg shadow-lg bg-slate-900  rounded-b-lg'>
                    <div className="py-2 text-center ">
                        <a href="#" className="block lg:text-lg sm:text-sm text-sm   text-white" tabIndex="0" role="link">{game.gameName}</a>
                    </div>
                </div>
            </div >
        </Link>
    )
}

export default CategoryGames