import axios from "axios"

export const getAvatarImageFromLocalStorage = (avatarName) => {
    const unparsedAvatars = localStorage.getItem("avatars")
    if (unparsedAvatars === null) {
        return null
    } else {
        const avatars = JSON.parse(unparsedAvatars)
        const avatar = avatars.find((avatar) => avatar.avatarName === avatarName)
        if (avatar === undefined) {
            return null
        } else {
            return avatar.avatarImage
        }
    }
}

export const initializeAvatars = () => {
    axios.get("/avatar/getAll")
    .then((response) => {
        const unparsedAvatars = localStorage.getItem("avatars")
        if (unparsedAvatars === null) {
            axios.get("/avatar/getAllWithImage")
                .then((response) => {
                    localStorage.setItem("avatars", JSON.stringify(response.data))
                })
            
        } else {
            const localAvatars = JSON.parse(unparsedAvatars)
            localAvatars.forEach((localAvatar) => {
                const matchingAvatar = response.data.find((avatar) => avatar.avatarName === localAvatar.avatarName)
                if (matchingAvatar === undefined) {
                    const index = localAvatars.indexOf(localAvatar)
                    localAvatars.splice(index, 1)
                } else {
                    if (localAvatar.avatarToken !== matchingAvatar.avatarToken) {
                        const index = localAvatars.indexOf(localAvatar)
                        axios.get("/avatar/" + localAvatar.avatarName)
                            .then((response) => {
                                localAvatars[index] = response.data
                                localStorage.setItem("avatars", JSON.stringify(localAvatars))
                            })
                    }
                }
            })
            // local ve remote arasindaki farki bul eger remote da yeni varsa local ekle
            response.data.forEach((remoteAvatar) => {
                const matchingAvatar = localAvatars.find((avatar) => avatar.avatarName === remoteAvatar.avatarName)
                if (matchingAvatar === undefined) {
                    axios.get("/avatar/" + remoteAvatar.avatarName)
                        .then((res) => {
                            localAvatars.push(res.data)
                            localStorage.setItem("avatars", JSON.stringify(localAvatars))
                        })
                }
            })
            localStorage.setItem("avatars", JSON.stringify(localAvatars))
        }
    })
}