import React, { useState } from 'react'
import { FaLock } from 'react-icons/fa6';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export default function ResetPassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const data = urlParams.get('data');

    let navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [rePassword, setRePassword] = useState(null);
    const [user, setUser] = useState({
        userMail: "",
        verificationCode: "",
        newPassword: ""
    })

    const closeAlert = () => {
        setError(null);
    };

    useState(() => {
        const decodedData = jwt_decode(data);
        if (decodedData.exp < Date.now() / 1000) {
            setError("Reset link expired.");
            setTimeout(() => {
                navigate('/login');
            }, 2000);
            return;
        }
        const userData = JSON.parse(decodedData.sub);
        let userCopy = { ...user };
        userCopy.userMail = userData.userMail;
        userCopy.verificationCode = userData.verificationCode;
        setUser(userCopy);
    }, [data])

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const validateFormInput = (e) => {
        e.preventDefault();

        if (user.newPassword !== rePassword) {
            setError("Passwords are not same.");
            return;
        }

        axios.post('/user/resetPassword/check', user)
            .then((response) => {
                setIsSuccess(true);
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            })
            .catch((error) => {
                setError(error.response.data);
            });
    }

    return (
        <div>
            {isSuccess ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <label className="block  text-2xl text-white my-2 text-left">
                        Password reset successful
                    </label>
                </div>
                :
                null
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-slate-900 opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-indigo-500 bg-slate-950 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <span className="sr-only">Kapat</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}
            <div className="mx-auto min-h-screen px-4 py-16 sm:px-6 lg:px-8 font-bodyFont bg-zinc-90  ">
                <div className='mx-auto max-w-lg mt-20 '>
                    <h1 className="text-left lg:text-6xl font-bold text-white text-5xl">
                    Reset Password
                    </h1>
                </div>
                <div className="mx-auto mt-5 max-w-lg border base-border border-indigo-500 rounded-md sm:p-6 lg:p-8  ">
                    <form
                        onSubmit={validateFormInput}
                        action=""
                        className="mt-6 space-y-4 rounded-lg  "
                    >
                        <div>

                            <div className="relative ">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="password"
                                    id="newPassword"
                                    maxLength={30}
                                    name="newPassword"

                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm   bg-gray-800"
                                    placeholder="Enter password"
                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaLock className='text-gray-400'></FaLock>

                                </span>
                            </div>
                        </div>

                        <div>


                            <div className="relative ">
                                <input
                                    onChange={(e) => setRePassword(e.target.value)}
                                    required
                                    type="password"
                                    id="re-password"
                                    maxLength={30}
                                    name="re-password"

                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm   bg-slate-950"
                                    placeholder="Re-enter password"
                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaLock className='text-gray-400'></FaLock>

                                </span>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="block w-full  rounded-lg bg-indigo-500 hover:bg-indigo-400 duration-200 px-5 py-3 text-xl font-medium text-white"
                        >
                            Reset Password
                        </button>

                    </form>
                </div>
            </div>
        </div>
    )
}
