import React, { useEffect, useState } from 'react'
import { FaImage } from "react-icons/fa6";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import SessionStatus from '../../service/SessionStatus';
import { useToken } from '../../service/context/TokenProvider';

function AdminCreateGame() {
    const navigate = useNavigate();
    const { tokenData } = useToken();
    const { userRoles, accessToken, subId } = tokenData
    const [gameTypes, setGameTypes] = useState([]);
    const [session, setSession] = useState(false);
    const [sectionAmount, setSectionAmount] = useState(5);
    const [gamePreviewImage, setGamePreviewImage] = useState();

    const [game, setGame] = useState({
        gameName: "",
        gameCreatorUserId: subId,
        gameTypeId: "",
        gamePreviewImage: "",
        squareAmount: 1
    });
    const [sectionImages, setSectionImages] = useState([]);
    const [sectionNames, setSectionNames] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const fillGameType = () => {
        axios.get('/gameType/getAll')
            .then(res => {
                setGameTypes(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const createGame = (e) => {
        e.preventDefault();

        // bolum fotograflarini kontrol et
        if (Object.keys(sectionImages).length != sectionAmount) {
            setError("Please make sure you have selected the image for all sections.");
            return
        }

        // oyun fotosunu kontrol et
        if (game.gamePreviewImage == "") {
            setError("Please select the image that will appear on the game panel.");
            return
        }

        // oyunun katagorisini kontrol et
        if (game.gameTypeId == "") {
            setError("Please choose the category of the game.");
            return
        }

        const formData = new FormData();

        Object.keys(game).forEach((key) => {
            formData.append(key, game[key]);
        });

        formData.append('token', accessToken)

        setIsLoading(true)
        axios.post('/game/add', formData, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(async (res) => {
                await createSections(res.data.gameId)
            })
            .catch(err => {
                if (err.response.data.message === "Access Denied") {
                    setIsLoading(false);
                    setSession("Session has expired, please log in again.");
                    return
                } else if (err.response.data.message === "Image is null") {
                    setIsLoading(false)
                    setError("Please select png or jpg format. Changing the name extension of the file is not enough.")
                    return
                } else if (error.response.data.message === "Image format is not supported") {
                    setIsLoading(false)
                    setError("Please select png or jpg format.")
                    return
                }
                console.log(err)
                setError("An error occurred while creating the game.")
                setIsLoading(false)
            })
    }

    const createSections = async (gameId) => {
        let hasError = false
        let loggedErrors = []
        let loggedIndexes = []
        let formData = null
        let index = 1
        for (const key of Object.keys(sectionNames)) {
            formData = new FormData();
            formData.append('gameId', gameId);
            formData.append('sectionCorrectAnswer', sectionNames[key].trim().replace(/\s+/g, ' '));
            formData.append('sectionImage', sectionImages[key]);
            formData.append('token', accessToken)
            try {
                await axios.post('/section/add', formData, {
                    headers: {
                        Authorization: accessToken
                    }
                })
            } catch (error) {
                loggedErrors.push(error)
                loggedIndexes.push(index)
                console.log(error)
                hasError = true
            }
            index += 1
        }

        let errShowed = false
        loggedErrors.forEach((error) => {
            if (error.response.data.message === "Image is null") {
                setIsLoading(false)
                setError("Please select png or jpg format. Changing the name extension of the file is not enough." + " Images: " + loggedIndexes.join(", "))
                errShowed = true
                return
            } else if (error.response.data.message === "Image format is not supported") {
                setIsLoading(false)
                setError("Please select png or jpg format." + " Images: " + loggedIndexes.join(", "))
                errShowed = true
                return
            }
        })

        if (!errShowed && hasError) {
            setIsLoading(false)
            alert("Oyun oluşturulurken bir hata oluştu")
        } 
        if (!hasError)  {
            alert("Oyun başarıyla oluşturuldu")
            navigate("/gameView/" + gameId)
        } else {
            axios.delete('/game/rollbackCreatedGame', {
                data: {
                    gameId: gameId,
                    token: accessToken
                },
                headers: {
                    Authorization: accessToken
                }
            })
            .then(res => {
                
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    const onGameInputChange = (e) => {
        setGame({ ...game, [e.target.name]: e.target.value });
    };

    const onGamePreviewImageInputChange = (e) => {
        const file = e.target.files[0];
        console.log(file)
        if (userRoles.includes("ROLE_ADMIN") == true) {
            if (file?.type != "image/jpeg" && file?.type != "image/png" && file?.type != "image/gif") {
                setError("Please select png or jpg format.");
                return
            }
        } else {
            if (file?.type != "image/jpeg" && file?.type != "image/png") {
                setError("Please select png or jpg format.");
                return
            }
        }
        if (file.size > 10000000) {
            setError("Please select an image smaller than 10MB.");
            return
        }
        setGame({ ...game, [e.target.name]: file });
        if (file) {
            setGamePreviewImage(URL.createObjectURL(file));
        }
    };

    const onSectionPreviewImageInputChange = (e) => {
        const file = e.target.files[0];
        if (file?.type != "image/jpeg" && file?.type != "image/png") {
            setError("Please select png or jpg format.");
            return
        }
        if (file.size > 10000000) {
            setError("Please select an image smaller than 10MB.");
            return
        }
        setSectionImages({ ...sectionImages, [e.target.name]: file });
    };

    const onInputChange = (e) => {
        setSectionAmount(e.target.value)
    };

    const onSectionNamesInputChange = (e, index) => {
        setSectionNames({ ...sectionNames, [`section-${index}`]: e.target.value })
    };
    const closeAlert = () => {
        setError(null);
    };

    useEffect(() => {
        fillGameType()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);

    return (
        <div>
            {isLoading ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-12"></div>
                </div>
                :
                null
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <span className="sr-only">Kapat</span>
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-2xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}
            <div className="mx-auto max-w-7xl sm:px-20 lg:px-16  font-bodyFont">
                {session && (
                    <SessionStatus session={session}></SessionStatus>
                )}
                <div className="flex justify-center mx-auto my-10">
                    <p className='lg:text-3xl  sm:text-lg text-white'>Create Game </p>

                </div>
                <div className="mx-auto max-w-3xl   ">

                    <form className=" " onSubmit={createGame}>

                        <div className=' '>

                            <div className="flex flex-col md:flex-row w-full my-2 md:gap-2 justify-center items-center">
                                <div className="flex-1 md:mr-2 mb-4 md:mb-0">
                                    <label htmlFor="HeadlineAct" className="block text-white my-2 lg:text-xl sm:text-sm">
                                        Category
                                    </label>
                                    <select
                                        onChange={(e) => onGameInputChange(e)}
                                        name="gameTypeId"
                                        id="gameTypes"
                                        className="lg:w-full md:w-44 sm:80 w-80  py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                    >
                                        <option value="" disabled selected>
                                        Please select category
                                        </option>
                                        {gameTypes.map((key, index) => (
                                            <option value={key.id} key={index}>
                                                {key.gameTypeName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex-1 md:flex items-center">
                                    <div className="flex-1 mb-4 md:mb-0 md:mr-2">
                                        <label htmlFor="section" className="block text-white my-2 lg:text-xl sm:text-sm">
                                        Episodes
                                        </label>
                                        <select
                                            onChange={(e) => onInputChange(e)}
                                            name="sectionAmount"
                                            id="section"
                                            className="lg:w-full md:w-44 sm:80 w-80 py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                        >
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                    <div className="flex-1 md:ml-2">
                                        <div className="flex-1 mb-4 md:mb-0 md:mr-2">              
                                                <label htmlFor="section" className="flex justify-between text-white my-2 lg:text-xl sm:text-sm">
                                                    Frames
                                                </label>    
                                            <select
                                                onChange={(e) => onGameInputChange(e)}
                                                name="squareAmount"
                                                id="squareAmount"
                                                className="lg:w-full md:w-44 sm:80 w-80 py-3 px-2 appearance-none bg-zinc-900 text-white rounded-lg border border-gray-200 lg:text-xl sm:text-sm"
                                            >
                                                <option value="1">1</option>
                                                <option value="4">4</option>
                                                <option value="9">9</option>
                                                <option value="16">16</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col md:flex-row w-full my-2 md:gap-2 justify-center items-center">
                            <label htmlFor="HeadlineAct" className="block  text-center  lg:text-xl sm:text-sm text-white mt-4 mb-2 ">
                                Game Name
                            </label>
                            <input
                                onChange={(e) => onGameInputChange(e)}
                                placeholder="Game Name"
                                required
                                type="text"
                                id="gameName"
                                name="gameName"
                                maxLength={40}
                                className=" lg:w-full sm:w-80 w-80  appearance-none py-3 px-2 text-white lg:text-xl sm:text-sm bg-zinc-900 border border-gray-200 rounded-md   focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring" />
                        </div>
                        <div className="flex flex-col items-center">
                            <label htmlFor="HeadlineAct" className="block  lg:text-xl sm:text-sm text-white  ">
                            Image displayed in the panel

                            </label>
                            <div>
                                <label htmlFor="dropzone-file" className="flex flex-col items-center sm:w-24 lg:w-56 w-24 p-5 mt-2 text-center  border-2  border-dashed cursor-pointer bg-gray-900 border-gray-700 rounded-xl">
                                    {
                                        gamePreviewImage ? <img src={gamePreviewImage} alt="Selected" />
                                            :
                                            <>
                                                <FaImage className='lg:h-8 lg:w-8 sm:h-4 sm:w-4 text-white'></FaImage>
                                                <h2 className="mt-1  lg:text-base sm:text-xs tracking-wide  text-gray-200">Panel Image</h2>
                                            </>
                                    }

                                    <input id="dropzone-file" type="file" className="hidden" name="gamePreviewImage" onChange={(e) => onGamePreviewImageInputChange(e)} accept={userRoles.includes("ROLE_ADMIN") ? ".jpg, .jpeg, .png, .gif" : ".jpg, .jpeg, .png"}/>
                                </label>
                            </div>
                        </div>
                        <section className="lg:px-0 px-2">

                            <label htmlFor="HeadlineAct" className="block  lg:text-xl sm:text-sm text-white my-2 mt-7">
                            *Determine the game's image and answer.

                            </label>
                            <div className=" border border-gray-200 rounded-md ">
                                <table className="min-w-full divide-y  divide-gray-700">
                                    <tbody className="bg-zinc-900  ">
                                        {
                                            Array.from({ length: sectionAmount }, (_, i) => (
                                                <tr key={i + "-tr"}>
                                                    <td className="lg:px-4 lg:py-4 sm:px-2 sm:py-2 px-2 py-2 text-sm font-medium text-gray-700 whitespace-nowrap">
                                                        <div className="inline-flex items-center gap-x-3">
                                                            <p className=' text-white lg:text-xl sm:text-xs'>{i + 1} - </p>
                                                            <div>
                                                                <label className="flex flex-col items-center sm:w-24 lg:w-56 w-24  p-5  text-center border-2 border-dashed cursor-pointer bg-gray-900 border-gray-700 rounded-xl">
                                                                    {
                                                                        sectionImages['section-' + i] ? <img src={URL.createObjectURL(sectionImages['section-' + i])} alt="Selected" />
                                                                            :
                                                                            <>
                                                                                <FaImage className='lg:h-8 lg:w-8 sm:h-4 sm:w-4 text-white'></FaImage>
                                                                                <h2 className="mt-1 lg:text-base sm:text-xs tracking-wide  text-gray-200">Game Image</h2>
                                                                            </>
                                                                    }
                                                                    <input name={'section-' + i} type="file" className="hidden" onChange={(e) => onSectionPreviewImageInputChange(e)} accept=".jpg, .jpeg, .png"/>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="lg:px-6 sm:px-1 text-sm font-medium text-gray-700 whitespace-nowrap relative">

                                                        <input
                                                            onChange={(e) => onSectionNamesInputChange(e, i)}
                                                            placeholder="Game Name"
                                                            required
                                                            type="search"
                                                            id="userName"
                                                            name={'section-' + i}
                                                            value={sectionNames['section-' + i]}
                                                            autoComplete="off"
                                                            className=" lg:w-96 sm:w-60 w-60 items-start justify-start py-2 px-2  text-white lg:text-lg sm:text-sm bg-zinc-900 border border-gray-200 rounded-md    focus:ring-blue-300 focus:ring-opacity-40 focus:border-blue-300 focus:outline-none focus:ring" />

                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='text-left lg:px-0 px-2'>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white mt-4">
                                *Please make sure you have correctly identified the sections you have created.
                                </label>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white my-1">
                                *In case of a possible situation, please remember that your game could be reported and removed.
                                </label>
                                <label htmlFor="HeadlineAct" className="block lg:text-lg sm:text-sm font-medium text-white my-1">
                                *Only JPG and PNG formats are supported.
                                </label>
                            </div>
                        </section>
                        <div className='flex justify-center'>
                            <button className=' mt-4 lg:w-full sm:w-96 hover:bg-red-600 duration-200   lg:text-xl sm:text-sm  border border-red-500 bg-red-500 px-3 py-2 rounded-lg text-white' type='submit'>Create Game </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default AdminCreateGame