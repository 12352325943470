import React, { useEffect, useState } from 'react'
import SessionStatus from '../../service/SessionStatus';
import { FiHome } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import axios from 'axios';

function VsEndGame(props) {
    const { gameId, showEndGame, player1Data, player2Data } = props
    const [showModal, setShowModal] = React.useState(false);
    const [session, setSession] = useState(null);

    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const addGamePlayed = () => {
        let guestUserId = null
        if (!subId) {
            guestUserId = localStorage.getItem('guestId')
            if (!guestUserId) {
                guestUserId = Math.floor(Math.random() * 90000000) + 10000000
                localStorage.setItem('guestId', guestUserId)
            }
        } else {
            guestUserId = subId
        }
        
        axios.post('/guestplayed/addVs?gameId=' + gameId + '&guestId=' + guestUserId)
        .then((response) => {
            console.log(response)
        })
    }

    useEffect(() => {
        if (showEndGame) {
            setShowModal(true)
            addGamePlayed()
        }
    }, [showEndGame])

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center flex text-white items-center font-bodyFont align-middle overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-opacity-0 backdrop-filter backdrop-blur-sm z-10 ">
                        {session && (
                            <SessionStatus session={session}></SessionStatus>
                        )}
                        <div className="relative font-bodyFont w-full  max-w-screen-sm rounded-lg mx-2">
                            <div className="bg-slate-900 gap-4 rounded-lg p-5 shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                                <div className="flex justify-center">
                                    <div className="flex justify-center lg:text-3xl md:text-xl sm:text-xl text-xl font-bold  text-blue-500 w-1/2">{player1Data.name}</div>
                                    <div className="flex justify-center lg:text-3xl md:text-xl sm:text-xl text-xl font-bold text-red-500 w-1/2">{player2Data.name}</div>
                                </div>
                                <div className="flex justify-center mt-1">
                                    <p className='flex justify-center lg:text-3xl sm:text-2xl font-bold   text-white w-1/2'>{player1Data.score}</p>
                                    <p className='flex justify-center lg:text-3xl sm:text-2xl font-bold  text-white w-1/2'>{player2Data.score}</p>
                                </div>
                                <hr className='border-1 border-indigo-400'></hr>
                                {
                                    player1Data.score > player2Data.score ? (
                                        <>
                                            <p className='cursor-default  text-5xl italic text-yellow-400 justify-center text-center animate-pulse'>WINNER!</p>
                                            <p className='cursor-default lg:text-2xl text-xl  justify-center text-center animate-bounce duration-500'>{player1Data.name}</p>
                                        </>
                                    ) : player1Data.score < player2Data.score ? (
                                        <>
                                            <p className='cursor-default  text-5xl italic text-yellow-400 justify-center text-center animate-pulse'>WINNER!</p>
                                            <p className='cursor-default lg:text-2xl text-xl  justify-center text-center animate-bounce duration-500'>{player2Data.name}</p>
                                        </>
                                    ) : (
                                        <>
                                            <p className='cursor-default  text-5xl italic text-yellow-400 justify-center text-center animate-pulse'>DRAW!</p>
                                        </>
                                    )
                                }
                                <form className="">
                                    <div className="flex justify-center my-2 w-full rounded-b">
                                        <Link to={"/"}>
                                            <button className="border-white duration-300 p-1 hover:bg-indigo-400 transform inline-block rounded-md lg:text-lg sm:text-base m-2 text-white">
                                                <FiHome className='text-4xl text-white'></FiHome>
                                            </button>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default VsEndGame