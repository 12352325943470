import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ImStatsBars, ImHome3, ImUser, ImEye, ImExit } from "react-icons/im";
import { FaBan, FaGamepad, FaSquarePlus  } from 'react-icons/fa6';
import { useToken } from '../../service/context/TokenProvider';

function SideBar() {
    let navigate = useNavigate();
    const { resetTokenData } = useToken();

    const onLogoutClicked = async () => {
        localStorage.removeItem("signedRefreshToken");
        localStorage.removeItem("signedUserAvatarName");
        localStorage.removeItem("signedUserId");
        navigate("/");
        resetTokenData();
    };

    return (
        <div>
            <aside className="flex flex-col font-bodyFont  w-64 h-screen px-5 py-8 overflow-y-hidden bg-zinc-900  border-r rtl:border-r-0 rtl:border-l ">
                <div className="flex text-2xl  text-center justify-center text-white flex-shrink-0 items-center">
                    <p className="text-white">GaGu</p>
                </div>

                <div className="flex flex-col justify-between flex-1 mt-4">
                    <nav className="flex-1  ">
                        <Link to={"/S5mQ1dP8kR3zN7fH2yL6?key="+localStorage.getItem("key")} >
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <ImHome3 className='text-xl'></ImHome3>
                            <span className="mx-2 text-lg font-medium">Ana Sayfa</span>
                        </button>
                    </Link>
                    <Link to={"/4a6R9sP2Lq8xN7dF3jK1"} >
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <ImStatsBars className='text-xl'></ImStatsBars>
                            <span className="mx-2 text-lg font-medium">İstatistikler</span>
                        </button>
                    </Link>
                    <Link to={"/D7rP2f9JkT6sH1lN8wQ0"}>
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <ImUser className='text-xl'></ImUser>
                            <span className="mx-2 text-lg font-medium">Kullanıcılar</span>
                        </button>
                    </Link>
                    <Link to={"/G5sR2tP8mQ1dN7fH3lK6"}>
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <FaGamepad className='text-xl'></FaGamepad>
                            <span className="mx-2 text-lg font-medium">Oyunlar</span>
                        </button>
                    </Link>

                    <Link to={"/L9zR3bF7pW2kT6yH1mQ0"}>
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <FaSquarePlus   className='text-xl'></FaSquarePlus  >
                            <span className="mx-2 text-lg font-medium">Oyun Oluştur</span>
                        </button>
                    </Link>

                    <Link to={"/M4sN8qR2fT6hJ9pL3kY1"}>
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <FaBan className='text-xl'></FaBan>
                            <span className="mx-2 text-lg font-medium">Banlananlar</span>
                        </button>
                    </Link>

                    <Link to={"/K2yR8sH3fP7mL1qN9tA6"}>
                        <button className="flex items-center mt-2 px-3 w-full py-2 text-gray-300 transition-colors duration-300 transform rounded-lg hover:bg-zinc-800   hover:text-blue-300" >
                            <ImEye className='text-xl'></ImEye>
                            <span className="mx-2 text-lg font-medium">Kontrol Merkezi</span>
                        </button>
                    </Link>
                   
                </nav>
                <div className='flex justify-center'>
                    <Link to={"/"}>
                        <button
                            className="border-blue-400 border-2 duration-300 animate-pulse transform inline-block rounded-md text-md bg-zinc-900 px-4 py-2 my-1  text-blue-400  hover:bg-zinc-900 "
                        >
                            CEPHEYE DÖN
                        </button>
                    </Link>
                </div>
                <div className="mt-6">

                    <div className="p-3 rounded-lg bg-zinc-800">
                        <h2 className="text-sm font-medium  text-white">Yeni Güncellemelerden Haberdar Ol! </h2>
                        <p className="mt-1 text-xs text-gray-400 ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus harum officia eligendi velit.</p>
                        <img className="object-cover w-full h-32 mt-2 rounded-lg" src="https://images.unsplash.com/photo-1658953229664-e8d5ebd039ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&h=1374&q=80" alt="" />
                    </div>

                    <div className="flex items-center justify-end mt-6">
                        <button onClick={onLogoutClicked} className="text-white  transition-colors duration-200 hover:text-red-500 ">
                            <ImExit className='text-2xl'></ImExit>
                        </button>
                    </div>
                </div>
        </div>
            </aside >
        </div >
    )
}

export default SideBar