import React, { useEffect, useState } from 'react'
import image1 from '../../assets/image3.jpg'
import CategoryCardPanel from '../home/card/CategoryCardPanel'
import CategoryAllGamesPanel from './CategoryAllGamesPanel'
import CategoryTrendPanel from './CategoryTrendPanel'
import design5 from '../../assets/design18.png'
import design6 from '../../assets/design15.png'
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom'
import axios from 'axios'

function CategoryPage() {
    const { categoryId } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [image, setImage] = useState(null);
    const [gameType, setGameType] = useState('')

    const getGameType = () => {
        axios.get('/gameType/' + categoryId)
            .then(response => {
                setGameType(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        const loadImage = async () => {
            try {
                const res = await import(`../../assets/category_${categoryId}.gif`); // TODO : gif e donusturecek miyiz ?
                setImage(res.default);
            } catch (error) {
                console.error('Dosya içe aktarımı sırasında bir hata oluştu: ', error);
            }
        };
        loadImage()
    }, [categoryId]);

    let style
    if (categoryId == 3) {
        style = {
            backgroundImage: `url(${image})`,
            backgroundPosition: '10% 40%',
        };
    } else if (categoryId == 4) {
        style = {
            backgroundImage: `url(${image})`,
            backgroundSize: ' 100% 100%',
        };
    } else {
        style = {
            backgroundImage: `url(${image})`,
            backgroundPosition: 'top 40%  right 2%',
            backgroundSize: ' 100% 100%',
        };
    }

    const sectionStyle = {
        backgroundAttachment: 'scroll',
        backgroundPosition: ' bottom  right 2%   ',
        backgroundImage: windowWidth > 767 ? `url(${design6})` : 'none',
        backgroundSize: '10%',
        backgroundRepeat: 'no-repeat',
        // Ensure the container covers the entire viewport height
    };
    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getGameType()
    }, [])
   

    return (
        <div style={sectionStyle}>
            <Helmet>
                <title>Anime Guess - Anime Mixed, Characters, Ecchi & Hentai Page </title>
                <meta name="description" content="Anime Guess - Anime guess the anime, enjoy the gaming experience, and compete to be on the top player list! Find exclusive and exciting anime games in this category. Anime guess from the pictures! The best anime characters and anime hentai games are here!" />
            </Helmet>
            <div
                className="mx-auto   max-w-6xl lg:px-10 md:px-10 px-5 font-bodyFont">

                <img
                    style={image ? style : null}
                    className=" bg-cover w-full lg:h-72  md:h-64 sm:h-52 h-52"
                />
                {categoryId == 2 ? (
                    <span className="flex items-center sm:px-20 lg:px-16 lg:text-3xl sm:text-lg text-lg font-bold justify-center text-white mt-5">
                        <span className="h-px flex-1 animate-border bg-gradient-to-r from-sky-500 via-blue-500 to-cyan-600   bg-[length:400%_400%]"></span>
                        <span className="shrink-0 px-6">{gameType.gameTypeName}</span>
                        <span className="h-px flex-1 animate-border bg-gradient-to-r from-sky-500 via-blue-500 to-cyan-600   bg-[length:400%_400%]"></span>
                    </span>

                ) : categoryId == 3 ? (
                    <span className="flex items-center sm:px-20 lg:px-16 lg:text-3xl sm:text-lg text-lg font-bold justify-center text-white mt-5">
                        <span className="h-px flex-1 animate-border bg-gradient-to-r from-yellow-500 via-orange-500 to-amber-500    bg-[length:400%_400%]"></span>
                        <span className="shrink-0 px-6">{gameType.gameTypeName}</span>
                        <span className="h-px flex-1 animate-border bg-gradient-to-r  from-yellow-500 via-orange-500 to-amber-500   bg-[length:400%_400%]"></span>
                    </span>

                ) : categoryId == 4 ? (
                    <span className="flex items-center sm:px-20 lg:px-16 lg:text-3xl sm:text-lg text-lg font-bold justify-center text-white mt-5">
                        <span className="h-px flex-1 animate-border bg-gradient-to-r from-rose-500 via-pink-500 to-purple-500 bg-[length:400%_400%]"></span>
                        <span className="shrink-0 px-6">{gameType.gameTypeName}</span>
                        <span className="h-px flex-1 animate-border bg-gradient-to-r from-rose-500 via-pink-500 to-purple-500 bg-[length:400%_400%]"></span>
                    </span>
                ) : null}



                <CategoryTrendPanel categoryId={categoryId}></CategoryTrendPanel>
                <CategoryAllGamesPanel categoryId={categoryId}></CategoryAllGamesPanel>

            </div>
        </div>
    )
}

export default CategoryPage