import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function CategoryCard(props) {
    const { id, gameTypeName, gameCount } = props
    const [image, setImage] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const res = await import(`../../../assets/${id}.gif`); // TODO : gif e donusturecek miyiz ?
                setImage(res.default);
                setIsLoaded(true);
            } catch (error) {
                console.error('Dosya içe aktarımı sırasında bir hata oluştu: ', error);
            }
        };
        loadImage()
    }, [id]);


    const style = {
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };


    return (
        <div>
            {image != null && !isLoaded ?
                null
                :
                <Link to={"/categoryPage/" + id}
                >
                    {id == 2 ?
                        <div class="flex font-bodyFont flex-col items-center justify-center w-full max-w-sm mx-auto hover:cursor-pointer duration-200 hover:-translate-y-3">
                            <img
                                alt='Anime Guess - Guess The Anime image'
                                src={image} style={style} className="w-full h-64 bg-cover bg-center bg-gray-300 rounded-lg shadow-md ring-1 ring-indigo-400">
                            </img>
                            <div class=" -mt-10 overflow-hidden rounded-lg shadow-lg lg:w-48 md:w-36 sm:w-28 w-28 bg-slate-900 ring-1 ring-slate-500">
                                <h3 class="py-2 lg:font-bold sm:text-sm text-sm tracking-wide text-center  animate-textHentai  from-sky-500 via-blue-500 to-cyan-600    bg-clip-text bg-gradient-to-r text-transparent ">{gameTypeName}</h3>

                                <div class="flex items-center justify-center px-3 py-1 bg-slate-800 ">
                                    <span class="lg:font-bold sm:text-sm text-sm   animate-textHentai from-sky-500 via-blue-500 to-cyan-600   bg-clip-text bg-gradient-to-r text-transparent ">
                                        {
                                            gameCount > 100 ? gameCount :
                                                gameCount > 10 ? "0" + gameCount :
                                                    "00" + gameCount
                                        }
                                    </span>
                                </div>
                            </div>
                        </div >
                        : id == 3 ?
                            <div class="flex font-bodyFont flex-col items-center justify-center w-full max-w-sm mx-auto hover:cursor-pointer duration-200 hover:-translate-y-3">
                                <img src={image} style={style} className="w-full h-64 bg-cover bg-center bg-gray-300 rounded-lg shadow-md ring-1 ring-indigo-400">
                                </img>
                                <div class=" -mt-10 overflow-hidden rounded-lg shadow-lg lg:w-48 md:w-36 sm:w-28 w-28 bg-slate-900 ring-1 ring-slate-500">
                                    <h3 class="py-2 lg:font-bold sm:text-sm text-sm tracking-wide text-center  animate-textHentai  from-yellow-500 via-orange-500 to-amber-500    bg-clip-text bg-gradient-to-r text-transparent ">{gameTypeName}</h3>

                                    <div class="flex items-center justify-center px-3 py-1 bg-slate-800 ">
                                        <span class="lg:font-bold sm:text-sm text-sm  animate-textHentai  from-yellow-500 via-orange-500 to-amber-500    bg-clip-text bg-gradient-to-r text-transparent ">
                                            {
                                                gameCount > 100 ? gameCount :
                                                    gameCount > 10 ? "0" + gameCount :
                                                        "00" + gameCount
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div >
                            : id == 4 ?
                                <div class="flex font-bodyFont flex-col items-center justify-center w-full max-w-sm mx-auto hover:cursor-pointer duration-200 hover:-translate-y-3">
                                    <img src={image} style={style} className="w-full h-64 bg-cover bg-center bg-gray-300 rounded-lg shadow-md ring-1 ring-indigo-400">
                                    </img>
                                    <div class=" -mt-10 overflow-hidden rounded-lg shadow-lg lg:w-48 md:w-36 sm:w-28 w-28 bg-slate-900 ring-1 ring-slate-500">
                                        <h3 class="py-2 lg:font-bold sm:text-sm text-sm tracking-wide text-center  animate-textHentai from-rose-500 via-pink-500 to-purple-500 bg-clip-text bg-gradient-to-r text-transparent ">{gameTypeName}</h3>

                                        <div class="flex items-center justify-center px-3 py-1 bg-slate-800 ">
                                            <span class="lg:font-bold sm:text-sm text-sm   animate-textHentai from-rose-500 via-pink-500 to-purple-500 bg-clip-text bg-gradient-to-r text-transparent">
                                                {
                                                    gameCount > 100 ? gameCount :
                                                        gameCount > 10 ? "0" + gameCount :
                                                            "00" + gameCount
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div >
                                : null

                    }

                </Link>

            }
        </div >
    )
}

export default CategoryCard