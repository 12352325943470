import React from 'react'

function LoadingCategoryTrendCard() {
    return (
        <div className="block shadow-lg  hover:scale-105  cursor-pointer  duration-200 rounded-lg">
            <div className='w-full h-64 rounded-t-md animate-pulse bg-slate-900'></div>
            <div className=' bg-slate-800 rounded-b-md'>
                <div className="py-4 flex items-center justify-center">
                    <div className="h-4 w-1/3 mb-2 bg-slate-900 rounded animate-pulse justify-center"></div>
                </div>

            </div>
        </div>


    )
}

export default LoadingCategoryTrendCard