import React from 'react'
import LoadingCategoryCard from './LoadingCategoryCard'

function LoadingCategoryCardPanel() {
    return (
        <div className=" grid lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-4 mt-10">
            <LoadingCategoryCard></LoadingCategoryCard>
            <LoadingCategoryCard></LoadingCategoryCard>
            <LoadingCategoryCard></LoadingCategoryCard>
        </div>
    )
}

export default LoadingCategoryCardPanel