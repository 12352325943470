import React, { useEffect, useRef, useState } from 'react'
import { FiEye, FiEyeOff, FiClipboard, FiInfo } from "react-icons/fi";
import UserAvatarPanel from '../card/UserAvatarPanel.js';
import AnimeWatched from '../card/AnimeWatched.js';
import AnimeWishList from '../card/AnimeWishList';
import MangaReaded from '../card/MangaReaded';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useToken } from '../../../service/context/TokenProvider.js';
import { formatDate } from '../../../service/FormatDate.js';
import UserUpdatePanel from '../card/UserUpdatePanel.js';
import mimage from '../../../assets/design1.png'
import { Helmet } from 'react-helmet';

function User() {
    const { userId } = useParams();
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const clipboardRef = useRef(null);
    const [showNotification, setShowNotification] = useState(false);
    const [userInformation, setUserInformation] = useState()
    const [userAnimeListWatchedLength, setUserAnimeListWatchedLength] = useState(null)
    const [userTotalScore, setUserTotalScore] = useState(null)
    const [userLeaderboardCount, setUserLeaderboardCount] = useState(null)

    const handleClipboardClick = () => {
        const textToCopy = window.location.href;

        if (textToCopy) {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setShowNotification(true);
                    setTimeout(() => {
                        setShowNotification(false);
                    }, 2000);
                }
            } catch (err) {
                console.error('Kopyalama işlemi sırasında bir hata oluştu:', err);
            }

            document.body.removeChild(textArea);
        }
    };
    const [activeTab, setActiveTab] = useState('AnimeWishList'); // Default active tab

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const fillUserInformation = () => {
        axios.get('/user/userPageInformation/' + userId)
            .then((response) => {
                setUserInformation(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fillWatchAnimeListCount = () => {
        axios.get('/animeList/countWatched', {
            params: {
                userId: userId
            }
        })
            .then((response) => {
                setUserAnimeListWatchedLength(response.data)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const showAnimeList = (show) => {
        axios.post('/user/showAnimeList', {
            userId: subId,
            showAnimeList: show,
            token: accessToken
        })
            .then((response) => {
                setUserInformation(userInformation => ({ ...userInformation, user: { ...userInformation.user, showAnimeList: show } }))
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const fillUserTotalScore = () => {
        axios.get('/rank/userTotalRank/' + userId)
            .then((response) => {
                setUserTotalScore(response.data)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const fillUserLeaderBoardCount = () => {
        axios.get('/rank/userLeaderboard/' + userId)
            .then((response) => {
                setUserLeaderboardCount(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const sectionStyle = {
        backgroundAttachment: 'scroll',
        backgroundPosition: 'bottom right',
        backgroundImage: windowWidth > 767 ? `url(${mimage})` : 'none',
        backgroundSize: '15%',
        backgroundRepeat: 'no-repeat',
        // Ensure the container covers the entire viewport height
    };


    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fillUserInformation()
        fillWatchAnimeListCount()
        fillUserTotalScore()
        fillUserLeaderBoardCount()
    }, [userId])

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);

    return (
        <section
            style={sectionStyle}
            className="font-bodyFont">
            <Helmet>
            <title>Anime Guess - User Profile</title>
                <meta name="description" content="Anime Guess - Enjoy the gaming experience, and compete to be on the top player list! Anime guess from the pictures! The best anime characters and anime hentai games are here!" />
            </Helmet>
            {showNotification && (
                <div role="alert" className=" absolute  m-2 z-50">
                    <div className="rounded-xl border  bg-green-400 p-4">
                        <div className="flex items-start gap-4">
                            <span >
                                <FiInfo className="text-white w-10 h-10" />
                            </span>
                            <div className="flex-1 text-white">
                                <strong className="block font-medium">Notification!</strong>
                                <p className="mt-1 text-sm">Link copied successfully.</p>
                            </div>

                            <button
                                className="text-gray-500 transition hover:text-gray-600"
                                onClick={() => setShowNotification(false)}
                            >
                                <span className="sr-only">Dismiss popup</span>

                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="mx-auto max-w-6xl lg:px-10 px-5">

                <div className="mt-10 flex  items-center">

                    <div className="relative text-center ">
                        {subId === userId ? (<div>
                            {
                                userInformation ?
                                    <div className="relative">
                                        <img
                                            className="inline-block lg:h-44 lg:w-44 h-24 w-24 rounded-full ring-2 ring-indigo-400"
                                            src={`data:image/jpeg;base64,${userInformation.avatarImage}`}
                                            alt="User Avatar"
                                        />
                                        <div className=' lg:text-base md:text-base sm:text-sm text-sm text-gray-400 hover:text-gray-500 duration-200 mt-1'>
                                            {subId === userId ? <UserAvatarPanel></UserAvatarPanel> : ""}
                                        </div>
                                    </div>
                                    :
                                    <div className="mx-1 border-t-transparent border-solid animate-spin rounded-full border-indigo-400 border-4 lg:h-44 lg:w-44 h-24 w-24">
                                    </div>
                            }
                        </div>) : (<div>
                            {
                                userInformation ?
                                    <img
                                        className="inline-block lg:h-44 lg:w-44 h-24 w-24 rounded-full ring-2 ring-indigo-400"
                                        src={`data:image/jpeg;base64,${userInformation.avatarImage}`}
                                    />
                                    :
                                    <div className="mx-1 border-t-transparent border-solid animate-spin rounded-full border-indigo-400 border-4 lg:h-44 lg:w-44 h-24 w-24">
                                    </div>
                            }

                        </div>)}
                        {
                            userTotalScore > 100000 ?
                                <div className=' ml-2 mt-1 lg:text-xl sm:text-sm text-sm'>
                                    <p className=" bg-gradient-to-r  duration-200   animate-text  from-teal-500 via-purple-500  to-orange-500 bg-clip-text text-transparent font-extrabold">
                                        GOD OF ANIME
                                    </p>
                                </div>
                                :
                                userTotalScore > 75000 ?
                                    <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm'>
                                        <p className="bg-clip-text bg-gradient-to-r text-transparent from-blue-500 via-purple-500 to-pink-500  font-extrabold">
                                            MASTER OF ANIME
                                        </p>
                                    </div>
                                    :
                                    userTotalScore > 65000 ?
                                        <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm'>
                                            <p className="bg-clip-text bg-gradient-to-r text-transparent from-rose-200 to-red-700 font-extrabold">
                                                Anime Myth
                                            </p>
                                        </div>
                                        :
                                        userTotalScore > 50000 ?
                                            <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm'>
                                                <p className="bg-clip-text bg-gradient-to-r text-transparent from-yellow-200 to-orange-700   font-extrabold">
                                                    Anime Prodigy
                                                </p>
                                            </div>
                                            :
                                            userTotalScore > 40000 ?
                                                <div className=' ml-2 mt-1 lg:text-xl sm:text-sm text-sm'>
                                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-sky-200 to-blue-700 font-extrabold">
                                                        Anime Champion
                                                    </p>
                                                </div>
                                                :
                                                userTotalScore > 30000 ?
                                                    <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm'>
                                                        <p className="bg-clip-text bg-gradient-to-r text-transparent from-teal-200 to-green-700 font-extrabold">
                                                            Anime Virtuoso
                                                        </p>
                                                    </div>
                                                    :
                                                    userTotalScore > 20000 ?
                                                        <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm '>
                                                            <p className="text-rose-700  font-extrabold">
                                                                Anime Savant
                                                            </p>
                                                        </div>
                                                        :
                                                        userTotalScore > 10000 ?
                                                            <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm '>
                                                                <p className=" text-yellow-700  font-extrabold">
                                                                    Anime Connoisseur
                                                                </p>
                                                            </div>
                                                            :
                                                            userTotalScore > 5000 ?
                                                                <div className=' ml-2 mt-1 lg:text-xl sm:text-sm text-sm'>
                                                                    <p className=" text-sky-700   font-extrabold">
                                                                        Anime Devotee
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div className='ml-2  mt-1 lg:text-xl sm:text-sm text-sm '>
                                                                    <p className=" text-teal-700  font-extrabold">
                                                                        Anime Explorer
                                                                    </p>
                                                                </div>
                        }

                    </div>
                    <div className="-mt-10 ml-3">
                        <div className="text-white lg:text-3xl sm:text-lg text-lg">{userInformation ? userInformation.user.userName : ""}</div>
                        <p className="text-gray-600 lg:text-lg sm:text-sm text-sm ">
                            {userInformation ? formatDate(userInformation.user.createDate) : ""}
                        </p>
                        <div className='flex'>
                            <span
                                ref={clipboardRef}
                                onClick={handleClipboardClick}
                            >
                                <FiClipboard className=" lg:w-6 lg:h-6 sm:h-4 sm:w-4 font-semibol    cursor-pointer text-green-500 hover:text-green-600 duration-200"
                                />
                            </span>
                            {subId === userId ? <UserUpdatePanel></UserUpdatePanel> : ""}
                        </div>
                    </div>
                </div>
                <div className='mb-1'>
                    <p className="flex   justify-end text-white lg:text-xl  sm:text-base text-sm ">
                        {userLeaderboardCount ? "Top " + userLeaderboardCount + " on the list!" : ""}
                    </p>

                    <hr className=" mt-1 border-gray-700 " />

                </div>

                <div className='flex justify-between gap-4  rounded-lg   '>
                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className='lg:text-5xl md:text-xl text-base cursor-default duration-200 text-white font-extrabold  m-2'>
                            {userAnimeListWatchedLength != null ? userAnimeListWatchedLength :
                                <div class='flex justify-center items-center text-white border-indigo-400 rounded-lg'>
                                    <div class="border-t-transparent mt-4 border-solid animate-spin rounded-full border-indigo-400 border-8 h-7 w-7">
                                    </div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>Watched</p>
                    </div>

                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className={`lg:text-5xl md:text-xl text-lg cursor-default  font-extrabold bg-clip-text m-2
                                    ${userLeaderboardCount == 1 ? 'bg-gradient-to-r animate-text text-transparent from-teal-500 via-purple-500 to-orange-500' :
                                userLeaderboardCount == 2 ? 'bg-gradient-to-r text-transparent   from-blue-500 via-purple-500 to-pink-500' :
                                    userLeaderboardCount == 3 ? 'bg-gradient-to-r text-transparent from-rose-200 to-red-700' :
                                        userLeaderboardCount == 4 ? 'bg-gradient-to-r text-transparent from-yellow-200 to-orange-700' :
                                            userLeaderboardCount == 5 ? 'bg-gradient-to-r text-transparent from-sky-200 to-blue-700' :
                                                userLeaderboardCount == 6 ? 'bg-gradient-to-r text-transparent from-teal-200 to-green-700' :
                                                    userLeaderboardCount == 7 ? '  text-rose-700' :
                                                        userLeaderboardCount == 8 ? '  text-yellow-700 ' :
                                                            userLeaderboardCount == 9 ? '  text-sky-700' :
                                                                userLeaderboardCount == 10 ? '  text-teal-700' : ' text-white'
                            }`}>
                            {userTotalScore != null ? userTotalScore :
                                <div class='flex justify-center items-center rounded-lg border-indigo-400'>
                                    <div class="border-t-transparent mt-4 border-solid animate-spin rounded-full border-8 h-7 w-7 border-indigo-400">
                                    </div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>Score</p>
                    </div>
                    <div className='flex flex-col items-center my-5' style={{ width: '250px' }}>
                        <p className='lg:text-5xl md:text-xl text-base cursor-default duration-200 text-white  m-2'>
                            {userInformation ? userInformation.playedGameCount :
                                <div class='flex justify-center items-center text-white border-indigo-400 rounded-lg'>
                                    <div class="border-t-transparent mt-4 border-solid animate-spin rounded-full border-indigo-400 border-8 h-7 w-7">
                                    </div>
                                </div>
                            }
                        </p>
                        <p className='text-gray-400 lg:text-base md:text-base text-xs m-2'>Played</p>
                    </div>
                </div>

                {
                    userId === subId ?
                        <>
                            <div className=' flex justify-center mb-2'>
                                {
                                    userInformation ? userInformation.user.showAnimeList ?
                                        <FiEye onClick={() => { showAnimeList(false) }} className=' lg:w-16 lg:h-16 sm:h-7 sm:w-7 h-7 w-7   font-semibol  cursor-pointer text-rose-500 hover:text-rose-600 duration-200'></FiEye>
                                        :
                                        <FiEyeOff onClick={() => { showAnimeList(true) }} className=' lg:w-16 lg:h-16 sm:h-7 sm:w-7 h-7 w-7   font-semibol  cursor-pointer text-rose-500 hover:text-rose-600 duration-200'></FiEyeOff>
                                        :
                                        <div className=' flex justify-center mt-10 mb-2'>
                                            <FiEye onClick={() => { showAnimeList(false) }} className=' lg:w-16 lg:h-16 sm:h-7 sm:w-7 h-7 w-7   font-semibol  cursor-pointer text-rose-500 hover:text-rose-600 duration-200'></FiEye>
                                        </div>
                                }
                            </div>
                            <div>
                                <div className='flex space-x-4 mb-4'>
                                    <div
                                        className={`cursor-pointer lg:text-lg text-xs ${activeTab === 'AnimeWishList' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                        onClick={() => handleTabClick('AnimeWishList')}
                                    >
                                        Plan To Watch
                                    </div>
                                    <div
                                        className={`cursor-pointer lg:text-lg text-xs ${activeTab === 'AnimeWatched' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                        onClick={() => handleTabClick('AnimeWatched')}
                                    >
                                        Anime Watched
                                    </div>
                                    <div
                                        className={`cursor-pointer lg:text-lg text-xs ${activeTab === 'MangaReaded' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                        onClick={() => handleTabClick('MangaReaded')}
                                    >
                                        Manga Readed
                                    </div>
                                </div>
                                {activeTab === 'AnimeWishList' && <AnimeWishList userId={userId} />}
                                {activeTab === 'AnimeWatched' && <AnimeWatched userId={userId} />}
                                {activeTab === 'MangaReaded' && <MangaReaded userId={userId} />}
                            </div>
                        </>
                        :
                        userInformation ? userInformation.user.showAnimeList ?
                            <>
                                <div>
                                    <div className='flex space-x-4 mb-4'>
                                        <div
                                            className={`cursor-pointer ${activeTab === 'AnimeWishList' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                            onClick={() => handleTabClick('AnimeWishList')}
                                        >
                                            Plan To Watch
                                        </div>
                                        <div
                                            className={`cursor-pointer ${activeTab === 'AnimeWatched' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                            onClick={() => handleTabClick('AnimeWatched')}
                                        >
                                            Anime Watched
                                        </div>
                                        <div
                                            className={`cursor-pointer ${activeTab === 'MangaReaded' ? 'text-indigo-400 border-b-2 border-indigo-400' : 'text-white hover:text-indigo-400'}`}
                                            onClick={() => handleTabClick('MangaReaded')}
                                        >
                                            Manga Readed
                                        </div>
                                    </div>
                                    {activeTab === 'AnimeWishList' && <AnimeWishList userId={userId} />}
                                    {activeTab === 'AnimeWatched' && <AnimeWatched userId={userId} />}
                                    {activeTab === 'MangaReaded' && <MangaReaded userId={userId} />}
                                </div>
                            </>
                            :
                            null
                            :
                            null
                }
            </div>
        </section>
    )
}

export default User