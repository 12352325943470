import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getAvatarImageFromLocalStorage } from '../../../service/Avatar';
import { Link } from 'react-router-dom';

function TopListTable() {
    const [topList, setTopList] = useState([])
    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const getTopList = async () => {
        await axios.get('/rank/leaderboard/global')
            .then((response) => {
                setTopList(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getTopList()
    }, [])

    return (
        <div className=' rounded-md animate-border bg-gradient-to-r from-red-500 via-purple-500 to-blue-500 bg-[length:400%_400%] p-0.5'>
            <div class="overflow-x-auto rounded-md  font-extrabold bg-gray-900">
                <table class="min-w-full text-white text-sm">
                    <thead className="border-b font-medium border-gray-500 text-white bg-gray-800 ">
                        <tr>
                            <th scope="col" className="lg:px-14 lg:py-4 sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Rank</th>
                            <th scope="col" className="lg:px-14 lg:py-4 sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Nick</th>
                            <th scope="col" className="lg:px-14 lg:py-4 sm:px-1 sm:py-2 px-1 py-2 lg:text-xl sm:text-xs">Score</th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            topList.length != 0 ?
                                topList.map((item, index) => (
                                    <tr className={`${index == 0 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-4xl sm:text-lg animate-text  from-teal-500 via-purple-500 to-orange-500 ' :
                                        index == 1 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-3xl sm:text-base font-extrabold   from-blue-500 via-purple-500 to-pink-500  ' :
                                            index == 2 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-2xl sm:text-sm  font-extrabold  from-rose-400 to-red-700' :
                                                index == 3 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-xl sm:text-xs font-extrabold from-yellow-400 to-orange-700' :
                                                    index == 4 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-xl sm:text-xs font-extrabold from-sky-400 to-blue-700' :
                                                        index == 5 ? 'bg-clip-text bg-gradient-to-r text-transparent lg:text-xl sm:text-xs font-extrabold from-teal-400 to-green-700' :
                                                            index == 6 ? 'lg:text-xl sm:text-xs font-extrabold text-rose-700' :
                                                                index == 7 ? 'lg:text-xl sm:text-xs font-extrabold text-yellow-700 ' :
                                                                    index == 8 ? 'lg:text-xl sm:text-xs font-extrabold text-sky-700' :
                                                                        index == 9 ? 'lg:text-xl sm:text-xs font-extrabold text-teal-700' :
                                                                            'lg:text-xl sm:text-xs font-extrabold text-white'
                                        } border-b border-gray-500 `}>
                                        <div className='flex justify-center'>
                                            <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10 px-8 py-2  lg:text-xl sm:text-xs flex justify-start">{index + 1}</td>
                                        </div>
                                        <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10  px-8 py-2">
                                            <div className='flex items-center  justify-start'>
                                                <img
                                                    className="lg:h-8 lg:w-8 sm:w-6 sm:h-6 h-6 w-6 rounded-full"
                                                    src={`data:image/jpeg;base64,${getAvatarImageFromLocalStorage(item.userAvatarName)}`}
                                                />
                                                <Link className='  hover:text-indigo-300 duration-200' to={"/userProfile/" + item.userId}>
                                                    <div className='ms-2 '>
                                                        {item.userName}
                                                    </div>
                                                </Link>
                                            </div>
                                        </td>

                                        <td className="whitespace-nowrap lg:px-14 md:px-14 sm:px-10  px-8 py-2 justify-start flex">{item.score}</td>
                                    </tr>
                                ))
                                :
                                null
                        }
                    </tbody>
                </table>
                {
                    !isLoaded ?
                        <div class='flex justify-center items-center text-white border-indigo-400 rounded-lg'>
                            <div class="border-t-transparent mt-4 mb-4 border-solid animate-spin rounded-full border-indigo-400 border-8 h-7 w-7">
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>)
}

export default TopListTable