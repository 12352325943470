import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaImage } from 'react-icons/fa6';
import { useToken } from '../../service/context/TokenProvider';

export default function AddAvatar() {
    const [showModal, setShowModal] = React.useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [error, setError] = useState(false);
    const [avatarImage, setAvatarImage] = useState();
    const [avatar, setAvatar] = useState({
        avatarName: "",
        avatarImage: "",
    })

    const onAvatartInputChange = (e) => {
        setAvatar({ ...avatar, [e.target.name]: e.target.value });
    };

    const onAvatarPreviewImageInputChange = (e) => {
        const file = e.target.files[0];
        setAvatar({ ...avatar, [e.target.name]: file });
        if (file) {
            setAvatarImage(URL.createObjectURL(file));
        }
    };

    const closeAlert = () => {
        setError(null);
    };

    const addAvatar = async (e) => {
        e.preventDefault();

        // oyun fotosunu kontrol et
        if (avatar.avatarImage == "") {
            setError("Avatarın resmini seçin.");
            return
        }

        // oyunun katagorisini kontrol et
        if (avatar.avatarName == "") {
            setError("Avatarın ismini girin.");
            return
        }

        const formData = new FormData();

        Object.keys(avatar).forEach((key) => {
            formData.append(key, avatar[key]);
        });

        await axios.post('/avatar/add', formData, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(res => {
                setShowModal(false)
                window.location.reload();
            })
            .catch(err => {
                setError("Oyun oluşturulurken bir hata oluştu")
            })
    }


    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                }}
                type="button">
                <button
                    className="bg-zinc-700 hover:bg-zinc-600 duration-200 text-white font-medium py-2 px-4 rounded-lg mt-10"
                >
                    Avatar Ekle
                </button>
            </a>

            {showModal ? (
                <>

                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        {error && (
                            <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                                <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                                <div
                                    role="alert"
                                    className="rounded border-s-4 border-red-500 bg-zinc-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                                >
                                    <button
                                        onClick={closeAlert}
                                        className="absolute top-2 right-2 text-white focus:outline-none"
                                    >
                                        <span className="sr-only">Kapat</span>
                                        <svg
                                            className="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <strong className="block text-6xl mb-5 font-medium text-white">
                                        :(
                                    </strong>
                                    <p className="mt-2 text-2xl text-white">
                                        {error}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-2xl">

                            {/*content*/}
                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}

                                <div className="relative block ml-4">
                                    {/*body*/}
                                    <div className="relative">
                                        <div className="font-bodyFont">
                                            <button
                                                onClick={() => setShowModal(false)}
                                                className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-6 h-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="p-4">
                                            <h2 className="text-2xl font-semibold"></h2>
                                            <form
                                                onSubmit={(e) => addAvatar(e)}
                                            >
                                                <div className="mt-4">
                                                    <div className='flex justify-center items-center flex-col'>
                                                        <label for="dropzone-file" className="flex flex-col justify-center items-center sm:w-40  lg:w-56  p-5 mt-2 text-center border-2  border-dashed cursor-pointer bg-gray-900 border-gray-700 rounded-xl">
                                                            {
                                                                avatarImage ? <img src={avatarImage} alt="Selected" />
                                                                    :
                                                                    <>
                                                                        <FaImage className='h-8 w-8 text-white'></FaImage>
                                                                        <h2 className="mt-1 font-medium tracking-wide text-gray-200 ">Avatar Resmi</h2>
                                                                    </>
                                                            }
                                                            <input id="dropzone-file" type="file" className="hidden" name="avatarImage" onChange={(e) => onAvatarPreviewImageInputChange(e)} />
                                                        </label>
                                                    </div>
                                                    <label className="text-xl">Avatar Adı:</label>
                                                    <input
                                                        onChange={(e) => onAvatartInputChange(e)}
                                                        type="text"
                                                        id="avatarName"
                                                        name="avatarName"
                                                        required
                                                        className="block w-full px-4 mt-2 text-xl bg-zinc-900 py-2 border rounded focus:outline-none focus:border-red-500"
                                                    />
                                                </div>

                                                <div className="flex justify-end mt-6">
                                                    <button

                                                        type='submit'
                                                        className="px-4 py-2 bg-zinc-900  text-white rounded hover:bg-zinc-700 duration-200 focus:outline-none"
                                                    >
                                                        Ekle
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

}