import React from 'react'
import { useNavigate } from 'react-router-dom';

function SessionStatus(props) {
    const { session, multiplayer } = props
    const [showModal, setShowModal] = React.useState(true)
    let navigate = useNavigate();

    const closeQuitlert = () => {

        localStorage.removeItem("signedRefreshToken");
        localStorage.removeItem("signedUserAvatarName");
        localStorage.removeItem("signedUserId");

        setShowModal(false);
        if (multiplayer) {
            navigate("/");
        } else {
            navigate("/login");
        }

    };


    return (
        <div>
            {showModal ? (
                <div className="fixed inset-0 flex items-center justify-center z-50 px-4  backdrop-blur-sm">
                    <div className="fixed inset-0 bg-slate-900 opacity-40 z-40"></div>
                    <div
                        className="rounded border-s-4 border-indigo-500 bg-slate-950 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {session}
                        </p>
                        <div className='mt-2 flex justify-end'>
                            <button onClick={closeQuitlert} className="hover:bg-indigo-600 duration-200  text-xl   bg-indigo-500 px-3 py-2 rounded-lg text-white">OK</button>
                        </div>
                    </div>
                </div>
            ) : null}

        </div>
    )
}

export default SessionStatus