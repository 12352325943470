import axios from 'axios';
import React, { useState } from 'react'
import { useToken } from '../../service/context/TokenProvider';


export default function AdminViewParam(props) {
    const [showModal, setShowModal] = React.useState(false);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [isLoaded, setIsLoadedParam] = useState(false);
    const { id, children, paramName, paramValue } = props;
    const [error, setError] = useState(false);
    const [paramById, setParamById] = useState([]);

    const [param, setParam] = useState({
        paramName: "",
        paramValue: "",
    });



    const getParamByName = async () => {
        await axios
            .get("/param/" + paramName, {
                headers: {
                    Authorization: accessToken
                }
            })
            .then((response) => {
                setParamById(response.data)
                setParam(response.data)
                setIsLoadedParam(true);
            })
            .catch((error) => {
                setIsLoadedParam(true);
                setError(error)
            })
    }

    const onSubmit = async (e) => {
        e.preventDefault();//submit yaparken sayfayı yenilemyior
        await axios.put(`/param/paramUpdate/${id}`, param, {
            headers: {
                Authorization: accessToken
            }
        })
            .then(function (response) {
                window.location.reload();
            })

    }
    const onInputChange = (e) => {
        setParam({ ...param, [e.target.name]: e.target.value })
    }

    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true)
                    getParamByName()
                }}
                type="button">
                {children}
            </a>

            {showModal ? (
                <>
                    <div className=" justify-center text-white  items-center font-bodyFont  overflow-x-hidden overflow-y-auto fixed  inset-0 z-50  outline-none focus:outline-none backdrop-blur-sm">
                        <div className="relative font-bodyFont w-auto my-6 mx-auto max-w-2xl">

                            {/*content*/}
                            <div className=" bg-zinc-800  border-gray-600  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                {isLoaded ? (
                                    <div className="relative block ml-4">
                                        {/*body*/}
                                        <div className="relative">
                                            <div className="font-bodyFont">
                                                <button
                                                    className="absolute top-0 right-0 p-2 text-white hover:text-gray-400"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-6 h-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="p-4">
                                                <h2 className="text-2xl font-semibold">id: {paramById.id}</h2>
                                                <form
                                                    onSubmit={(e) => onSubmit(e)}

                                                >
                                                    <div className="mt-4">
                                                        <label className="text-xl">paramName:</label>
                                                        <input
                                                            onChange={(e) => onInputChange(e)}
                                                            defaultValue={paramById.paramName}
                                                            type="text"
                                                            id="paramName"
                                                            name="paramName"
                                                            className="block w-full px-4 mt-2 text-xl bg-zinc-900 py-2 border rounded focus:outline-none focus:border-red-500"
                                                        />


                                                    </div>
                                                    <div className="mt-4">
                                                        <label className="text-xl">paramValue:</label>
                                                        <input
                                                            onChange={(e) => onInputChange(e)}
                                                            defaultValue={paramById.paramValue}
                                                            type="text"
                                                            id="paramValue"
                                                            name="paramValue"
                                                            className="block w-full px-4 mt-2 text-xl bg-zinc-900 py-2 border rounded focus:outline-none focus:border-red-500"
                                                        />
                                                    </div>
                                                    <div className="flex justify-end mt-6">
                                                        <button
                                                            type='submit'
                                                            className="px-4 py-2 bg-zinc-900  text-white rounded hover:bg-zinc-700 duration-200 focus:outline-none"
                                                        >
                                                            Kaydet
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div className="fixed inset-0 flex items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                                        <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-24"></div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );

}