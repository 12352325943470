import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaDiscord, FaInstagram, FaLinkedin, FaSquareTwitter } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';

function AdminPanel() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const [userByCount, setUserByCount] = useState([]);
    const [playedByCount, setPlayedByCount] = useState([]);
    const [guestPlayedByCount, setGuestPlayedByCount] = useState([]);
    const [guestPlayedVsByCount, setGuestPlayedVsByCount] = useState([]);
    const [guestUserCount, setGuestUserCount] = useState([]);
    const [gameByCount, setGameByCount] = useState([]);
    const [databaseRunning, setDatabaseRunning] = useState(null);
    const [isBackendRunning, setIsBackendRunning] = useState(false);
    const [isGameSearchApiRunning, setIsGameSearchApiRunning] = useState(false);
    const [checkKey, setCheckKey] = useState(false)


    const getBackendStatus = () => {
        axios.get("/user/ping")
            .then(function (response) {
                if (response.status === 200) {
                    setIsBackendRunning(true);
                } else {
                    setIsBackendRunning(false);
                }
            })
            .catch(function (error) {
                console.error("Ping isteği hatası:", error);
                setIsBackendRunning(false);
            });
    }

    const getGameSearcApiStatus = () => {
        axios.get("https://api.rawg.io/api/")
            .then(function (response) {
                if (response.status === 200) {
                    setIsGameSearchApiRunning(true);
                } else {
                    setIsGameSearchApiRunning(false);
                }
            })
            .catch(function (error) {
                console.error("Ping isteği hatası:", error);
                setIsGameSearchApiRunning(false);
            });
    }

    const getDatabaseStatus = () => {
        axios
            .get('/param/DbPing', {
                headers: {
                    Authorization: accessToken
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    setDatabaseRunning(true);
                } else {
                    setDatabaseRunning(false);
                }
            })
            .catch(function (error) {
                console.error("Ping isteği hatası:", error);
                setDatabaseRunning(false);
            });
    }

    const getCountUsersToday = () => {
        axios
            .get("/user/getCountUsersToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setUserByCount(result);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    const getCountGamesToday = () => {
        axios
            .get("/game/getCountGamesToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setGameByCount(result);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    const getCountPlayedToday = () => {
        axios
            .get("/played/countPlayedToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setPlayedByCount(result);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    const getCountGuestPlayedToday = () => {
        axios
            .get("/guestplayed/countPlayedToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setGuestPlayedByCount(result);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    const getCountGuestRegisteredToday = () => {
        axios
            .get("/guestplayed/getRegisteredToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setGuestUserCount(result);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    const getCountGuestPlayedVsToday = () => {
        axios
            .get("/guestplayed/countPlayedVsToday")
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setGuestPlayedVsByCount(result / 2);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post("/param/getPanelLink/send?paramValue=" + key, {}, {
                    headers: {
                        Authorization: accessToken,
                    },
                });
                if (response.status === 200) {
                    setCheckKey(true);
                    localStorage.setItem('key', key);
                    getCountUsersToday();
                    getCountPlayedToday();
                    getCountGuestPlayedToday()
                    getCountGuestPlayedVsToday()
                    getCountGuestRegisteredToday()
                    getCountGamesToday();
                    getDatabaseStatus();
                    getBackendStatus()
                    getGameSearcApiStatus()
                } else if (response.status === 404) {
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setCheckKey(false);
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
                console.error("Bir hata oluştu: ", error);
            }
        }
        fetchData();
    }, [key]);



    const PanelCard = [
        { title: " Kayıtlı Kullanıcı Sayısı", explanation: userByCount, to: "/" },
        { title: " Eklenen Oyun Sayısı", explanation: gameByCount, to: "/" },
        { title: " Oynanan Oyun Sayısı", explanation: playedByCount, to: "/" },
        { title: " Misafir Kullanıcı Sayısı", explanation: guestUserCount, to: "/" },
        { title: " Gelen Şikayet Sayısı", explanation: "0", to: "/" },
        { title: " Misafir Oynanan Oyun Sayısı", explanation: `${guestPlayedByCount} - ${guestPlayedVsByCount}`, to: "/" },
    ]

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">An unexpected error occurred...</div>
                <div className="text-2xl font-bold text-red-500">Please try again later.</div>
            </div>
        )
    } else if (!checkKey) {
        return (
            <div className='fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black  z-50'>
                <div className='text-white border-zinc-600 rounded-lg'>
                    <div className='flex justify-center items-center mt-20'>
                        <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
                    </div>
                </div>
            </div>
        );
    } else
        return (
            <div className="  min-h-screen text-white font-bodyFont  bg-gradient-to-r from-dark-500 via-dark-700 to-dark-800 from-gray-700 :via-gray-800 to-gray-900 ">
                <section className="bg-zinc-900 text-white">
                    <div
                        className="mx-auto min-h-screen max-w-screen-xl   sm:py-6 sm:px-6 lg:py-10 lg:px-8"
                    >

                        <div className="mx-auto  text-center">

                            <h2 className=" lg:text-4xl font-bold sm:text-2xl"> ADMİN PANELİ</h2>
                            <p className='text-3xl justify-center text-center mt-8 mb-4 '>Durum</p>
                            <div className=" grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                                <p className="w-full text-gray-300 border-2 p-4 items-start rounded-md">
                                    Database: <span className={databaseRunning ? 'text-green-500' : 'text-red-500'}>
                                        {databaseRunning ? 'True' : 'False'}
                                    </span>
                                </p>
                                <p className="  w-full text-gray-300 border-2 p-4 items-start rounded-md">
                                    Game Search Api: <span className={isGameSearchApiRunning ? 'text-green-500' : 'text-red-500'}>
                                        {isGameSearchApiRunning ? 'True' : 'False'}
                                    </span>
                                </p>
                                <p className="w-full text-gray-300 border-2 p-4 items-start rounded-md">
                                    Backend: <span className={isBackendRunning ? 'text-green-500' : 'text-red-500'}>
                                        {isBackendRunning ? 'True' : 'False'}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <p className='text-3xl justify-center text-center mt-8 mb-4'>BUGÜN</p>
                        <div className=" grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">

                            {PanelCard.map((key, index) => (
                                <a
                                    key={index}
                                    className="block  text-center rounded-xl border  bg-zinc-800 border-gray-800 p-8 transition "
                                >
                                    <h2 className=" text-xl  font-bold text-white">{key.title}</h2>
                                    <p className="mt-5 text-5xl  text-blue-500">
                                        {key.explanation}
                                    </p>

                                </a>
                            ))}
                        </div>

                        <p className='text-3xl justify-center text-center mt-8 mb-4'>Social Media</p>
                        <div className=" grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">

                            <a
                                href="https://www.linkedin.com/company/gameguesser"
                                type="button"
                                target="_blank"
                                className="bg-blue-400 px-3 py-2 hover:bg-blue-500   duration-200  font-semibold text-white inline-flex items-center  rounded">
                                <FaLinkedin className='text-3xl'></FaLinkedin>
                                <span className='ml-2'> Linkedin</span>
                            </a>
                            <a
                                href="https://www.instagram.com/_gaguofficial/"
                                type="button"
                                target="_blank"
                                className="bg-red-500 px-4 py-2 hover:bg-red-600   duration-200  font-semibold text-white inline-flex items-center rounded">
                                <FaInstagram className='text-3xl'></FaInstagram>
                                <span className='ml-2'>Instagram</span>
                            </a>
                            <a
                                href="https://twitter.com/_gaguofficial"
                                type="button"
                                target="_blank" className="bg-blue-500 px-4 py-2 hover:bg-blue-600   duration-200  font-semibold text-white inline-flex items-center rounded">
                                <FaSquareTwitter className='text-3xl'></FaSquareTwitter>
                                <span className='ml-2'>Twitter</span>
                            </a>
                        </div>
                    </div>



                </section>

            </div>
        )

}

export default AdminPanel