// src/App.js
import React, { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

const WebSocketExample = () => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const socket = new SockJS('http://localhost:8080/websocket');
    const stompClient = Stomp.over(socket);

    stompClient.connect({}, (frame) => {
        console.log('Connected to WebSocket');
        
        // Your logic for handling the connection
        stompClient.subscribe('/topic/card-updates', (message) => {
            console.log(message);
            
            // Your logic for handling the message
        });
    });


    setSocket(stompClient);
    
  }, []);

  const sendMessage = () => {
    
    console.log(socket.connected)

    socket.send('/app/send-card', {}, JSON.stringify({ name: 'Your name' }));

  };

  return (
    <div>
      <h1>WebSocket Example</h1>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={sendMessage}>Send Message</button>
    </div>
  );
};

export default WebSocketExample;
