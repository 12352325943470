import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { FaUserEdit } from 'react-icons/fa';
import { useToken } from '../../../service/context/TokenProvider';
import SessionStatus from '../../../service/SessionStatus';

function UserUpdatePanel() {
    const [showModal, setShowModal] = React.useState(false);
    const [userName, setUserName] = useState("");
    const [error, setError] = useState(null);
    const [session, setSession] = useState(null);
    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    const closeAlert = () => {
        setUserName("");
        setError(null);
    };

    const closeArea = () => {
        setUserName("");
        setShowModal(false);
    };

    const setUpdateUserName = (e) => {
        e.preventDefault();
        if (!userName || userName.length < 3) {
            setError("Your username must be more than 3 characters.");
            return;
        }
        axios.put('/user/updateUserName/' + subId + '?userName=' + userName, {
            token: accessToken
        }, {
            headers: {
                Authorization: accessToken
            }
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.data.message === "Access Denied") {
                    setSession("New username:");
                    return;
                }
                if (error.response.status == 409) {
                    setError("The entered username is already in use.");
                    return;
                }
                if (error.response.status == 401) {
                    setError("Something went wrong.");
                    return;
                }
            });
    };

    return (
        <>
            <a
                className='w-full'
                onClick={() => {
                    setShowModal(true);
                }}
                type="button"
            >
                <button className="relative ">

                    <FaUserEdit className=' lg:w-6 lg:h-6 sm:h-4 sm:w-4   font-semibol   ml-2   cursor-pointer text-indigo-500 hover:text-indigo-600 duration-200'></FaUserEdit>
                </button>
            </a>
            {showModal ? (
                <>
                    <div className=" justify-center flex text-white items-center font-bodyFont align-middle  overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none bg-opacity-0 backdrop-filter backdrop-blur-sm z-50 ">
                        {error && (
                            <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-sm">
                                <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                                <div
                                    role="alert"
                                    className="rounded border-s-4 border-indigo-500 bg-slate-900 p-4 relative z-50 max-w-xl mx-auto w-full"
                                >
                                    <button
                                        onClick={closeAlert}
                                        className="absolute top-2 right-2 text-white focus:outline-none"
                                    >
                                        <span className="sr-only">Kapat</span>
                                        <svg
                                            className="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <strong className="block text-6xl mb-5 font-medium text-white">
                                        :(
                                    </strong>
                                    <p className="mt-2 text-2xl text-white">
                                        {error}
                                    </p>
                                </div>
                            </div>
                        )}
                        {session && <SessionStatus session={session}></SessionStatus>}
                        <div className="relative font-bodyFont w-auto  mx-auto max-w-4xl">
                            {/*content*/}
                            <div className=" bg-slate-900  rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
                                {/*body*/}
                                <div className=" relative block items-center align-middle p-5  ">
                                    <form className="" onSubmit={setUpdateUserName}>
                                        {/* Kullanıcı adını güncellemek için input alanı */}
                                        <label htmlFor="HeadlineAct" className="block  lg:text-lg sm:text-sm  font-medium text-white my-2 text-left">
                                            New username:
                                        </label>
                                        <input
                                            type="text"
                                            value={userName}
                                            maxLength={15}
                                            required
                                            onChange={(e) => setUserName(e.target.value)}
                                            className="block w-full p-4 text-white border lg:text-lg sm:text-sm  border-gray-200 rounded-md bg-gray-800  focus:ring-indigo-400 focus:ring-opacity-40  focus:outline-none focus:ring" />
                                        <div className="flex  justify-end  my-2 mt-4  w-full rounded-b">
                                            <button
                                                className="border-white border-2 duration-300 mr-2  transform inline-block rounded-md lg:text-lg sm:text-base bg-slate-950 px-3 py-2  hover:text-red-500 hover:border-red-500 text-white"
                                                type="button"
                                                onClick={() => closeArea()}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="border-white border-2 duration-300  transform inline-block rounded-md lg:text-lg sm:text-base bg-slate-950 px-3 py-2   hover:text-green-500 hover:border-green-500 text-white"
                                                type="submit"
                                            >
                                                Accept
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black opacity-40 z-40"></div>
                </>
            ) : null}
        </>
    );
}

export default UserUpdatePanel;
