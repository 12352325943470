import React, { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingTodayGame from './loading/LoadingTodayGame'
import { Link } from 'react-router-dom'

function TodayGameCard() {
    const [game, setGame] = useState([])
    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const getTodayGame = async () => {
        await axios.get('/game/lastGame')
            .then((response) => {
                setGame(response.data)
                setIsLoaded(true);
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
            })
    }

    useEffect(() => {
        getTodayGame()
    }, [])
    
    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen flex-col">
                <div className="text-4xl font-bold text-red-500 mb-4">An unexpected error occurred...</div>
                <div className="text-2xl font-bold text-red-500">Please try again later.</div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <LoadingTodayGame></LoadingTodayGame>
        )
    } else
        return (
            <Link to={"/gameView/" + game.id}>
                <div class="flex font-bodyFont flex-col items-center justify-center w-full mx-auto hover:cursor-pointer duration-200 hover:scale-105 rounded-lg ring-1 ring-indigo-400">
                    <img src={`data:image/jpeg;base64,${game.gamePreviewImage}`} className="w-full lg:h-96 md:h-60 sm:h-40 h-40 bg-contain bg-center bg-gray-300  shadow-md  rounded-t-lg">
                    </img>
                    <div className='w-full rounded-lg shadow-lg bg-slate-900  rounded-b-lg'>
                        <div className="py-4 text-center ">
                            <h3 className="block lg:text-lg sm:text-sm text-sm text-white" tabIndex="0" role="link">{game.gameName}</h3>
                        </div>
                    </div>
                </div>
            </Link>
        )
}

export default TodayGameCard