import React, { useEffect } from 'react'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0); // Sayfanın en üstüne scroll yap
    }, []);
    useEffect(() => {
        document.title = "Anime Guess - Privacy Policy Page";
        return () => {
            document.title = "Anime Guess - Guess and Compete";
        };
    }, []);
    return (
        <div className="mx-auto max-w-7xl sm:px-20 lg:px-10  px-5  font-bodyFont text-white">
            <div className="mx-auto max-w-7xl relative bg-slate-900 ">
                <div className="mt-10 items-center p-4">
                    <h1 className="lg:text-4xl sm:text-sm  text-sm font-bold  my-2 text-white">PRIVACY POLICY
                    </h1>
                    <p>Last Updated On 28-Dec-2023</p>
                    <p className='mb-2'>Effective Date 28-Dec-2023</p>
                    <p className="mb-4 lg:text-lg text-sm">
                        Anime Guess ("we," "us,") respect your privacy and are committed to protecting it through our compliance with this Privacy Policy. This policy describes:
                        <ul>
                            <li className='my-2'>
                                • the types of information that we may collect from you when you access or use our Website - https://animeguess.app/ and other online services (collectively, our "Services"); and
                            </li>
                            <li className='my-2'>
                                • our practices for collecting, using, maintaining, protecting and disclosing that information.
                            </li>
                        </ul>
                        <p className=' mb-2'>This policy applies only to information we collect through our Services and in the electronic communications sent through or in connection with our Services.</p>
                    </p>
                    <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">DEFINITIONS</h2>
                    <p className="mb-4 lg:text-lg text-sm">
                        <p className='my-2 font-semibold'>User</p>
                        "User" or "you" or "your" refers to you, as a user of the Services. A user is someone who accesses or uses the Services for the purpose of sharing, displaying, hosting, publishing, transacting, uploading information or viewing pictures and includes other persons jointly participating in using the Services.
                        <p className='my-2 font-semibold'>User Account</p>
                        "User Account" is a separate part of the Website, containing User information required by Anime Guess during registration. The User Account is created by the User during registration and is used to identify the User and to provide access to the User Account and the Services.
                        <p className='my-2 font-semibold'>Content</p>
                        "Content" will include (but is not limited to) images, photos, audio, video, location data, 'nearby places', and all other forms of information or data.
                        <p className='my-2'>Anime Guess strives to offer its visitors and Users the many advantages of Internet technology and to provide an interactive and personalized experience. For these purposes, we, from Anime Guess, may use personally identifiable information - like your name, e-mail address, address, telephone number, etc., which are subject to the terms of this Privacy Policy. We, and we suppose you too, do not tolerate spam. We will therefore never sell, barter, or rent your email address to any unauthorized third parties.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">1. WHAT INFORMATION DO WE COLLECT FROM OUR USERS?</h2>

                        <p className="my-2">
                            Anime Guess may collect and use information of and regarding its Users. We collect only data which is essential to our operations and enables us to provide you with better user experience.
                        </p>
                        <p className="my-2">
                            The information, through which the person may be identified, may include data, which the User voluntarily enters, uses or provides when using the Services of the  Anime Guess  Website , or submits when creating a User Account.  Anime Guess collects and uses the information for the purposes, outlined in this Privacy Policy, as well as to offer new services to the User or to familiarise him with new functionalities on the Website.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">We collect two types of information from and about our Users, including information:</h2>
                        <ul>
                            <li className='my-2'>
                                • By which you may be personally identified; and/or
                            </li>
                            <li className='my-2'>
                                • About your internet connection, the equipment you use to access our Services and your usage details (i.e. your Internet Service Provider).
                            </li>
                        </ul>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">The information we collect on or through our Services may include:</h2>
                        <ul>
                            <li className='my-2'>
                                i.         User Account information: Personal information, such as your full name and other information you may provide with your account, such as your address and phone number, that may be displayed as a part of your User Account;
                                <p className=''> We collect this information in order to verify your identity and provide you with an individualised experience of our Services.</p>
                            </li>
                            <li className='my-2'>
                                ii.         Contact Information: Your contact information, such as email address and other ways of communication.
                                <p className=''>We collect this information in order to provide you with access to certain features of our Services and to inform you about relevant information concerning your use of our Services.</p>
                            </li>
                            <li className='my-2'>
                                iii.         Preferences: Your preferences and settings such as time zone and language;
                                <p className=''>We collect this information in order to enhance your user experience.</p>
                            </li>
                            <li className='my-2'>
                                iv.         Your Content: Information you provide through our Services. You also may provide information (likes, ratings, reviews, images, photos, comments, etc.) to be published or displayed on publicly accessible areas of the Services.
                                <p className=''>We collect this information in order to keep track of the user generated content and examine whether it complies with our content standards.</p>
                            </li>
                            <li className='my-2'>
                                v.         Searches and other activities: The search terms you have looked up and results you selected;
                                <p>We collect this information in order to improve your user experience and provide you with more relevant content and Services.</p>
                            </li>
                            <li className='my-2'>
                                vi.         Browsing information: How long you used our Services, which features you used, etc.;
                                <p className=''>We collect this information in order to analyse the behaviour of our users and improve our Services.</p>
                            </li>
                            <li className='my-2'>
                                vii.         Communications: Between you and Anime Guess support staff regarding the Services.
                                <p className=''>We collect this information in order to monitor the quality of our support staff and your communications with us.</p>
                            </li>
                        </ul>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">2.     HOW WE GATHER INFORMATION FROM USERS?</h2>

                        <p className="my-2">
                            How we collect and store information depends on the pages you are visiting, the activities in which you elect to participate and the services provided. For example, you may be asked to provide information when you register for access to certain portions of our Website, create a User account, request certain features, such as e-mail newsletters or when you make a payment. Like most Websites, Anime Guess also collects information automatically and through the use of electronic tools that may be transparent to our visitors and Users. For example, we may log the name of your Internet Service Provider.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">3.     WHAT WE DO WITH THE INFORMATION WE COLLECT?</h2>

                        <p className="my-2">
                            Like other Websites, we collect information to enhance your visit and deliver more individualised content. We respect your privacy and do not share your information with anyone, except in cases when that proves necessary.
                        </p>
                        <p className="my-2">
                            We will retain the information we collect from you for a period of maximum of 5 /five/ years, or less if it is no longer necessary for the provision of the Anime Guess services to you. After the expiry of this period we undertake to delete any information we have collected from you.
                        </p>
                        <p className="my-2">
                            Aggregate information and information that does not personally identify you, may be used in many ways. For example, Anime Guess may combine information about your usage patterns on the Website with similar information obtained from other users to help enhance our site and Services (e.g., to learn which pages are visited the most or what features Users find the most attractive). This information does not include any information about you and does not allow anyone to identify you individually. We may use personally identifiable information, collected on the Website, to communicate with you about your registration and customisation preferences; our Terms of Service and Privacy Policy; services and products offered by or through the Website; and other topics we think you might find of interest. Personally identifiable information collected by Anime Guess may also be used for other purposes, including but not limited to Website administration, troubleshooting, processing of e-commerce transactions and other communications with you.
                        </p>

                        <p className="my-2">
                            Certain third parties we work with, such as Google, may use cookies to serve you with better advertisements based on your prior visits of our Website.
                            Moreover, Google may use DoubleClick cookies, which enables it and its partners to serve you more relevant advertisements based on your prior visits to our Website and/or other websites on the internet. You may opt out of Googles DoubleClick cookies at any time, by visiting http://www.google.com/settings/ads.                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">4.     DISCLOSURE</h2>

                        <p className="my-2">
                        Anime Guess won't transfer information about you to third parties for the purpose of providing or facilitating third-party advertising to you. We won't sell information about you. We may share your account information with third parties in some circumstances, including: (1) with your consent; (2) to a service provider or partner who meets our data protection standards; (3) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process; (4) when we have a good faith belief that doing so will help prevent imminent harm to someone. If we are going to share your information in response to legal process, we'll give you notice so you can challenge it (for example by seeking court intervention), unless we're prohibited by law or believe doing so may endanger others. We will object to requests for information about users of our services that we believe are improper. Certain third parties who provide technical support for the operation of our Website - our Web hosting service for example - may have access to such information.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">5.     RIGHT OF CONSENT WITHDRAWAL
                        </h2>
                        <p className="my-2">
                            You may withdraw your consent for collecting your personal information at any time. To do so, please contact us at gagu@gameguesser.xyz.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">6.     CHANGE OR REVIEW OF INFORMATION
                        </h2>
                        <p className="my-2">
                            If you would like to review, change or delete personal data we have collected from you or you had submitted or permanently delete your account, please contact us.
                        </p>
                        <p className="my-2">
                            For more information regarding the termination or deletion of your information, please refer to Section 8: Termination of this Privacy Policy.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">7.     ACCESSING & CORRECTING YOUR PERSONAL INFORMATION.
                        </h2>
                        <p className="my-2">
                            We take reasonable steps to accurately record the personal information that you provide to us, as well as any subsequent updates.
                        </p>
                        <p className="my-2">
                            We encourage you to review, update, and correct the personal information that we maintain about you. You may request that we delete personal data about you that is inaccurate, incomplete, irrelevant for legitimate purposes, or is being processed in a way which infringes any applicable legal requirements.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">8.     SECURITY: HOW WE PROTECT YOUR INFORMATION?
                        </h2>
                        <p className="my-2">
                            We have implemented appropriate measures in the form of various technical, physical and other means, including, but not limited to measures regarding the security of our electronic systems and databases. These means aim at improving the integrity and security of the personal information that we collect and maintain. However, please be advised that even the best security measures cannot guarantee the full elimination of all risks. If we learn of any violation, breach or danger to our security systems breach, then we will attempt to notify you electronically so that timely and appropriate protective steps can be taken. Apart from informing you via e-mail, we may post a notice through the Website if a security breach occurs.
                        </p>
                        <p className="my-2">
                            Your personal data safety is of utmost importance to us. We review and strive to improve our security measures on a regular basis.
                        </p>
                        <p className="my-2">
                            If we detect a breach of our security measures, which has the potential of harming you as individual we will notify you without undue delay.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">9.     CONTROLLING YOUR PERSONAL DATA
                        </h2>
                        <p className="my-2">
                            Other Users may be able to identify you, or associate you with your account, if you include your personal information in any content you post or make publicly available.
                        </p>
                        <p className="my-2">
                            For more information on how you can control your data shared with us, please see section 4, 5 and 6 of the present agreement.
                        </p>
                        <p className="my-2">
                            If you have any questions regarding the ways you can control your personal data, please contact us at gagu@gameguesser.xyz.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">10.  TERMINATION
                        </h2>
                        <p className="my-2">
                            As mentioned, you may at any time review or change the personal information we maintain about you by contacting Anime Guess. Upon your request, we will delete your contact information and personal data from our active databases.
                        </p>
                        <p className="my-2">
                            This Privacy Policy is effective until terminated by either party. If you no longer agree to be bound by this Privacy Policy, you must cease the use of the Anime Guess Website. If you are dissatisfied with Anime Guess, its content, or any of these terms, conditions, and policies, your sole legal remedy is to discontinue using the Website. Anime Guess reserves the right to terminate or suspend your access to and use of the Website, or parts of them, without notice, if we believe, in our sole discretion that such use is in violation of any applicable law, or harmful to our interests or the interests of another person or entity, or where Anime Guess has reasons to believe that their use is in violation of this Privacy Policy or the Terms of Use.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">11.  CHILDREN'S PRIVACY
                        </h2>
                        <p className="my-2">
                        Anime Guess does not knowingly collect personal information from children under the age of 13. If we learn that we have personal information on a child under the age of 13, we will delete that information from our servers. Anime Guess encourages parents to go online with their kids.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">12.  CHANGES TO THIS POLICY
                        </h2>
                        <p className="my-2">
                        Anime Guess reserves the rights to change this Privacy Policy at any time. Please check this page periodically for changes. Your use of the services after any such amendment or change in the Privacy Policy shall be deemed as your express acceptance to such amended/changed Privacy Policy and an assent to be bound by such changed/amended Privacy Policy. Information collected prior to the time any change is posted will be used according to the rules and laws that applied at the time the information was collected.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">13.  GOVERNING LAW.
                        </h2>
                        <p className="my-2">
                            This Privacy Policy and the use of the Website are governed by the laws of Turkey. The parties undertake to first try to resolve the dispute with by negotiation. If the parties fail to reach an amicable resolution through negotiation, Anime Guess agrees to submit the dispute to the competent Court of Turkey.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">14.  COPYRIGHTS.
                        </h2>
                        <p className="my-2">
                            The copyrights of our Website are the property of Anime Guess.
                        </p>
                        <p className="my-2">
                            Texts, graphics, photographs, animations, videos and clips, visible on the Website are the object of copyright and are part of the intellectual property of Anime Guess. Those may not be reproduced, used, presented or represented without an explicit written permission by Anime Guess. Any distribution of files, obtained by the Users under the Terms and Conditions of the Website, or of parts of such files, constitutes a violation of the relevant intellectual property protection laws and is prosecuted by the law.
                        </p>
                        <p className="my-2">
                            Nothing contained on this Website may be interpreted as granting a license or right of use as a trademark without the prior explicit written consent of Anime Guess.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">15.  MISCELLANEOUS
                        </h2>
                        <p className="my-2">
                        Anime Guess is controlled, operated and administered entirely within Turkey.
                        </p>
                        <p className="my-2">
                            If you are accessing the Anime Guess Website from another jurisdiction, please be advised that you are transferring your personal information to Anime Guess in Turkey and, by using the Website, you consent to that transfer and to abide by the applicable laws concerning your use of the Website and your agreements with us.
                        </p>
                        <p className="my-2">
                            This statement and the policies outlined in this Privacy Policy are not intended to and do not create any contractual or other legal rights in or on behalf of any third party.
                        </p>
                        <p className="my-2">
                        Anime Guess may provide a translation of the English version of the Privacy Policy into other languages. You understand and agree that any translation of the Privacy Policy into other languages is only for your convenience and that the English version shall govern your rights and responsibilities. In case there are any inconsistencies between the English version of the Privacy Policy and its translated versions, the English version of the Terms shall prevail.
                        </p>
                    </p>
                    <p className='mb-4 lg:text-lg text-sm'>
                        <h2 className="lg:text-xl sm:text-sm font-semibold mb-2">16.  CONTACT
                        </h2>
                        <p className="my-2">
                            If you believe Anime Guess does not adhere to this Privacy Policy, in order to address a question, to resolve a complaint regarding the Website or the Anime Guess Services, or to receive further information regarding the Services, please contact Anime Guess via email at gagu@gameguesser.xyz.
                        </p>
                    </p>
                    <p className='my-2'>The content in this website is not affiliated with, endorsed, or supported by any company. All copyrights and trademarks are the property of their respective owners. If you believe your copyright is being infringed, please contact us.</p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
