import React from 'react'

function Maintenance() {
    return (
        <div className="flex  justify-center min-h-screen bg-zinc-900 font-bodyFont">

            <div className="flex flex-col items-center justify-center max-w-2xl">

                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" className="w-48 h-48 text-indigo-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                    </svg>
                </div>
                <p className="text-4xl font-bold tracking-tight text-white  mb-2">
                    We are in maintenance.
                </p>
                <p className="text-lg font-bold tracking-tight text-indigo-500  mb-2">
                    Anime Guess
                </p>
                <p className=" text-gray-500 text-2xl">Please come back later. </p>

            </div>
        </div>
    )
}

export default Maintenance