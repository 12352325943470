import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaCircleInfo, FaPen, FaTrash } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../../service/context/TokenProvider';
import AddAvatar from '../components/AddAvatar';
import AddParam from '../components/AddParam';
import AddRole from '../components/AddRole';
import AddRoleUser from '../components/AddRoleUser';
import DeleteRoleUser from '../components/DeleteRoleUser';
import AdminViewGame from '../view/AdminViewGame';
import AdminViewParam from '../view/AdminViewParam';

function AdminControlManager() {
    const [error, setError] = useState(null);
    const [param, setParam] = useState([]);
    const [pageableAvatar, setPageableAvatar] = useState([])
    const [role, setRole] = useState([]);
    const { tokenData } = useToken();
    const { accessToken } = tokenData
    const navigate = useNavigate()
    const [checkKey, setCheckKey] = useState(false)

    const [currentPage, setCurrentPage] = useState(0);

    const paramGetAll = () => {
        axios
            .get("/param/getAll", {
                headers: {
                    Authorization: accessToken
                }
            })
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setParam(result);
                },
                (error) => {
                    setError(error)
                }
            );
    };

    const avatarGetAll = async (page) => {
        try {
            const response = await axios.get(`/avatar/getAllAvatarPageable?page=${page}&size=10`, {
                headers: {
                    Authorization: accessToken
                }
            });
            setPageableAvatar(response.data);
        } catch (error) {
            setError(error);
        }
    }

    const roleGetAll = () => {
        axios
            .get("/role/getAll", {
                headers: {
                    Authorization: accessToken
                }
            })
            .then(function (response) {
                return response.data;
            })
            .then(
                (result) => {
                    setRole(result);
                },
                (error) => {
                    setError(error)
                }
            );
    };


    const deleteParam = async (id) => {
        await axios.delete(`/param/${id}`, {
            headers: {
                Authorization: accessToken
            }
        })
            .catch(function (error) {
                setParam(error)
            });
        window.location.reload();
    };

    const deleteAvatar = async (name) => {
        await axios.delete(`/avatar/${name}`, {
            headers: {
                Authorization: accessToken
            }
        })
            .catch(function (error) {
                setPageableAvatar(error)
            });
        window.location.reload();
    };
    const deleteRole = async (id) => {
        await axios.delete(`/role/${id}`, {
            headers: {
                Authorization: accessToken
            }
        })
            .catch(function (error) {
                setRole(error)
            });
        window.location.reload();
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePrevPageClick = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPageClick = () => {
        if (currentPage < pageableAvatar.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.post("/param/getPanelLink/send?paramValue=" + localStorage.getItem('key'), {}, {
                    headers: {
                        Authorization: accessToken,
                    },
                });
                if (response.status === 200) {
                    setCheckKey(true);
                    paramGetAll()
                    roleGetAll()
                    avatarGetAll(currentPage)
                } else if (response.status === 404) {
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setCheckKey(false);
                    localStorage.removeItem('key');
                    navigate("/forbidden");
                }
                console.error("Bir hata oluştu: ", error);
            }
        }
        fetchData();
    }, [localStorage.getItem('key')]);


    useEffect(() => {
        avatarGetAll(currentPage)
    }, [currentPage]);

    if (!checkKey) {
        return (
            <div className='fixed top-0 left-0 flex justify-center items-center w-full h-full bg-black  z-50'>
                <div className='text-white border-zinc-600 rounded-lg'>
                    <div className='flex justify-center items-center mt-20'>
                        <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="grid grid-cols-2 grid-rows-1 h-screen text-white">
                <div className="border">
                    <p className=' text-xl ml-1'>Param</p>

                    <div>
                        {param ? (
                            param.length === 0 ?
                                (
                                    <p className="text-xl  mt-1 text-start align-middle ">Param bulunamadi.</p>

                                ) : (
                                    <table className="min-w-full text-left text-sm font-light ">
                                        <thead className="border-b font-medium border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">*</th>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">id</th>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">paramName</th>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">paramValue</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {param ? param.map((key, index) => (
                                                <tr
                                                    className="border-b transition duration-300 ease-in-out  border-neutral-500 hover:bg-neutral-600">
                                                    <td className="whitespace-nowrap px-2 py-0.5 font-medium">{index + 1} </td>
                                                    <td className="whitespace-nowrap px-2  py-0.5 font-medium">{key.id} </td>
                                                    <td className="whitespace-nowrap px-2 py-0.5 text-xs  ">{key.paramName} </td>
                                                    <td className="whitespace-nowrap px-2 py-0.5   text-xs   ">{key.paramValue} </td>
                                                    <td className="whitespace-nowrap px-4 py-1">
                                                        <span className="flex justify-between">
                                                            <AdminViewParam key={index} id={key.id} paramName={key.paramName} paramValue={key.paramValue} >
                                                                <FaPen
                                                                    className='text-green-400 font-medium cursor-pointer hover:text-green-500 duration-200'>
                                                                </FaPen>
                                                            </AdminViewParam>
                                                            <FaTrash
                                                                onClick={() => deleteParam(key.id)}
                                                                className='text-red-400 font-medium cursor-pointer hover:text-red-500 duration-200' />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) : null}
                                        </tbody>
                                    </table>
                                )

                        ) : (
                            null
                        )}

                    </div>

                    <AddParam></AddParam>
                </div>
                <div className="border">

                    <p className=' text-xl ml-1'>Avatar</p>

                    <div>
                        {pageableAvatar ? (
                            pageableAvatar.length === 0 ?
                                (
                                    <p className="text-xl  mt-1 text-start align-middle ">Avatar bulunamadi.</p>

                                ) : (
                                    <div>
                                        <table className="min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium border-neutral-500">
                                                <tr>
                                                    <th scope="col" className="px-2 py-1 text-blue-500 text-base">*</th>
                                                    <th scope="col" className="px-2 py-1 text-blue-500 text-base">id</th>
                                                    <th scope="col" className="px-2 py-1 text-blue-500 text-base">AvatarName</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageableAvatar ? pageableAvatar.content.map((key, index) => (
                                                    <tr
                                                        className="border-b transition duration-300 ease-in-out  border-neutral-500 hover:bg-neutral-600">
                                                        <td className="whitespace-nowrap px-2 py-0.5 font-medium">{index + 1} </td>
                                                        <td className="whitespace-nowrap px-2  py-0.5 font-medium">{key.id} </td>
                                                        <td className="whitespace-nowrap px-2 py-0.5  ">{key.avatarName} </td>
                                                        <td className="whitespace-nowrap px-4 py-1">
                                                            <span className="flex justify-end">
                                                                <FaTrash
                                                                    onClick={() => deleteAvatar(key.avatarName)}
                                                                    className='text-red-400 font-medium cursor-pointer hover:text-red-500 duration-200' />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )) : null}

                                            </tbody>

                                        </table>
                                        <div className="flex justify-center mt-5">
                                            <a
                                                href="#"
                                                onClick={handlePrevPageClick}
                                                className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                </svg>
                                            </a>

                                            {Array.from({ length: pageableAvatar.totalPages }, (_, i) => (
                                                <a
                                                    onClick={() => handlePageClick(i)}
                                                    className={`px-2 py-2 mx-1 cursor-pointer  transition-colors duration-300 transform rounded-md sm:inline text-gray-200  ${currentPage === i ? 'font-bold bg-red-500 text-white' : ''}`}
                                                >
                                                    {i + 1}
                                                </a>
                                            ))}

                                            <a
                                                href="#"
                                                onClick={handleNextPageClick}
                                                className="flex items-center justify-center px-2 py-2 mx-1 transition-colors duration-300 transform bg-gray-800 text-white rounded-md rtl:-scale-x-100  "
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                )

                        ) : (
                            null
                        )}
                        <AddAvatar></AddAvatar>
                    </div>
                </div>
                <div className="border">
                    <p className=' text-xl ml-1'>Role</p>
                    <div>
                        {role ? (
                            role.length === 0 ?
                                (
                                    <p className="text-xl  mt-1 text-start align-middle ">Role bulunamadi.</p>

                                ) : (
                                    <table className="min-w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">*</th>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">id</th>
                                                <th scope="col" className="px-2 py-1 text-blue-500 text-base">Role Adi</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {role ? role.map((key, index) => (
                                                <tr
                                                    className="border-b transition duration-300 ease-in-out  border-neutral-500 hover:bg-neutral-600">
                                                    <td className="whitespace-nowrap px-2 py-0.5 font-medium">{index + 1} </td>
                                                    <td className="whitespace-nowrap px-2  py-0.5 font-medium">{key.id} </td>
                                                    <td className="whitespace-nowrap px-2 py-0.5  ">{key.roleName} </td>
                                                    <td className="whitespace-nowrap px-4 py-1">
                                                        <span className="flex justify-end">
                                                            <FaTrash
                                                                onClick={() => deleteRole(key.id)}
                                                                className='text-red-400 font-medium cursor-pointer hover:text-red-500 duration-200' />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) : null}
                                        </tbody>
                                    </table>
                                )

                        ) : (
                            null
                        )}
                        <div className=' flex  justify-between'>
                            <AddRole></AddRole>
                            <AddRoleUser></AddRoleUser>
                            <DeleteRoleUser></DeleteRoleUser>
                        </div>
                    </div>

                </div>
                <div className="border ">Bölge 4</div>
            </div>
        )
    }
}

export default AdminControlManager