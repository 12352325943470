import React, { useEffect, useState } from 'react'
import GameCard from './GameCard'
import axios from 'axios'
import LoadingGameCard from './loading/LoadingGameCard'
import { Link } from 'react-router-dom'

export default function GameCardPanel(props) {
    const { id, gameTypeName, updateLoaded } = props
    const [randomGames, setRandomGames] = useState([])
    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const getRandomGames = async () => {
        await axios.get('/game/randomGames/' + id)
            .then((response) => {
                setRandomGames(response.data)
                setIsLoaded(true);
                updateLoaded(true)
            })
            .catch((error) => {
                setIsLoaded(true);
                setError(error)
                updateLoaded(true)
            })
    }

    useEffect(() => {
        getRandomGames()
    }, [])

    return (
        <div>
            <div className='flex justify-between  mt-20'>
                <h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    {gameTypeName}
                </h1>
                <Link to={"/categoryPage/" + id}>
                    <h1 className="lg:text-xl sm:text-base font-bold  text-white cursor-pointer  hover:animate-pulse  hover:scale-105">
                        see more
                    </h1>
                </Link>
            </div>

            <div className=" grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-10 mt-10">
                {
                    randomGames.length == 0 ?
                    <>
                        <LoadingGameCard></LoadingGameCard>
                        <LoadingGameCard></LoadingGameCard>
                        <LoadingGameCard></LoadingGameCard>
                        <LoadingGameCard></LoadingGameCard>
                    </>
                    :
                    randomGames.map((key, index) => (
                        <GameCard key={index} data={key} ></GameCard>
                    ))
                }
            </div>
        </div>
    )
}
