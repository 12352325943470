import React, { useState } from 'react'
import { FaLock, FaAt } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineGoogle } from "react-icons/ai";
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { initializeAvatars } from '../../service/Avatar';
import { useToken } from '../../service/context/TokenProvider';
import logo from '../../assets/logo.png'


function Login() {
    const [isSend, setIsSend] = useState(false);
    const [error, setError] = useState(null);
    const { fetchTokenData } = useToken();

    let navigate = useNavigate();

    const [user, setUser] = useState({
        userMail: "",
        userPassword: "",
    });

    const [formError, setFormError] = useState({
        userMail: "",
        userPassword: "",
    });

    const { userMail, userPassword } = user;

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const validateFormInput = async (event) => {
        event.preventDefault();
        let inputError = {
            userMail: "",
            userPassword: "",
        };

        if (user.userPassword.length <= 6) {
            setFormError({
                ...inputError,
                userPassword: "The password you entered is not valid.",
            });
            return;
        }
        setFormError(inputError);
        await onLogin();
    };

    const onLogin = async (e) => {
        setIsSend(true);
        await axios.post("/user/login", user).then(function (response) {
            loginSuccess(response)
        }).catch((error) => {
            if (error.response.data.message === "Yanlis") {
                setIsSend(false);
                setError("The email address or password is incorrect. Please try again.");
                return
            }
            setError("An error occurred while logging in. Please ask again later or contact support.")
            setIsSend(false);
        })
    };

    const loginSuccess = (response) => {
        localStorage.setItem("signedUserId", response.data.userId);
        localStorage.setItem("signedRefreshToken", response.data.refreshToken);
        localStorage.setItem("signedUserAvatarName", response.data.userAvatarName);
        initializeAvatars()
        navigate("/userProfile/" + localStorage.getItem("signedUserId"));
        fetchTokenData();
        //window.location.reload();
    }

    const closeAlert = () => {
        setError(null);
    };

    const loginWithGoogle = useGoogleLogin({
        flow: 'implicit',
        onSuccess: tokenResponse => onGoogleLoginSuccess(tokenResponse),
    });

    const onGoogleLoginSuccess = (token) => {

        axios.post("/user/loginWithGoogle", token)
            .then((response) => {
                loginSuccess(response)
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    setError("The entered email address is already in use.");
                    return
                }
                setError("An error occurred while logging in. Please ask again later or contact support.")
            })
    }

    return (
        <div>
            {isSend ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-12"></div>
                </div>
                :
                null
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-slate-900 opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-indigo-500 bg-slate-950 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}
            <div className="mx-auto min-h-screen px-4 py-16 sm:px-6 lg:px-8 font-bodyFont bg-zinc-90  ">
                <img
                    onClick={() => navigate('/')}
                    src={logo}
                    className=" absolute top-12 lg:h-20 md:h-16 sm:h-10 h-10 w-auto cursor-pointer "
                    alt="Anime Guess"
                />
                <div className='mx-auto max-w-lg mt-20 '>
                    <h1 className="text-left lg:text-6xl font-bold text-white text-5xl">
                        Login
                    </h1>
                    <p className="text-left text-white my-3">
                        Don't you have an account? <Link to={"/join"}>
                            <span className='text-indigo-400 hover:text-indigo-500 cursor-pointer'>join us!</span>
                        </Link>
                    </p>
                </div>
                <div className="mx-auto max-w-lg ring-1 ring-indigo-300 rounded-md sm:p-6 lg:p-8 p-2 ">
                    <form
                        onSubmit={validateFormInput}
                        action=""
                        className="mt-6 space-y-4 rounded-lg  "
                    >
                        <div>
                            <label for="email" className="sr-only">Email</label>

                            <div className="relative ">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="email"
                                    id="userMail"
                                    maxLength={50}
                                    name="userMail"
                                    value={userMail}
                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm  bg-gray-800"
                                    placeholder="E-mail"
                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaAt className='text-gray-400'></FaAt>

                                </span>
                            </div>
                        </div>
                        <div>
                            <label for="password" className="sr-only">Password</label>
                            <div className="relative my-5">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="password"
                                    id="userPassword"
                                    maxLength={20}
                                    name="userPassword"
                                    value={userPassword}
                                    className="w-full rounded-lg text-white  border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm  bg-gray-800"
                                    placeholder="Password"
                                />
                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                        <FaLock className='text-gray-400'></FaLock>
                                    </span>
                                </span>
                            </div>
                            <p className="text-red-500 font-semibold text-base">
                                {formError.userPassword}
                            </p>
                        </div>
                        <button
                            type="submit"
                            className="block w-full  rounded-lg bg-indigo-500 hover:bg-indigo-400 duration-200 px-5 py-3 lg:text-xl text-lg font-medium text-white"
                        >
                            Login In
                        </button>

                        <Link to={"/forgotPassword"}>
                            <button className="text-left text-md mt-2 hover:text-gray-600 cursor-pointer text-gray-500">
                                Forgot Password?
                            </button>
                        </Link>
                        <div className="flex flex-col">
                            <button type="button" onClick={loginWithGoogle} className="py-3 px-5 flex gap-2 justify-center items-center w-full  bg-zinc-700 hover:bg-zinc-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                <AiOutlineGoogle className='text-2xl'></AiOutlineGoogle>
                                Continue with Google
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login