import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import getAccessToken from "../AccessToken";
import { Token } from "../Token";

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [tokenData, setTokenData] = useState({
        userRoles: [],
        subId: null,
        accessToken: null
    });

    const resetTokenData = () => {
        setTokenData({
            userRoles: [],
            subId: null,
            accessToken: null
        });
    }

    const fetchTokenData = async () => {
        const { userRoles, subId, accessToken } = await Token();
        setTokenData({ userRoles, subId, accessToken });
    };

    const checkTokenValidity = async () => {
        if (!localStorage.getItem("signedUserId")) {
            return
        }
        const accessToken = await getAccessToken();
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            if (decodedToken.exp && decodedToken.exp <(Date.now() + 900000) / 1000) {
                try {
                    const response = await axios.post("/user/refresh", {
                        "refreshToken": localStorage.getItem("signedRefreshToken")
                    });
                    setTokenData({ ...tokenData, accessToken: response.data.accessToken });
                    // Yeni tokenları local storage'a kaydet
                    localStorage.setItem("signedRefreshToken", response.data.refreshToken);
                    window.location.reload();
                    return true;
                } catch (error) {
                    console.error(error);
                    return false;
                }
            }
            return true;
        }
        return false;
    };


    useEffect(() => {
        fetchTokenData();

        checkTokenValidity();

        const interval = setInterval(() => {
            checkTokenValidity();
        }, 900000 ); // 15 saniyede bir çalışacak

        return () => clearInterval(interval);

    }, []); // Bu etkileşim sadece bir kere çalışacak şekilde ayarlanmıştır.

    return (
        <TokenContext.Provider value={{ "tokenData": tokenData, "resetTokenData": resetTokenData, "fetchTokenData": fetchTokenData }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    const context = useContext(TokenContext);
    if (!context) {
        throw new Error("useToken must be used within a TokenProvider");
    }
    return context;
};
