import React, { useEffect } from 'react'
import design8 from '../../assets/design6.png'
import { Link } from 'react-router-dom'

function OurSite() {

    useEffect(() => {
        document.title = "Anime Guess - Our Site Page";
        return () => {
            document.title = "Anime Guess - Guess and Compete";
        };
    }, []);

    return (
        <div
        >
            <section
                className="mx-auto max-w-6xl lg:px-10 px-5 font-bodyFont">
                <div className="container py-5 mx-auto">

                    <div className="   rounded-lg  relative flex flex-col w-full  outline-none focus:outline-none">
                        <p className='lg:text-4xl md:text-3xl sm:text-xl  text-xl text-indigo-400  font-bodyFont text-center  cursor-default my-7'>
                            What's on Our Site?
                        </p>
                        <div className='text-left'>
                            <h1 className='lg:text-2xl md:text-xl sm:text-base text-white text-xl underline my-5'>What is Anime Guess?</h1>
                            <div className=''>
                                <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white my-4">
                                    Welcome to Anime Guess, your daily portal to anime excitement! Dive into a world of guessing anime, earn points, and rise through the ranks. Challenge yourself with iconic anime titles, characters, and scenes. While playing Anime Guess, discover new anime gems and add them to your list. Compete with friends, aim for personalized anime tags, and let the anime guessing adventure elevate your otaku expertise!
                                    <Link to={"/join"}><span className='text-indigo-400 cursor-pointer hover:text-indigo-500 ml-1'>Join us, and let the guessing games begin!</span> </Link>
                                </p>
                                <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white my-4">
                                    Anime Guess, Guess the Anime from the picture. A new anime game is added every day. Challenge yourself to recognize anime titles from screenshots. New anime game added regularly.
                                    <Link to={"/"}><span className='text-indigo-400 cursor-pointer hover:text-indigo-500 ml-1'>Try to Guess the anime!</span> </Link>

                                </p>
                            </div>
                        </div>
                        <div className='lg:flex items-center'>
                            <img src={design8} alt="image1" className='rounded-lg lg:h-96 h-40 lg:w-auto mb-4 lg:mr-4' />
                            <div className='text-left'>
                                <h1 className='lg:text-2xl md:text-xl sm:text-base text-white text-xl underline my-2'>In Game</h1>
                                <div className=''>
                                    <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white mt-4">
                                        *Guess the Anime game consists of 3 difficulty levels, and these levels are determined by dividing the image into equal parts.
                                    </p>
                                    <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white mt-4">
                                        *When you start the game, if you can't identify which anime the presented image belongs to, you can open the image by pressing the 'Open Square' button. However, this will result in receiving fewer points.
                                    </p>
                                    <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-white mt-4">
                                        *Our games can last between 5 to 10 minutes, varying with their difficulty levels. Following the game, your ranking on the leaderboard will be determined by the points you have earned. The top five players will be showcased on the leaderboard.
                                    </p>
                                    <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm font-medium text-red-500 mt-2">
                                        *It is crucial to note that exiting the game, refreshing the page, or encountering an internet connection loss during the game will be considered as if you have played the game, and you won't accrue any points from that specific game.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='text-left'>
                            <h1 className='lg:text-2xl md:text-xl sm:text-base text-white text-xl underline my-5'>What is Top Players?</h1>
                            <div className=''>
                                <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                    *In the Top Players section, the latest game uploaded to the system is showcased here, and a new game is added every day.</p>
                                <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                    *The top 50 players are listed based on the points they have accumulated in the games so far.</p>
                                <p htmlFor="HeadlineAct" className="block lg:text-xl sm:text-sm  font-medium text-white mt-4">
                                    *Additionally, tags are distributed based on the points users have collected.</p>
                            </div>
                        </div>
                        <div className='text-left'>
                            <h1 className='lg:text-2xl md:text-xl sm:text-base  text-white text-xl underline my-5'>Anime Tags</h1>
                            <div className='grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-2'>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm '>
                                    <p className=" text-teal-700  font-medium">
                                        1 - Anime Explorer:
                                    </p>
                                    <p className='text-white ml-2'>0-4999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className=" text-sky-700   font-medium">
                                        2 - Anime Devotee:
                                    </p>
                                    <p className='text-white ml-2'>5000-9999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm '>
                                    <p className=" text-yellow-700  font-medium">
                                        3 - Anime Connoisseur:
                                    </p>
                                    <p className='text-white ml-2'>10000-19999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm '>
                                    <p className="text-rose-700  font-medium">
                                        4 - Anime Savant:
                                    </p>
                                    <p className='text-white ml-2'>20000-29999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-teal-200 to-green-700 font-medium">
                                        5 - Anime Virtuoso:
                                    </p>
                                    <p className='text-white ml-2'>30000-39999 points</p>
                                </div>


                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-sky-200 to-blue-700 font-medium">
                                        6 - Anime Champion:
                                    </p>
                                    <p className='text-white ml-2'>40000-49999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-yellow-200 to-orange-700   font-medium">
                                        7 - Anime Prodigy:
                                    </p>
                                    <p className='text-white ml-2'>50000-64999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-rose-200 to-red-700 font-medium">
                                        8 - Anime Myth:
                                    </p>
                                    <p className='text-white ml-2'>65000-74999 points</p>
                                </div>


                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className="bg-clip-text bg-gradient-to-r text-transparent from-blue-500 via-purple-500 to-pink-500 font-medium">
                                        9 - MASTER OF ANIME:
                                    </p>
                                    <p className='text-white ml-2'>75000-99999 points</p>
                                </div>

                                <div className='flex  mt-2 lg:text-xl sm:text-sm text-sm'>
                                    <p className=" bg-gradient-to-r  duration-200   animate-text  from-teal-500 via-purple-500  to-orange-500 bg-clip-text text-transparent font-medium">
                                        10 - GOD OF ANIME:
                                    </p>
                                    <p className='text-white ml-2'>100000-????? points</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}

export default OurSite