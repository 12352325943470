import axios from "axios";



function getAccessToken() {

    return new Promise((resolve, reject) => {
        if (!localStorage.getItem("signedUserId")) {
            return
        }
        axios.get('/access/getOne/' + localStorage.getItem("signedUserId") + '?token=' + localStorage.getItem("signedRefreshToken"))
            .then((response) => {

                const accessToken = response.data.token;

                resolve(accessToken); // Veriyi döndürm
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    }
    );

}


export default getAccessToken;

