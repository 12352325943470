import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

function Error() {
    useEffect(() => {
        document.title = "Anime Guess - 404 Page Not Found";
        return () => {
            document.title = "Anime Guess - Guess and Compete!";
        };
    }, []);

    return (
        <div className="grid h-screen bg-zinc-900 place-content-center font-bodyFont">
            <div className="text-center">
                <h1 className="font-black text-gray-200 text-9xl">404</h1>
                <p className="text-4xl font-bold tracking-tight text-indigo-400  mb-2">
                    Anime Guess
                </p>
                <p className="mt-4 text-gray-500 text-2xl">We can't find that page.</p>
                <Link to={"/"}>
                    <button className=" text-base px-4 py-2 mt-3 font-medium tracking-wide text-white transition-colors duration-200 transform  bg-indigo-500 rounded hover:bg-indigo-400  ">
                        Home Page
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Error