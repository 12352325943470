import React, { useState } from 'react'
import { FaLock, FaAt, FaUserLarge } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineGoogle } from "react-icons/ai";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useToken } from '../../service/context/TokenProvider';
import { initializeAvatars } from '../../service/Avatar';
import VerifyMail from './VerifyMail';
import logo from '../../assets/logo.png'

function Register() {

    const [verificationState, setVerificationState] = useState();
    const [error, setError] = useState(null);
    // const [isChecked, setIsChecked] = useState(false);
    const { fetchTokenData } = useToken();
    let navigate = useNavigate();
    const [user, setUser] = useState({
        userName: "",
        userMail: "",
        userPassword: "",
        isVerified: false,
    });

    const [formError, setFormError] = useState({
        userName: "",
        userMail: "",
        userPassword: "",
        checked: ""
    });
    const { userName, userMail, userPassword } = user;

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const validateFormInput = async (event) => {
        event.preventDefault();
        let inputError = {
            userName: "",
            userMail: "",
            userPassword: "",

        };

        if (user.userName.trim().length <= 2) {
            setFormError({
                ...inputError,
                userName: "Your name must be more than 2 characters.",
            });
            return;
        }

        if (user.userName.trim().length >= 15) {
            setFormError({
                ...inputError,
                userName: "Your name cannot exceed 10 characters.",
            });
            return;
        }

        if (user.userPassword.length <= 6) {
            setFormError({
                ...inputError,
                userPassword: "Your password must be more than 6 characters.",
            });
            return;
        }
        // if (!isChecked) {
        //     setFormError({
        //         ...inputError,
        //         checked: "Lütfen şartları onayladığınızdan emin olun.",
        //     });
        //     return;
        // }
        setFormError(inputError);
        await checkForMailAlreadyExist()
    };

    const checkForMailAlreadyExist = async () => {
        axios.get('/user/checkUser?userMail=' + user.userMail + "&userName=" + user.userName)
            .then(res => {
                sendVerificationCode()
            })
            .catch((error) => {
                if (error.response.data.message === "KullanıcıAdi") {
                    setError("The entered username is already in use.");
                    return
                }
                if (error.response.data.message === "Email") {
                    setError("The entered email address is already in use.");
                    return
                }
                setError("An error occurred during registration. Please try again later or contact support.");
            })
    }

    const sendVerificationCode = async () => {
        setVerificationState(false)
        await axios.post("/user/verification/send?userMail=" + user.userMail)
            .then(function (response) {
                setVerificationState(true)
                return response.data
            }).catch((error) => {
                console.log(error)
            })
    }

    const onSubmit = async (e) => {
        await axios.post("/user/register", user).then((response) => {
            loginSuccess(response)
        }).catch((error) => {
            if (error.response.data.message === "KullanıcıAdi") {
                setError("The entered username is already in use.");
                return
            }
            if (error.response.data.message === "Email") {
                setError("The entered email address is already in use.");
                return
            }
            setError("An error occurred during registration. Please try again later or contact support.");
        })

    };

    const closeAlert = () => {
        setError(null);
    };

    const loginSuccess = (response) => {
        localStorage.setItem("signedUserId", response.data.userId);
        localStorage.setItem("signedRefreshToken", response.data.refreshToken);
        localStorage.setItem("signedUserAvatarName", response.data.userAvatarName);
        initializeAvatars()
        navigate("/userProfile/" + localStorage.getItem("signedUserId"));
        fetchTokenData();
    }

    const loginWithGoogle = useGoogleLogin({
        flow: 'implicit',
        onSuccess: tokenResponse => onGoogleLoginSuccess(tokenResponse),
    });

    const onGoogleLoginSuccess = (token) => {

        axios.post("/user/loginWithGoogle", token)
            .then((response) => {
                loginSuccess(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div>
            {verificationState == false ?
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-4 h-40 w-40 sm:h-20 sm:w-20 mb-12"></div>
                    <label className="block  text-2xl text-white my-2 text-left">
                        Sending Verification Code
                    </label>
                </div>
                :
                verificationState == true ?
                    <VerifyMail setVerificationState={setVerificationState} sendVerificationCode={sendVerificationCode} user={user} onSubmit={onSubmit}></VerifyMail>
                    :
                    verificationState == null ?
                        null
                        :
                        <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-0 backdrop-filter backdrop-blur-sm z-10">
                            <label className="block  text-2xl text-white my-2 text-left">
                                Verification Successful
                            </label>
                        </div>
            }
            {error && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-slate-900 opacity-40 z-40"></div>
                    <div
                        role="alert"
                        className="rounded border-s-4 border-indigo-500 bg-slate-950 p-4 relative z-50 max-w-xl mx-auto w-full"
                    >
                        <button
                            onClick={closeAlert}
                            className="absolute top-2 right-2 text-white focus:outline-none"
                        >
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <strong className="block text-6xl mb-5 font-medium text-white">
                            :(
                        </strong>
                        <p className="mt-2 text-xl text-white">
                            {error}
                        </p>
                    </div>
                </div>
            )}

            <div className="mx-auto min-h-screen px-4 py-16 sm:px-6 lg:px-8 font-bodyFont bg-zinc-90  ">
                <img
                    onClick={() => navigate('/')}
                    src={logo}
                    className=" absolute top-12 lg:h-20 md:h-16 sm:h-10 h-10 w-auto cursor-pointer "
                    alt="Anime Guess"
                />
                <div className='mx-auto max-w-lg mt-20 '>
                    <h1 className="text-left lg:text-6xl font-bold text-white text-5xl">
                        Join
                    </h1>
                    <p className="text-left text-white my-3">
                        Do you already have an account?  <Link to={"/login"}>
                            <span className='text-indigo-400 hover:text-indigo-500 cursor-pointer'>Log In!</span>
                        </Link>
                    </p>
                </div>
                <div className="mx-auto max-w-lg ring-1 ring-indigo-300 rounded-md sm:p-6 lg:p-8 p-2 ">
                    <form
                        onSubmit={validateFormInput}
                        className="mt-6 space-y-4 rounded-lg  "
                    >
                        <div>
                            <label for="Username" className="sr-only">Username</label>

                            <div className="relative ">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="userName"
                                    id="userName"
                                    value={userName}
                                    maxLength={15}
                                    name="userName"
                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm bg-gray-800"
                                    placeholder="Username"
                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaUserLarge className='text-gray-400'></FaUserLarge>
                                </span>
                            </div>
                        </div>
                        <div>
                            <label for="email" className="sr-only">Email</label>

                            <div className="relative ">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="email"
                                    id="userMail"
                                    maxLength={50}
                                    name="userMail"
                                    value={userMail}
                                    className="w-full rounded-lg  text-white border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm bg-gray-800"
                                    placeholder="E-mail"
                                />

                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <FaAt className='text-gray-400'></FaAt>

                                </span>
                            </div>
                        </div>
                        <div>
                            <label for="password" className="sr-only">Password</label>
                            <div className="relative my-5">
                                <input
                                    onChange={(e) => onInputChange(e)}
                                    required
                                    type="password"
                                    id="userPassword"
                                    maxLength={20}
                                    name="userPassword"
                                    value={userPassword}
                                    className="w-full rounded-lg text-white  border-gray-700 border-2 p-4 pe-12 text-lg shadow-sm  bg-gray-800"
                                    placeholder="Password"
                                />
                                <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                    <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                        <FaLock className='text-gray-400'></FaLock>
                                    </span>
                                </span>
                            </div>
                            {/* <p className="text-red-500 font-semibold text-base">
                                {formError.userPassword}
                            </p> */}
                        </div>
                        <button
                            type="submit"
                            className="block w-full  rounded-lg bg-indigo-500 hover:bg-indigo-400 duration-200 px-5 py-3 lg:text-xl text-lg font-medium text-white"
                        >
                            Join In
                        </button>


                        <button className="text-left text-md mt-2 hover:text-gray-600 cursor-pointer text-gray-500">
                            By signing up, you agree to Anime Guess Terms of Service and Privacy Policy.
                        </button>

                        <div className="flex flex-col">
                            <button type="button" onClick={loginWithGoogle} className="py-3 px-5 flex gap-2 justify-center items-center w-full  bg-zinc-700 hover:bg-zinc-600 focus:ring-red-500 focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                <AiOutlineGoogle className='text-2xl'></AiOutlineGoogle>
                                Continue with Google
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Register