import React from 'react'
import LoadingGameCard from './LoadingGameCard'

export default function LoadingGameCardPanel(props) {
    return (
        <div>
            <div className='flex justify-between  mt-20'>
                <div className="h-7 w-1/4 mb-2 bg-slate-800 rounded animate-pulse justify-center"></div>
            </div>

            <div className='grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-10 mt-10'>
                <LoadingGameCard></LoadingGameCard>
                <LoadingGameCard></LoadingGameCard>
                <LoadingGameCard></LoadingGameCard>
                <LoadingGameCard></LoadingGameCard>
            </div>
        </div>
    )
}
