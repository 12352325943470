import React, { useEffect, useState } from 'react'
import CategoryTrendCard from './CategoryTrendCard'
import LoadingCategoryTrendCard from './loading/LoadingCategoryTrendCard'
import axios from 'axios'

function CategoryTrendPanel(props) {
    const { categoryId } = props

    const [trendGames, setTrendGames] = useState([])

    const getTrendGames = () => {
        axios.get('/game/topTrendGames/gameCategory/' + categoryId + '?limit=2')
            .then(response => {
                setTrendGames(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getTrendGames()
    }, [])

    return (
        <div>
            <div className='flex justify-between mt-20'>
                {categoryId == 4 ? (
                    <h1 className="lg:text-3xl sm:text-xl font-bold text-white">
                        Trend Games
                    </h1>
                ) : (<h1 className="lg:text-3xl sm:text-xl font-bold  text-white ">
                    Trend Games
                </h1>)}

            </div>
            <div className=" grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-4 mt-10">
                {
                    trendGames.length == 0 ?
                    <>
                        <LoadingCategoryTrendCard></LoadingCategoryTrendCard>
                        <LoadingCategoryTrendCard></LoadingCategoryTrendCard>
                    </>
                        :
                        trendGames.map((trendGame) => (
                            <CategoryTrendCard trendGame={trendGame}></CategoryTrendCard>
                        ))
                }
            </div>
        </div>
    )
}

export default CategoryTrendPanel