import React, { useEffect, useState } from 'react'
import { FaForward, FaPowerOff } from 'react-icons/fa6'
import { FiEye } from 'react-icons/fi'
import { useNavigate, useParams, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useToken } from '../../service/context/TokenProvider'
import VsEndGame from './VsEndGame'
import SessionStatus from '../../service/SessionStatus'

function VsGame() {
    const location = useLocation();
    const { gameId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const navigate = useNavigate();

    const webSocketConnection = useSelector(state => state.webSocketConnection);
    const { socket, stompClient } = webSocketConnection;

    const [gameSubscription, setGameSubscription] = useState(null);
    const [playerWaitText, setPlayerWaitText] = useState("");
    const [player1Data, setPlayer1Data] = useState({
        name: "Guest 1",
        score: 0,
        answer: "AAA",
        answerList: [],
    });
    const [player2Data, setPlayer2Data] = useState({
        name: "Guest 2",
        score: 0,
        answer: "BBB",
        answerList: [],
    });

    useEffect(() => {
        initializeMultiplayerGame();
    }, [gameId])

    const initializeMultiplayerGame = async () => {
        if (subId) {
            await axios.get('/user/userPageInformation/' + subId)
            .then((response) => {
                changeUserInformation(response.data.user.userName)
            })
            .catch((error) => {
                console.log(error)
            })
        } else {
            changeUserInformation("Guest " + location.state.playerNumber)
        }
        console.log('WebSocketConnection: ', stompClient)
        const gameSubs = stompClient.subscribe(`/topic/game/${token}`, parseGameMessage);
        setGameSubscription(gameSubs);
    }

    const changeUserInformation = (userName) => {
        if (location.state.playerNumber == 1) {
            setPlayer1Data((prev) => {
                return {
                    ...prev,
                    name: userName
                }
            });
        } else if (location.state.playerNumber == 2) {
            setPlayer2Data((prev) => {
                return {
                    ...prev,
                    name: userName
                }
            });
        }
    }

    useEffect(() => {
        if (location.state && gameSubscription) {
            if (location.state.playerNumber == 1) {
                sendGamePageReady(player1Data.name);
            } else if (location.state.playerNumber == 2) {
                sendGamePageReady(player2Data.name);
            }
        }
    }, [location.state, gameSubscription])

    const sendGamePageReady = (playerName) => {
        axios.post('/multiplayer/gameReady?gameToken=' + token + '&playerNumber=' + location.state.playerNumber + '&playerName=' + playerName)
            .then((response) => {
                console.log(response);
            }).catch((error) => {
                console.log(error);
            });
    }

    const parseGameMessage = (message) => {
        const gameMessage = JSON.parse(message.body);
        console.log('GameMessage: ', gameMessage);
        if (gameMessage.message == "Game ready") {
            onGameReady(gameMessage)
        } else if (gameMessage.message == "Answer sent") {
            onAnswerSent(gameMessage);
        } else if (gameMessage.message == "Both players answered") {
            onBothPlayersAnswered(gameMessage);
        } else if (gameMessage.message == "Next section ready") {
            setNextSectionReady(true);
            setNextSectionHidden(true);
        }
    }

    const onGameReady = (gameMessage) => {
        setSections(gameMessage.sections)
        setNumSquares(Math.round(Math.sqrt(gameMessage.squareAmount)));
        setIsSectionsFetched(true);
        setPlayer1Data((prev) => {
            return {
                ...prev,
                name: gameMessage.player1Name
            }
        });
        setPlayer2Data((prev) => {
            return {
                ...prev,
                name: gameMessage.player2Name
            }
        });
    }


    const onAnswerSent = (gameMessage) => {
        if (location.state.playerNumber == 1) {
            if (gameMessage.player1Answer) {
                setPlayerWaitText("Waiting for opponent")
            } else if (gameMessage.player2Answer) {
                setPlayerWaitText("Opponent answered")
            }
        } else if (location.state.playerNumber == 2) {
            if (gameMessage.player1Answer) {
                setPlayerWaitText("Opponent answered")
            } else if (gameMessage.player2Answer) {
                setPlayerWaitText("Waiting for opponent")
            }
        }
    }

    const onBothPlayersAnswered = (gameMessage) => {
        setPlayerWaitText("");
        setIsButtonClickable(true);
        setNextSectionHidden(false);
        setPlayer1Data((prev) => {
            const newAnswerList = [...prev.answerList]
            newAnswerList.push(gameMessage.player1Answer == gameMessage.correctAnswer ? true : false);
            return {
                ...prev,
                answer: gameMessage.player1Answer,
                score: gameMessage.player1Score,
                answerList: newAnswerList
            }
        });
        setPlayer2Data((prev) => {
            const newAnswerList = [...prev.answerList]
            newAnswerList.push(gameMessage.player2Answer == gameMessage.correctAnswer ? true : false);
            return {
                ...prev,
                answer: gameMessage.player2Answer,
                score: gameMessage.player2Score,
                answerList: newAnswerList
            }
        });
        const buttons = document.querySelectorAll('button');
        buttons.forEach((button) => {
            if (button.innerText === gameMessage.correctAnswer) {
                button.classList.add('shadow-green-500');
            }
            if (location.state.playerNumber == 1) {
                if (button.innerText === gameMessage.player1Answer && gameMessage.player1Answer != gameMessage.correctAnswer) {
                    button.classList.add('shadow-red-500');
                }
            } else if (location.state.playerNumber == 2) {
                if (button.innerText === gameMessage.player2Answer && gameMessage.player2Answer != gameMessage.correctAnswer) {
                    button.classList.add('shadow-red-500');
                }
            }
        });
    }

    /*
    ///////////////////////////////////////////////////////////////////////////
    /////////////////////         GAME LOGIC AREA         /////////////////////
    ///////////////////////////////////////////////////////////////////////////
    */

    const [sections, setSections] = useState(null);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [numSquares, setNumSquares] = useState(0);
    const [parts, setParts] = useState([]);
    const [handleSquares, setHandleSquares] = useState(0);
    const [handleSquearesFlag, setHandleSquaresFlag] = useState(false);
    const [firstParts, setFirstParts] = useState(false);
    const [randomIndexes, setRandomIndexes] = useState([]);
    const [isSectionsFetched, setIsSectionsFetched] = useState(false);
    const [isButtonClickable, setIsButtonClickable] = useState(false);
    const [nextSectionHidden, setNextSectionHidden] = useState(true);
    const [isButtonClickableSquare, setIsButtonClickableSquare] = useState(true);
    const [lastSquareButton, setLastSquareButton] = useState(0);
    const [lastClickSquareButton, setLastClickSquareButton] = useState(0);
    const [answerClicked, setAnswerClicked] = useState(false);
    const [nextSectionReady, setNextSectionReady] = useState(false);
    const [userAnswerState, setUserAnswerState] = useState(null);
    const [navigateToEndGame, setNavigateToEndGame] = useState(false);
    const [showEndGame, setShowEndGame] = useState(null);
    const [error, setError] = useState(null);
    const [session, setSession] = useState(null);

    const { tokenData } = useToken();
    const { subId, accessToken } = tokenData;

    //Bölüm geçtikçe resimleri değiştiriyoruz
    const handleImageLoad = () => {
        console.log('HandleImageLoad')
        const img = new Image();
        const currentSection = sections[currentSectionIndex];
        if (currentSection && currentSection.sectionImage) {
            img.src = `data:image/jpeg;base64,${currentSection.sectionImage}`;
            if (numSquares != 1) {
                img.onload = () => {
                    const partsArray = splitImage(img, numSquares);
                    setParts(partsArray);
                    setHandleSquares(numSquares * numSquares - 1);
                    setHandleSquaresFlag(true);
                    setFirstParts(true);
                };
            }
        }
    };

    // Resmi parçalara ayırıyoruz
    const splitImage = (img, numSquares) => {
        const { width, height } = img;
        const canvasWidth = width / numSquares;
        const canvasHeight = height / numSquares;
        const canvasArray = [];

        for (let i = 0; i < numSquares * numSquares; i++) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // Siyah rengi kullanarak parçayı doldur
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            canvasArray.push(canvas.toDataURL());
        }

        return canvasArray;
    };

    const restoreOriginalPart = (index) => {
        const originalImage = new Image();
        const currentSection = sections[currentSectionIndex];
        if (currentSection && currentSection.sectionImage) {
            originalImage.src = `data:image/jpeg;base64,${currentSection.sectionImage}`;
            originalImage.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = originalImage.width;
                canvas.height = originalImage.height;

                const sx = (index % Math.sqrt(numSquares * numSquares)) * canvas.width / numSquares;
                const sy = Math.floor(index / Math.sqrt(numSquares * numSquares)) * canvas.height / numSquares;

                ctx.drawImage(originalImage, sx, sy, canvas.width / numSquares, canvas.height / numSquares, 0, 0, canvas.width, canvas.height);

                const updatedParts = [...parts];
                updatedParts[index] = canvas.toDataURL();
                setParts(updatedParts);
            };
        }
    };

    const handleRandomOpen = () => {

        const currentTime = new Date().getTime();
        if (currentTime - lastSquareButton < 500) {
            return;
        }
        setLastClickSquareButton(currentTime);

        if (handleSquearesFlag) {
            setHandleSquares((prevSquares) => prevSquares - 1);
        }
        restoreOriginalPart(randomIndexes[0]);
        setRandomIndexes(randomIndexes.slice(1));
    };

    const handleSquareButtonClick = () => {
        setIsButtonClickableSquare(false);
        setTimeout(() => {
            if (handleSquares != 1) {
                setIsButtonClickableSquare(true);
            }
        }, 500);
        handleRandomOpen();
    };

    const clearShadows = () => {
        const buttons = document.querySelectorAll('button');
        buttons.forEach((button) => {
            button.classList.remove('shadow-green-500');
            button.classList.remove('shadow-red-500');
        });
    };

    const nextSectionClick = () => {
        setIsButtonClickable(false);

        axios.post('/multiplayer/nextSectionReady?gameToken=' + token + '&playerNumber=' + location.state.playerNumber)
            .then(res => {
                console.log('next section ready response: ', res);
            })
            .catch(err => {
                console.error(err)
            })
    }

    const onNextSectionReady = () => {
        setAnswerClicked(false);
        setIsButtonClickableSquare(true);
        if (currentSectionIndex === sections.length - 1) {
            setNavigateToEndGame(true);
        } else {
            setCurrentSectionIndex((prev) => prev + 1)
            clearShadows();
        }
    }

    const onAnswerClick = (e) => {
        if (!answerClicked) {
            setAnswerClicked(true);
            const userAnswer = e.target.innerText;

            clearShadows();

            axios.post('/multiplayer/sendAnswer', {
                "gameToken": token,
                "playerNumber": location.state.playerNumber,
                "answer": userAnswer,
                "remainingSquare": handleSquares
            })
                .then(res => {
                    setUserAnswerState(userAnswer);
                    console.log('send answer response: ', res);
                })
                .catch(err => {
                    console.error(err)
                    if (err.response.data.message == "Game not found") {
                        setSession("Game session not available.");
                    }
                })
        }
    };

    useEffect(() => {
        if (nextSectionReady) {
            setNextSectionReady(false);
            onNextSectionReady()
        }
    }, [nextSectionReady]);

    useEffect(() => {
        if (navigateToEndGame) {
            setShowEndGame(true);
            gameSubscription.unsubscribe();
            stompClient.disconnect(() => {
                socket.close();
                console.log('WebSocket bağlantısı kapatıldı.');
            });
        }
    }, [navigateToEndGame]);

    useEffect(() => {
        if (handleSquares === 0) {
            setHandleSquaresFlag(false);
        }
    }, [handleSquares]);

    useEffect(() => {
        if (isSectionsFetched) {
            handleImageLoad()
        }
    }, [currentSectionIndex, sections]);

    useEffect(() => {
        if (firstParts) {
            setFirstParts(false);
            function getRandomInt(max) {
                return Math.floor(Math.random() * max);
            }
            const randomSqu = getRandomInt(numSquares * numSquares)
            restoreOriginalPart(randomSqu)

            const randomNums = [];

            while (randomNums.length < numSquares * numSquares - 1) {
                const randomIndex = Math.floor(Math.random() * numSquares * numSquares);
                if (!randomNums.includes(randomIndex) && randomIndex !== randomSqu) {
                    randomNums.push(randomIndex);
                }

            }

            setRandomIndexes(randomNums);
        }

    }, [firstParts, sections]);

    if (!sections) {
        return (
            <div className='flex justify-center items-center h-screen'>
                <div className='border-t-transparent border-solid animate-spin rounded-full border-gray-400 border-8 h-28 w-28'></div>
            </div>
        )
    } else {
        return (
            <div className='h-screen'>
                <div className=" flex items-center justify-center ">
                    {session && (
                        <SessionStatus session={session} multiplayer={true}></SessionStatus>
                    )}
                    <section className="font-bodyFont px-2 text-white">

                        <div className="max-w-6xl">

                            <div className="flex justify-center mt-2">
                                {
                                    sections ?
                                        <p className="lg:text-4xl sm:text-2xl text-2xl">{currentSectionIndex + 1}/{sections.length}</p>
                                        :
                                        null
                                }
                            </div>

                            {
                                sections ?
                                    <>
                                        <div className="flex justify-center my-5 ">
                                            {
                                                numSquares === 1 ?
                                                    <div className=''>
                                                        <img className=" w-auto  lg:h-96 md:h-80 sm:h-60 h-60" src={`data:image/jpeg;base64,${sections[currentSectionIndex].sectionImage}`} alt='Anime Guess - Guess The Anime image' />
                                                    </div>
                                                    :
                                                    numSquares === 2 ?
                                                        <div className='grid grid-cols-2'>
                                                            {
                                                                parts.map((part, index) => (
                                                                    <div key={index} className="">
                                                                        <img className=" w-auto lg:h-44 md:h-36 sm:h-28 h-20" src={part} alt='Anime Guess - Guess The Anime image' />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        numSquares === 3 ?
                                                            <div className='grid grid-cols-3'>
                                                                {
                                                                    parts.map((part, index) => (
                                                                        <div key={index} className="">
                                                                            <img className=" w-auto  lg:h-36 md:h-28 sm:h-20 h-16" src={part} alt='Anime Guess - Guess The Anime image' />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            :
                                                            numSquares === 4 ?
                                                                <div className='grid grid-cols-4'>
                                                                    {
                                                                        parts.map((part, index) => (
                                                                            <div key={index} className="">
                                                                                <img className=" w-auto  lg:h-36 md:h-28 sm:h-16 h-16" src={part} alt='Anime Guess - Guess The Anime image' />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                            }
                                        </div>

                                        <div className="grid lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-1 gap-4  w-full ">
                                            {
                                                sections[currentSectionIndex].sectionAnswers.map((option, index) => (
                                                    index === 0 ?
                                                        <button
                                                            className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg duration-200  shadow-lg`}
                                                            onClick={(e) => onAnswerClick(e)}
                                                        >{option}</button>
                                                        :
                                                        index === 1 ?
                                                            <button
                                                                className={`bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2  rounded-lg  duration-200 shadow-lg`}
                                                                onClick={(e) => onAnswerClick(e)}
                                                            >{option}</button>
                                                            :
                                                            index === 2 ?
                                                                <button
                                                                    className={`bg-indigo-500 hover:bg-indigo-600  text-white px-4 py-2  rounded-lg  duration-200 shadow-lg`}
                                                                    onClick={(e) => onAnswerClick(e)}
                                                                >{option}</button>
                                                                :
                                                                index === 3 ?
                                                                    <button
                                                                        className={`bg-rose-500  hover:bg-rose-600 text-white px-4 py-2 rounded-lg  duration-200 shadow-lg`}
                                                                        onClick={(e) => onAnswerClick(e)}
                                                                    >{option}</button>
                                                                    :
                                                                    null
                                                ))
                                            }
                                        </div>
                                    </>
                                    :
                                    null
                            }

                            {
                                numSquares != 1 ?
                                    <div className='flex justify-center mt-10'>
                                        <button
                                            onClick={handleSquareButtonClick}
                                            className={` flex items-center align-middle rounded-lg lg:text-lg sm:text-sm ${isButtonClickableSquare ? 'bg-pink-700 hover:animate-pulse' : 'bg-gray-500'} lg:px-3 lg:py-2 px-2 py-2 font-medium shadow-sm  shadow-slate-400 text-white  focus:outline-none`}
                                            disabled={!isButtonClickableSquare}
                                        >
                                            Open Square
                                            <FiEye className=' w-6 h-6  ml-2 '></FiEye>
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                            <div className='flex justify-center mt-5 mb-2' style={{height: '50px'}}>
                            {
                                playerWaitText ?
                                <p
                                    className={' cursor-auto animate-pulse flex italic items-center align-middle rounded-lg lg:text-3xl md:text-2xl sm:text-xl text-xl font-medium text-white'}
                                >
                                    {playerWaitText}
                                </p>
                                :
                                !nextSectionHidden ?
                                <button
                                    onClick={nextSectionClick}
                                    className={` flex items-center align-middle rounded-lg lg:text-lg sm:text-sm ${isButtonClickable ? 'bg-sky-400 hover:animate-pulse' : 'bg-gray-500'} lg:px-3 lg:py-1 px-2 py-10 font-medium shadow-sm  shadow-slate-400 text-white  focus:outline-none`}
                                    disabled={!isButtonClickable}
                                >
                                    Ready
                                    <FaForward className=' w-6 h-6  ml-2 '></FaForward>
                                </button>
                                :
                                null
                            }
                            </div>

                            <VsEndGame
                                gameId={gameId}
                                showEndGame={showEndGame}
                                player1Data={player1Data}
                                player2Data={player2Data}>
                            </VsEndGame>
                        </div>
                    </section>
                </div>
                <hr className=' border-1 border-indigo-400 mb-2'></hr>
                <div>
                    <div className="flex justify-center">
                        <div className=" flex justify-center lg:text-3xl md:text-2xl sm:text-lg font-bold text-blue-500   w-1/2 " >{player1Data.name}</div>
                        <div className=" flex justify-center  lg:text-3xl md:text-2xl sm:text-lg font-bold text-red-500  w-1/2">{player2Data.name}</div>
                    </div>
                    <div className="flex justify-center mt-4">
                        <p className='flex justify-center lg:text-4xl sm:text-2xl font-bold italic text-white w-1/2'>{player1Data.score}</p>
                        <p className=' flex justify-center lg:text-4xl sm:text-2xl font-bold italic text-white w-1/2'>{player2Data.score}</p>
                    </div>
                    <div className="flex justify-center lg:-mt-16 md:-mt-5 mt-4 lg:mx-0 mx-2">
                        <div className="grid grid-cols-5 gap-2">
                            {
                                sections ? (
                                    <>
                                        {player1Data.answerList.map((answer, index) => (
                                            <div key={index} className={"ring-1 ring-indigo-400 rounded-sm px-3 h-7 " + (answer ? "bg-green-500" : "bg-red-500")}></div>
                                        ))}
                                    </>
                                ) : null
                            }
                            {
                                sections ? (
                                    Array(sections.length - player1Data.answerList.length).fill().map((_, index) => (
                                        <div className=" ring-1 ring-indigo-400 rounded-sm px-3 h-7"></div>
                                    ))
                                ) : null
                            }
                        </div>
                        <div className="border-l border-1 border-gray-400 mx-4"></div>
                        <div className="grid grid-cols-5 gap-2">
                            {
                                sections ? (
                                    <>
                                        {player2Data.answerList.map((answer, index) => (
                                            <div key={index} className={"ring-1 ring-indigo-400 rounded-sm px-3 h-7 " + (answer ? "bg-green-500" : "bg-red-500")}></div>
                                        ))}
                                    </>
                                ) : null
                            }
                            {
                                sections ? (
                                    Array(sections.length - player2Data.answerList.length).fill().map((_, index) => (
                                        <div className=" ring-1 ring-indigo-400 rounded-sm px-3 h-7"></div>
                                    ))
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VsGame